import { Box } from "@mui/system";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import { Stack } from "@mui/material";
import QueryDialog from "../../components/query/QueryDialog";


function MainLayout() {
	return (
		<>
			<Stack height="100%">
				<Header />
				<Box
					component="main"
					flex="1 1 auto"
					overflow="hidden"
				>
					<Outlet />
				</Box>
			</Stack>
		</>
	)
}


export default MainLayout;