import { Stack, Typography } from "@mui/material";
import QueriesStack from "./QueriesStack";

function Directive({ data, withQueries = true }) {
	// TODO: Research markdown or other rich text solution.
	const { description, restrictions, queries, range, attunement, scaling } =
		data;
	const hasQueries = !!queries?.length && withQueries;

	return (
		<Stack spacing={1}>
			{range && (
				<Stack direction="row" spacing={2}>
					<Typography component="span" variant="h6">
						{"Range: "}
						<Typography
							component="span"
							variant="h6"
							sx={{ fontWeight: "normal" }}
						>
							{range}"
						</Typography>
					</Typography>
					{attunement && (
						<Typography component="span" variant="h6">
							{"Attunement: "}
							<Typography
								component="span"
								variant="h6"
								sx={{ fontWeight: "normal" }}
							>
								{attunement}
								{scaling && " (Scaling)"}
							</Typography>
						</Typography>
					)}
				</Stack>
			)}
			{restrictions && (
				<Typography variant="subtitle2" color="warning.main">
					{restrictions}
				</Typography>
			)}
			<Typography dangerouslySetInnerHTML={{ __html: description }} />
			{hasQueries && <QueriesStack queries={queries} />}
		</Stack>
	);
}

export default Directive;
