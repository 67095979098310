import { IconButton, TextField } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";


function StepperField(props) {
	const { onStepUp, onStepDown, sx, ...textFieldProps } = props;

	return (
		<TextField
			{...textFieldProps}
			type="number"
			InputProps={{
				startAdornment: (
					<IconButton onClick={onStepDown}>
						<RemoveCircleOutlineIcon />
					</IconButton>
				),
				endAdornment: (
					<IconButton onClick={onStepUp}>
						<AddCircleOutlineIcon />
					</IconButton>
				),
			}}
			sx={{
				"& input[type=number]": {
					MozAppearance: "textfield",
					textAlign: "center",
				},
				"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
					WebkitAppearance: "none",
					margin: 0,
				},
				...sx
			}}
		/>
	);
}


export default StepperField;