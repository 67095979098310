import FactionsList from "../faction/FactionsList";
import ListSettings from "./ListSettings";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { closeListSetup } from "../../store/utils-slice";
import useGameSettings from "../../hooks/use-game-settings";
import FrameDialog from "../../ui/containers/FrameDialog";
import FrameHeader from "../../ui/containers/FrameHeader";
import FrameDialogContent from "../../ui/containers/FrameDialogContent";
import FloatingActions from "../../ui/FloatingActions";
import { createNode } from "../../lib/list";
import { newList } from "../../store/list-slice";
import { useNavigate } from "react-router-dom";


function ListSetupDialog() {
	const { listSetupOpen } = useSelector((state) => state.utils);
	const { getGameSettings } = useGameSettings();
	const { listDefaultPoints, listMinPoints } = getGameSettings();
	const initialInputsState = {
		factionSlug: null,
		name: "",
		nameStatus: null,
		points: listDefaultPoints,
		pointsStatus: null,
	}
	const [inputs, setInputs] = useState(initialInputsState);
	const dispatch = useDispatch();
	const {
		availableGames,
		currentGameSlug,
	} = useSelector((state) => state.games);
	const { factions } = availableGames.find(g => g.slug === currentGameSlug);
	const navigate = useNavigate();

	if (!listSetupOpen) return;



	// const { sendRequest, data, status } = useHttp(getFaction, true);

	// const defaultInputs = {
	// 	tlaok: {
	// 		name: "",
	// 		nameStatus: null,
	// 		points: 2000,
	// 		pointsStatus: null,
	// 	},
	// 	fb: {
	// 		name: "",
	// 		nameStatus: null,
	// 		points: 800,
	// 		pointsStatus: null,
	// 	},
	// };

	// useEffect(() => {
	// 	if (currentGameSlug) {
	// 		setInputs(defaultInputs[currentGameSlug]);
	// 	}
	// }, [currentGameSlug]);

	// useEffect(() => {
	// 	if (list?.factionSlug && list?.factionSlug !== currentFaction?.slug) {
	// 		sendRequest(currentGameSlug, list.factionSlug);
	// 	}
	// }, [list?.factionSlug, currentFaction?.slug, currentGameSlug?.slug, sendRequest]);

	// useEffect(() => {
	// 	if (status === "completed" && data) {
	// 		onCurrentFactionChange({}, data);

	// 		// const armyRules = data.faction.optionGroups.find(o => o.slug === "army-rules");
	// 		// if (armyRules) {
	// 		// 	onListUpdate({
	// 		// 		...list,
	// 		// 		optionGroups: [
	// 		// 			...list.optionGroups.filter(g => g.slug !== "army-rules"),
	// 		// 			{
	// 		// 				name: "Army Rules",
	// 		// 				slug: "army-rules",
	// 		// 				options: [...armyRules.options],
	// 		// 			}
	// 		// 		]
	// 		// 	});
	// 		// }
	// 	}
	// }, [status, data, onCurrentFactionChange]);

	function handleGo() {
		let error = false;

		if (!inputs.name?.length) {
			error = true;
			setInputs((prevInputs) => ({
				...prevInputs,
				nameStatus: "Name cannot be empty",
			}));
		}

		if (inputs.points < listMinPoints) {
			error = true;
			setInputs((prevInputs) => ({
				...prevInputs,
				pointsStatus: `Points cannot be lower than ${listMinPoints}`,
			}));
		}

		if (!error) {
			// // onListUpdate({
			// // 	settings: {
			// // 		name: inputs.name,
			// // 		points: parseInt(inputs.points),
			// // 	},
			// // });
			// const armyRules = currentFaction.optionGroups.find(
			// 	(o) => o.slug === "army-rules"
			// );
			const rootNode = createNode({
				name: inputs.name,
				points: parseInt(inputs.points),
				gameSlug: currentGameSlug,
				factionSlug: inputs.factionSlug,
			});
			// dispatch(newList(rootNode));
			localStorage.setItem("unsaved-list", JSON.stringify(rootNode));

			navigate(`editor/${rootNode.id}`, { state: { test: "test" } });
			handleCancel();
		}
	}

	function handleFactionSelect(factionSlug) {
		setInputs(prevInputs => ({
			...prevInputs,
			factionSlug
		}));
	}

	function handleNameChange(name) {
		setInputs(prevInputs => ({
			...prevInputs,
			name,
			nameStatus: null,
		}));
	}

	function handlePointsChange(points) {
		setInputs(prevInputs => ({
			...prevInputs,
			points,
			pointsStatus: null,
		}));
	}

	function handleBack() {
		setInputs(initialInputsState);
	}

	function handleCancel() {
		setInputs(initialInputsState);
		dispatch(closeListSetup());
	}

	const backAction = {
		id: "Back",
		icon: ArrowBackIcon,
		handler: handleBack,
	};

	const goAction = {
		id: "Start new list",
		label: "Go to war!",
		handler: handleGo,
	};

	const cancelAction = {
		id: "Cancel",
		icon: CloseIcon,
		handler: handleCancel,
	};

	const actions = inputs.factionSlug ? [
		backAction,
		cancelAction,
		goAction,
	] : [
		cancelAction,
	];

	return (
		<FrameDialog open onClose={handleCancel} responsive actions={actions}>
			<FrameHeader title="New List" />
			<FrameDialogContent>
				{!inputs.factionSlug && (
					<FactionsList
						factions={factions}
						onFactionSelect={handleFactionSelect}
					/>
				)}
				{inputs.factionSlug && (
					<ListSettings
						inputs={inputs}
						onNameChange={handleNameChange}
						onPointsChange={handlePointsChange}
					/>
				)}
			</FrameDialogContent>
		</FrameDialog>
	);
}


export default ListSetupDialog;