import { useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { getFaction } from "../lib/api";
import { HorPanelsCtxProvider } from "../ui/layouts/HorPanelsCtx";
import useGameSettings from "../hooks/use-game-settings";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentFaction } from "../store/games-slice";


function Faction() {
	const faction = useLoaderData();
	const { getComponent } = useGameSettings();
	const { currentFactionSlug } = useSelector(state => state.games);
	const dispatch = useDispatch();

	useEffect(() => {
		if (faction.slug !== currentFactionSlug) {
			dispatch(setCurrentFaction(faction.slug));
		}
	}, [currentFactionSlug, faction.slug, dispatch]);

	// Waits for state to be set.
	if (!currentFactionSlug) return;

	const FactionComponent = getComponent("pages", "faction");

	return (
		<HorPanelsCtxProvider>
			<FactionComponent faction={faction} />
		</HorPanelsCtxProvider>
	);
}


export function loader({ params }) {
	return getFaction(params.gameSlug, params.factionSlug);
}


export default Faction;