import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { forwardRef } from "react";
import appSettings from "../../app-settings";
import FloatingActions from "../FloatingActions";


const PanelContainer = forwardRef((props, ref) => {
	const { body, actions, responsive = false } = props;
	const smDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const fullWidth = responsive && smDown;
	const maxWidth = fullWidth ? "100%" : `${appSettings.panelMaxSize}px`;

	return (
		<Box
			position="relative"
			height="100%"
			width="100%"
			maxWidth={maxWidth}
			ref={ref}
			tabIndex={-1}
		>
			{body}
			<FloatingActions xPos="right" yPos="bottom" actions={actions} />
		</Box>
	);
});


export default PanelContainer;