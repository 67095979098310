import { Stack, Chip, Typography } from "@mui/material";
import LabelledGroup from "../../../ui/containers/LabelledGroup";
import ReferenceBox from "../../../ui/ReferenceBox";
import QueryIcon from "../../query/QueryIcon";


function Masteries({ group }) {
	const { name, query, groups } = group;

	return (
		<LabelledGroup label={name} action={query && <QueryIcon query={query} />}>
			<Stack direction="row" flexWrap="wrap" gap={1}>
				{groups.map(g => (
					<ReferenceBox key={g.slug}>
						<Typography mx={1}>{g.name}</Typography>
					</ReferenceBox>
				))}
			</Stack>
		</LabelledGroup>
	);
}


export default Masteries;