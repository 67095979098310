import FramePanelContent from "../../ui/containers/FramePanelContent";
import EntryPanelHeader from "./EntryPanelHeader";
import EntryPanelContent from "./EntryPanelContent";
import FramePanel from "../../ui/containers/FramePanel";
import { useContext, useState } from "react";
import FloatingActions from "../../ui/FloatingActions";
import CloseIcon from "@mui/icons-material/Close";
import HorPanelsCtx from "../../ui/layouts/HorPanelsCtx";
import { useSelector } from "react-redux";
import PushPinIcon from '@mui/icons-material/PushPin';

function EntryPanel({ panel, type }) {
	const { id, data: entry } = panel;
	const { onPanelRemove, onPanelUpdate } = useContext(HorPanelsCtx);
	const [activeProfileIndex, setActiveProfileIndex] = useState(
		entry.profiles ? 0 : null
	);
	const { currentFactionSlug } = useSelector(state => state.games);

	function handleProfileChange(index) {
		setActiveProfileIndex(index);
	}

	function handlePanelClose() {
		onPanelRemove(id);
	}

	function handlePanelPin() {
		onPanelUpdate(id, { type: "permanent" })
	}

	function handlePanelUnPin() {
		onPanelUpdate(id, { type: "temporary" })
	}


	return (
		<FramePanel frameStyle="frame2" color="secondary">
			<EntryPanelHeader
				entry={entry}
				activeProfileIndex={activeProfileIndex}
				factionSlug={currentFactionSlug}
			/>
			<FramePanelContent>
				<EntryPanelContent
					entry={entry}
					activeProfileIndex={activeProfileIndex}
					onProfileChange={handleProfileChange}
				/>
			</FramePanelContent>
		</FramePanel>
	);
}


export default EntryPanel;