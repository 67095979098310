import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import QueryIcon from "../components/query/QueryIcon";


function Reference(props) {
	const { label, variable, secLabel, secVariable, query } = props;
	const isVariableLast = !secLabel && !secVariable && !query;
	const isSecVariableLast = !query;

	return (
		<Box
			display="inline-flex"
			padding={(theme) => theme.spacing(0, 1)}
			bgcolor="#d6d9da"
			borderRadius={1}
			sx={{ cursor: "default" }}
			{...props}
		>
			<Typography component="span" noWrap >{label}</Typography>
			{variable && (
				<Box
					padding={(theme) => theme.spacing(0, 1)}
					bgcolor="#c2c5c6"
					borderRadius={1}
					ml={-0.5}
					mr={isVariableLast ? -1 : 1}
				>
					{variable}
				</Box>
			)}
			{secLabel && <Typography component="span" mr={1}>{secLabel}</Typography>}
			{secVariable && (
				<Box
					padding={(theme) => theme.spacing(0, 1)}
					bgcolor="#c2c5c6"
					borderRadius={1}
					ml={-0.5}
					mr={isSecVariableLast ? -1 : 1}
				>
					{secVariable}
				</Box>
			)}
			{query && <QueryIcon query={query} />}
		</Box >
	);
}


export default Reference;