import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import "@fontsource/eb-garamond/variable.css";
import "@fontsource/eb-garamond/variable-italic.css";
import { green, red, blueGrey, orange } from "@mui/material/colors";
import { alpha } from "@mui/material";
import { calc } from "caniuse-lite/data/features";

const myWhite = "#fcfdfd";
const myBlack = "#0a0d0e";

let defaultTheme = createTheme({
	palette: {
		common: {
			black: myBlack,
			white: myWhite,
		},
		primary: {
			main: blueGrey[700],
			contrastText: myWhite,
			contrastIcon: blueGrey[50],
		},
		secondary: {
			main: blueGrey[50],
			contrastText: myBlack,
			contrastIcon: blueGrey[700],
		},
		success: {
			main: green[700],
			contrastText: myWhite,
		},
		darkBgr: {
			main: blueGrey[800],
			contrastText: myWhite,
			contrastIcon: blueGrey[50],
		},
		error: {
			main: red[700],
			contrastText: myWhite,
		},
		warning: {
			main: orange[800],
			contrastText: myWhite,
		},
	},
	typography: {
		fontFamily: '"EB Garamond", "Times New Roman", Times, serif',
		fontWeightRegular: 500,
		h1: {
			fontSize: "3.6rem",
			fontWeight: 500,
		},
		h2: {
			fontSize: "2.6rem",
			fontWeight: 500,
		},
		h3: {
			fontSize: "1.8rem",
			fontWeight: 500,
		},
		h4: {
			fontSize: "1.4rem",
			fontWeight: 500,
		},
		h5: {
			fontSize: "1.2rem",
			fontWeight: 600,
			lineHeight: 1.4,
		},
		h6: {
			fontSize: "1.1rem",
			fontWeight: 600,
		},
		subtitle1: {
			lineHeight: 1.2,
		},
		subtitle2: {
			fontWeight: 600,
			lineHeight: 1.2,
		},
		button: {
			fontSize: "1rem",
			textTransform: "none",
		},
	},
});

defaultTheme = createTheme(defaultTheme, {
	components: {
		MuiPaper: {
			defaultProps: {
				elevation: 3,
			},
			styleOverrides: {
				root: {
					backgroundColor: defaultTheme.palette.secondary.main,
					color: defaultTheme.palette.secondary.contrastText,
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: {
				".shadowed": {
					filter: "drop-shadow(0 2px 4px black)",
				},
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					"&.Mui-selected": {
						backgroundColor: defaultTheme.palette.primary.main,
						color: defaultTheme.palette.primary.contrastText,
						"&:hover": {
							backgroundColor: defaultTheme.palette.primary.dark,
						},
					},
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					margin: defaultTheme.spacing(2),
					width: `calc(100% - ${defaultTheme.spacing(2)})`,
					"[data-simplebar]": {
						maxHeight: "80vh",
					},
				},
				paperFullScreen: {
					margin: 0,
					width: "100%",
					"[data-simplebar]": {
						maxHeight: "unset",
						height: "100%",
					},
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					backgroundColor: defaultTheme.palette.primary.main,
					color: defaultTheme.palette.primary.contrastText,
					padding: defaultTheme.spacing(1, 2),
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					"&&": {
						padding: defaultTheme.spacing(1, 2),
					},
				},
			},
		},
		MuiIconButton: {
			defaultProps: {
				size: "small",
			},
			variants: [
				{
					props: { variant: "light" },
					style: {
						color: defaultTheme.palette.primary.contrastIcon,
						"&:hover": {
							backgroundColor: alpha(
								defaultTheme.palette.primary.contrastIcon,
								0.15
							),
						},
						"&.Mui-disabled": {
							color: alpha(defaultTheme.palette.primary.contrastIcon, 0.26),
						},
					},
				},
				{
					props: { variant: "light-overlay" },
					style: {
						color: "#cfd8dc",
						backgroundColor: defaultTheme.palette.common.white,
						"&:hover": {
							backgroundColor: alpha(defaultTheme.palette.common.white, 0.7),
						},
					},
				},
			],
		},
		MuiButton: {
			styleOverrides: {
				sizeSmall: {
					fontSize: "unset",
				},
				sizeLarge: {
					fontSize: "1.1rem",
				},
			},
		},
		MuiChip: {
			defaultProps: {
				// size: "small",
			},
			styleOverrides: {
				root: {
					height: "unset",
					position: "relative",
					color: "inherit",
					// fontSize: "0.875rem",
					backgroundColor: "transparent",
					"&:after": {
						content: "''",
						backgroundColor: "currentColor",
						width: "100%",
						height: "100%",
						opacity: 0.1,
						position: "absolute",
						borderRadius: defaultTheme.shape.borderRadius * 4,
						pointerEvents: "none",
					},
				},
				sizeSmall: {
					fontSize: "0.875rem",
				},
				sizeMedium: {
					fontSize: "1rem",
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: "inherit",
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					borderColor: "currentColor",
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: "1rem",
				},
			},
		},
	},
});

defaultTheme = responsiveFontSizes(defaultTheme);

export default defaultTheme;
