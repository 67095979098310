import { Chip, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import EntryCount from "./EntryCount";
import useGameSettings from "../../hooks/use-game-settings";
import EntryPaper from "./EntryPaper";


function EntryButton({ entry, onSelect, factionSlug }) {
	const { name, slug, type, gameClass, cost, size } = entry;
	const { getEntrySettings, getFactionSettings } = useGameSettings();
	const icon = getEntrySettings(slug)?.icon ||
		getFactionSettings(factionSlug).defaultEntryIcon;

	return (
		<EntryPaper onClick={onSelect}>
			<Stack direction="row" spacing={0.5} className="content">
				<Box
					className="avatar"
					flex="0 0 auto"
					sx={{ background: `url(${icon}) center/cover` }}
				/>
				<Stack flex="1 1 auto" minWidth={0} spacing={0.5} className="padded">
					<Box>
						<Typography variant="h6" noWrap>{name}</Typography>
					</Box>
					<Stack direction="row" spacing={0.5}>
						<Chip label={type} size="small" />
						{gameClass && <Chip label={gameClass} size="small" />}
					</Stack>
				</Stack>
				<Stack
					justifyContent="space-between"
					alignItems="flex-end"
					className="padded"
					flex="0 0 auto"
				>
					<Typography variant="h6">{cost === 0 ? "Free" : cost}</Typography>
					<EntryCount count={size} />
				</Stack>
			</Stack>
		</EntryPaper >
	);
}


export default EntryButton;