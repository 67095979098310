import {
	Collapse,
	IconButton,
	SpeedDial,
	SpeedDialAction,
	SpeedDialIcon,
	Stack,
	Tooltip,
	Typography
} from "@mui/material";
import { styled, alpha } from "@mui/system";
import { shadow } from "../../themes/mixins";
import ShadowBox from "./ShadowBox";
import line from "../../assets/decorative/line.svg";
import { useEffect, useRef, useState } from "react";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


const Line = styled("div")({
	height: "8px",
	background: `linear-gradient(
		90deg,
		rgba(207,216,220,1) 0%,
		rgba(144,164,174,1) 5%,
		rgba(84,110,122,1) 10%,
		rgba(55,71,79,1) 50%,
		rgba(84,110,122,1) 90%,
		rgba(144,164,174,1) 95%,
		rgba(207,216,220,1) 100%
	)`,
	maskImage: `url(${line})`,
	maskRepeat: "repeat no-repeat",
	maskSize: "auto 100%",
});


// TODO: Check if it looks OK on light background.
const ActionDial = styled(SpeedDial)(({ theme, direction }) => ({
	position: "relative",
	backgroundColor: "unset",

	"& .MuiSpeedDial-fab": {
		color: "currentColor",
		backgroundColor: "unset",
		boxShadow: "unset",
		"&:hover": {
			backgroundColor: alpha(theme.palette.primary.contrastIcon, 0.15),
		},
	},
	"& .MuiSpeedDial-actions": {
		position: "absolute",
		right: direction === "left" && "100%",
		margin: 0,
		padding: 0,
	}
}));


function ActionButton({ action, variant }) {
	const { label, icon: Icon, handler } = action;

	return (
		<Tooltip title={label} placement="top">
			<IconButton onClick={handler} variant={variant}>
				<Icon />
			</IconButton>
		</Tooltip>
	);
}


function Section({ title, actions = [], children, darkBgr, prefix }) {
	const [collapseActions, setCollapseActions] = useState(false);
	const [openDial, setOpenDial] = useState(false);
	const [openCollapse, setOpenCollapse] = useState(true);
	const stackRef = useRef();
	const titleRef = useRef();

	// TODO: Change how actions collapse. Right now it will keep actions expanded
	// even if the title is truncated. Try checking for the line width and
	// expand/collapse based on that.
	useEffect(() => {
		const stackWidth = stackRef.current.getBoundingClientRect().width;
		const titleWidth = titleRef.current.getBoundingClientRect().width;
		const actionsWidth = actions.length * 42 + (children ? 42 : 0);
		const shouldOpen = titleWidth / (stackWidth - actionsWidth) > 0.8;
		setCollapseActions(shouldOpen);
	}, [title]);


	function handleDialOpen() {
		setOpenDial(true);
	}

	function handleDialClose() {
		setOpenDial(false);
	}

	function handleCollapseToggle() {
		setOpenCollapse((prevState) => !prevState);
	}

	const allActions = [...actions];
	if (children) {
		allActions.push({
			label: openCollapse ? "Collapse" : "Expand",
			icon: openCollapse ? ExpandLess : ExpandMore,
			handler: handleCollapseToggle,
			collapsible: false,
		});
	}

	const expandedActions =
		collapseActions ? allActions.filter(a => !a.collapsible) : allActions;
	const collapsedActions =
		collapseActions ? allActions.filter(a => a.collapsible) : [];

	const expandedButtons = expandedActions.map(a => (
		<ActionButton key={a.label} action={a} variant={darkBgr && "light"} />
	));
	// TODO: Check if destructuring is necessary only for icon: Icon
	const collapsedButtons = collapsedActions.map(({ label, handler, icon: Icon }) => (
		<SpeedDialAction
			key={label}
			icon={<Icon />}
			tooltipTitle={label}
			onClick={handler}
		/>
	)).reverse();

	return (
		<section>
			<Stack
				direction="row"
				alignItems="center"
				spacing={1}
				ref={stackRef}
			>
				{prefix}
				<Typography
					component="h2"
					variant="h4"
					noWrap
					ref={titleRef}
					color="secondary"
					sx={{ ...shadow }}
				>
					{title}
				</Typography>
				<ShadowBox flexGrow={1}>
					<Line />
				</ShadowBox>
				{!!collapsedButtons.length && (
					<ActionDial
						ariaLabel="SpeedDial openIcon example"
						direction="left"
						icon={<SpeedDialIcon icon={<MoreHorizIcon />} />}
						FabProps={{
							size: "small",
						}}
						onMouseOver={handleDialOpen}
						onClick={handleDialOpen}
						open={openDial}
						onClose={handleDialClose}
						color="success"
					>
						{collapsedButtons}
					</ActionDial>
				)}
				{expandedButtons}
			</Stack >
			{children && (
				<Collapse in={openCollapse} children={children} sx={{ mt: 2 }} />
			)}
		</section>
	);
}


export default Section;