import { getNode } from "../../lib/list";

function hasTags(entry, tags) {
	entry.tagGroups?.forEach(g => {
		g.tags.forEach(t => {
			tags = tags.filter(s => s !== t.slug);
		});
	});

	return tags.length <= 0;
}

const TLAOK_TARGETS = {
	"LIST": (list) => {
		return [list];
	},
	"ALL_ENTRIES": (list, origin, params) => {
		const { children: warbands } = list;
		const entries = [];
		const slugFilter = params?.slugs || [];
		const typeFilter = params?.types || [];

		warbands.forEach(w => {
			if (slugFilter.length > 0) {
				entries.push(...w.children.filter(c => slugFilter.includes(c.entrySlug)));
			} else if (typeFilter.length > 0) {
				entries.push(...w.children.filter(c => typeFilter.includes(c.listEntry.type)));
			} else {
				entries.push(...w.children);
			}
		});
		return entries;
	},
	"ALL_REGIMENTS": (list, origin, params) => {
		const { children: warbands } = list;
		let regiments = [];
		const typeFilter = params?.types || [];
		const tagFilter = params?.tags || [];
		const types = ["mainstay", "restricted"];

		warbands.forEach(w => {
			regiments.push(...w.children.filter(c => types.includes(c.type)));
		});

		if (typeFilter.length > 0) {
			regiments = regiments.filter(c => typeFilter.includes(c.listEntry.type));
		}

		if (tagFilter.length > 0) {
			regiments = regiments.filter(c => hasTags(c.listEntry, tagFilter));
		}

		return regiments;
	},
	"WARLORD_REGIMENTS": (list, origin, params) => {
		const { children: warbands } = list;
		let regiments = [];
		const typeFilter = params?.types || [];
		const types = ["mainstay", "restricted"];

		warbands.forEach(w => {
			const character = w.children.find(c => c.type === "character")
			if (character.listEntry.isWarlord) {
				regiments.push(...w.children.filter(c => types.includes(c.type)));
			}
		});

		if (typeFilter.length > 0) {
			regiments = regiments.filter(c => typeFilter.includes(c.listEntry.type));
		}

		return regiments;
	},
	"ALL_CHARACTERS": (list) => {
		const { children: warbands } = list;
		const characters = [];
		warbands.forEach(w => {
			characters.push(...w.children.filter(c => c.type === "character"));
		});
		return characters;
	},
	"ALL_OTHER_CHARACTERS": (list, origin) => {
		const { children: warbands } = list;
		const characters = [];
		warbands.forEach(w => {
			const character = w.children.find(c => c.type === "character" && c.id !== origin.id);
			if (character) characters.push(character);
		});
		return characters;
	},
	"WARBAND": (list, origin) => {
		return [origin.parent];
	},
	"WARBAND_ENTRIES": (list, origin) => {
		const warband = origin.parent;
		return warband.children;
	},
	"REGIMENTS_OF": (list, origin, params) => {
		const { children: warbands } = list;
		const { characterSlugs = [] } = params;
		const regiments = [];

		for (const w of warbands) {
			const character = w.children.find(c => c.type === "character");
			if (characterSlugs.includes(character.entrySlug)) {
				regiments.push(...w.children.filter(c => c.type !== "character"));
			}
		}

		return regiments;
	},
	"WARBAND_REGIMENTS": (list, origin, params) => {
		const warband = origin.parent;
		let regiments = warband.children.filter(c => c.type !== "character");

		const slugFilter = params?.filters?.slugs || [];
		const typeFilter = params?.filters?.types || [];
		const tagFilter = params?.filters?.tags || [];

		if (slugFilter.length > 0) {
			regiments = regiments.filter(r => slugFilter.includes(r.entrySlug));
		}
		if (typeFilter.length > 0) {
			regiments = regiments.filter(r => typeFilter.includes(r.listEntry.type));
		}
		if (tagFilter.length > 0) {
			regiments = regiments.filter(c => hasTags(c.listEntry, tagFilter));
		}

		return regiments;
	},
	"SELF": (list, origin) => {
		return [origin];
	},
	"SELF_LINK": (list, origin, params) => {
		const { linkedNodes = [] } = origin;
		const { slugs = [] } = params;
		const targets = [];

		slugs.forEach(s => {
			const paths = linkedNodes[s] || [];
			paths.forEach(p => {
				targets.push(getNode(list, p));
			});
		});

		return targets;
	},
}


export default TLAOK_TARGETS;