import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import ListEntryButton from "../../list-entry/ListEntryButton";


function CharacterRegiment({ node, factionSlug, onSwap, forceDelete }) {
	return (
		<Stack spacing={1}>
			<ListEntryButton node={node} factionSlug={factionSlug} onSwap={onSwap} forceDelete={forceDelete} />
			{node.children.map(retinue => (
				<Box pl={3} key={retinue.id}>
					<ListEntryButton node={retinue} factionSlug={factionSlug} noActions />
				</Box>
			))}
		</Stack>
	)
}


export default CharacterRegiment;
