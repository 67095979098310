import { Checkbox, FormControlLabel, IconButton, List, ListItem, Paper, Stack, Typography } from "@mui/material";
import LabelledGroup from "../../../ui/containers/LabelledGroup";
import EditIcon from '@mui/icons-material/Edit';
import Reference from "../../../ui/Reference";
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { useState } from "react";
import FrameDialog from "../../../ui/containers/FrameDialog";
import FrameHeader from "../../../ui/containers/FrameHeader";
import FrameDialogContent from "../../../ui/containers/FrameDialogContent";
import QueryIcon from "../../query/QueryIcon";
import { useDispatch } from "react-redux";
import { attachNode, removeNode, updateOptions } from "../../../store/list-slice";
import Section from "../../../ui/containers/Section";
import { useRouteLoaderData } from "react-router-dom";
import { createNode } from "../../../lib/list";

function getSelections(group, selections) {
	const { groups = [], orphans = [] } = group;
	selections.push(...orphans.filter(o => o.selected));
	groups.forEach(g => getSelections(g, selections));
	return selections;
}


function getAllOptionsSlugs(group, slugs) {
	const { groups = [], orphans = [] } = group;
	slugs.push(...orphans.map(o => o.slug));
	groups.forEach(g => getAllOptionsSlugs(g, slugs));
	return slugs;
}


function Officers({ node, group }) {
	const { name, groups = [], orphans = [] } = group;
	const [open, setOpen] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState(getSelections(group, []));
	const dispatch = useDispatch();
	const { faction } = useRouteLoaderData("editor");

	const optionsToAdd = selectedOptions.map(o => o.slug);
	const optionsToRemove = getAllOptionsSlugs(group, []);

	function handleOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	function handleApply() {
		dispatch(updateOptions({
			path: node.path,
			add: optionsToAdd,
			remove: optionsToRemove,
		}));

		optionsToAdd.forEach(o => {
			if (faction.entries[o]) {
				const officerNode = createNode({
					type: "officer",
					entrySlug: o,
				});

				dispatch(attachNode({
					path: node.path,
					node: officerNode,
				}));
			}
		});

		optionsToRemove.forEach(o => {
			const officerNode = node.children.find(c => c.listEntry.slug === o);
			if (officerNode) {
				dispatch(removeNode({
					parentPath: node.path,
					nodeId: officerNode.id,
				}));
			}
		});

		handleClose();
	}

	const actions = [
		{
			// label: "Apply",
			id: "Apply",
			icon: DoneIcon,
			handler: handleApply
		},
		// {
		// 	description: "Cancel",
		// 	icon: ClearIcon,
		// 	handler: handleClose
		// },
	];

	function handleOptionToggle(option, event) {
		option.selected = event.target.checked;
		setSelectedOptions(getSelections(group, []));
	}

	const orphansSection = !orphans.length ? null : (
		<List sx={{ mx: 2 }} dense>
			{orphans.filter(o => o.active !== false).map(o => (
				<ListItem key={o.slug} disableGutters>
					<FormControlLabel
						control={<Checkbox checked={!!selectedOptions.find(s => s.slug === o.slug)} />}
						label={o.tier ? `Tier ${o.tier}` : o.name}
						onChange={handleOptionToggle.bind(null, o)}
					/>
					{o.query && <QueryIcon query={o.query} />}
					<Typography
						variant="body1"
						flex="1 1 auto"
						align="right"
					>
						{o.cost || "Free"}
					</Typography>
				</ListItem>
			))}
		</List>
	);

	return (
		<>
			<LabelledGroup
				label={name}
				action={
					<IconButton onClick={handleOpen} size="small">
						<EditIcon />
					</IconButton>
				}
			>
				<Stack direction="row" mt={0} flexWrap="wrap" gap={1} py={0.5}>
					{selectedOptions.map(o => (
						<Reference
							key={o.slug}
							label={o.name}
							query={o.query}
						/>
					))}
				</Stack>
			</LabelledGroup>
			<FrameDialog
				open={open}
				onClose={handleApply}
				responsive
				actions={actions}
			>
				<FrameHeader title={group.name} />
				<FrameDialogContent>
					<Stack spacing={2}>
						{orphansSection}
					</Stack>
				</FrameDialogContent>
			</FrameDialog>
		</>
	);
}

export default Officers;