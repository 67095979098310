import { Box, Stack, Typography, Link as MuiLink } from "@mui/material";
import GameHomeButton from "../components/game/GameHomeButton";
import CenteredContentBox from "../ui/containers/CenteredContentBox";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentFaction, setCurrentGame } from "../store/games-slice";
import { useEffect } from "react";
import RippedBox from "../ui/containers/RippedBox";
import AppPromo from "../components/AppPromo";


function Home() {
	const {
		availableGames,
		currentGameSlug,
		currentFactionSlug,
	} = useSelector(state => state.games);
	const dispatch = useDispatch();

	useEffect(() => {
		if (currentGameSlug) {
			dispatch(setCurrentGame(null));
		}
		if (currentFactionSlug) {
			dispatch(setCurrentFaction(null));
		}
	}, [currentGameSlug, currentFactionSlug])

	const gameButtons = availableGames.map(g => (
		<GameHomeButton key={g.slug} game={g} />
	));

	return (
		<CenteredContentBox>
			<Stack spacing={2} alignItems="center">
				{gameButtons}
				<Box pt={4}>
					<AppPromo />
				</Box>
			</Stack>
		</CenteredContentBox>
	);
}


export default Home;