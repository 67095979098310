import { List, ListItem, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LabelledGroup from "../../../ui/containers/LabelledGroup";
import ReferenceBox from "../../../ui/ReferenceBox";
import QueryIcon from "../../query/QueryIcon";


function Retinue({ group }) {
	const { name, query, groups = [], orphans = [] } = group;

	return (
		<LabelledGroup label={name} action={query && <QueryIcon query={query} />}>
			{orphans.length > 0 && (
				<Box component={List} disablePadding px={1}>
					{orphans.map((o) => (
						<ListItem key={o.slug} dense disableGutters>
							<Typography variant="body1" mr={1}>
								{o.name}
							</Typography>
							<Typography variant="body1" flex="1 1 auto" align="right">
								{o.cost || "Free"}
							</Typography>
						</ListItem>
					))}
				</Box>
			)}
			{groups.length > 0 && (
				<Stack direction="row" flexWrap="wrap" gap={1}>
					{groups.map(g => (
						<ReferenceBox key={g.slug}>
							<Typography ml={1}>{g.name}</Typography>
							<Box className="var">
								<Typography>{g.description}</Typography>
							</Box>
						</ReferenceBox>
					))}
				</Stack>
			)}
		</LabelledGroup>
	);
}


export default Retinue;