import FloatingActions from "../../ui/FloatingActions";
import FrameDialogContent from "../../ui/containers/FrameDialogContent";
import EntryPanelHeader from "../entry/EntryPanelHeader";
import { useState } from "react";
import EntryPanelContent from "../entry/EntryPanelContent";
import { Box } from "@mui/system";
import { Navigate, useNavigate, useRouteLoaderData } from "react-router-dom";


function EntryResponse({ request, response: entry }) {
	const [activeProfile, setActiveProfile] = useState(
		entry.profiles ? entry.profiles[0] : null
	);
	const navigate = useNavigate();
	const { factionSlug } = request;


	function handleProfileChange(profileName) {
		const newProfile = entry.profiles.find(
			(p) => p.profileName === profileName
		);
		setActiveProfile(newProfile);
	}

	function handleOpenFaction() {
		navigate(`factions/${factionSlug}`);
	}

	return (
		<>
			<EntryPanelHeader
				entry={entry}
				activeProfile={activeProfile}
				factionSlug={factionSlug}
			/>
			<FrameDialogContent>
				<EntryPanelContent
					entry={entry}
					activeProfile={activeProfile}
					onProfileChange={handleProfileChange}
				/>
				<Box height="100px" />
			</FrameDialogContent>
		</>
	);
}


export default EntryResponse;