import { IconButton, Stack, Toolbar, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/system";
import GameSelectorIcon from "../../components/game/GameSelectorIcon";
import GameHomeIcon from "../../components/game/GameHomeIcon";
import bgr from "../../assets/cloud-banner.jpg";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import LoginIconButton from "../LoginIconButton";
import { useDispatch, useSelector } from "react-redux";
import useGameSettings from "../../hooks/use-game-settings";
import RulesSearchIcon from "../../components/RulesSearchIcon";
import { openScanner } from "../../store/utils-slice";


const TintedHeader = styled(Toolbar)(({ theme, tint }) => ({
	background: `
		linear-gradient(
			0,
			${tint || "transparent"},
			${tint || "transparent"}
		),
		url(${bgr}) center/cover
	`,
	backgroundBlendMode: "overlay",
	minHeight: "unset",
	padding: theme.spacing(0.5, 1),
}));


function Header() {
	const currentGameSlug = useSelector(state => state.games.currentGameSlug);
	const { getGameSettings } = useGameSettings();
	const { tint } = getGameSettings() || { tint: null };
	const [searchOpen, setSearchOpen] = useState(false);
	const smDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const dispatch = useDispatch();

	function handleScannerOpen() {
		dispatch(openScanner());
	}

	return (
		<TintedHeader variant="dense" tint={tint}>
			{(searchOpen && smDown) || (
				<Stack
					flexBasis={"30%"}
					direction="row"
					justifyContent="flex-start"
					spacing={1}
				>
					{currentGameSlug && (
						<GameSelectorIcon gameSlug={currentGameSlug} variant="light" />
					)}
				</Stack>
			)}

			<Stack
				flexBasis={"40%"}
				direction="row"
				flexGrow={1}
				justifyContent="center"
				spacing={1}
			>
				{currentGameSlug && !searchOpen && (
					<GameHomeIcon gameSlug={currentGameSlug} variant="light" />
				)}
				{currentGameSlug && (
					<RulesSearchIcon
						open={searchOpen}
						setOpen={setSearchOpen}
						variant="light"
					/>
				)}
				{currentGameSlug && !searchOpen && (
					<IconButton
						aria-label="Scan Card"
						variant="light"
						onClick={handleScannerOpen}
					>
						<QrCodeScannerIcon />
					</IconButton>
				)}
			</Stack>
			{(searchOpen && smDown) || (
				<Stack
					flexBasis={"30%"}
					direction="row"
					justifyContent="flex-end"
					spacing={1}
				>
					<LoginIconButton />
				</Stack>
			)}
		</TintedHeader >
	);
}


export default Header;