import { Box } from "@mui/material";
import { styled } from "@mui/system";
import SimpleBar from 'simplebar-react';


const Scrollbar = styled(SimpleBar)({
	width: "100%",
	height: "100%",
	"& .simplebar-content": {
		width: "100%",
		height: "100%",
		display: "flex",
	}
});


function CenteredContentScrollbar({ children }) {
	return (
		<Scrollbar>
			<Box margin="auto">
				{children}
			</Box>
		</Scrollbar>
	);
}

export default CenteredContentScrollbar;