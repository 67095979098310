import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import deco from "../assets/decorative/title-decoration.svg";


const Left = styled("div")({
	flex: "1 1 auto",
	background: `no-repeat right/auto 70% url(${deco})`,
});

const Right = styled(Left)({
	transform: "scaleX(-1)"
});

function FancyTypography(props) {
	return (
		<Stack direction="row" spacing={1}>
			<Left />
			<Typography {...props} />
			<Right />
		</Stack>
	);
}


export default FancyTypography;