import { Autocomplete, Grow, IconButton, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import useGameSettings from "../hooks/use-game-settings";
import useQuery from "../hooks/use-query";
import { useState } from "react";

function RulesSearchIcon({ open, setOpen, variant }) {
	const [searchInput, setSearchInput] = useState("");
	const [resultsOpen, setResultsOpen] = useState(false);
	const { getGameSettings } = useGameSettings();
	const { directives } = getGameSettings();
	const sendQuery = useQuery();

	function handleOpen() {
		setOpen(true);
	}

	function handleClose() {
		setResultsOpen(false);
		setOpen(false);
	}

	function handleSearchChange(e, newInput) {
		setSearchInput(newInput);
		setResultsOpen(newInput.length >= 3 ? true : false);
	}

	function handleOptionSelect(e, option) {
		if (option.key === "entry") {
			sendQuery({
				label: option.value,
				request: {
					type: "entries",
					factionSlug: "the-hundred-kingdoms",
					slug: "theist-priest",
				},
			});
		} else {
			sendQuery({
				label: option.value,
				request: {
					type: "directives",
					slug: option.key,
				},
			});
		}
		handleClose();
	}

	return (
		<>
			{!open && (
				<IconButton aria-label="Search" variant={variant} onClick={handleOpen}>
					<SearchIcon />
				</IconButton>
			)}
			<Grow in={open} unmountOnExit mountOnEnter>
				<Autocomplete
					open={resultsOpen}
					fullWidth
					onClose={handleClose}
					onBlur={handleClose}
					inputValue={searchInput}
					onInputChange={handleSearchChange}
					onChange={handleOptionSelect}
					disablePortal
					noOptionsText="Nothing found"
					id="search-results"
					options={directives}
					sx={{
						"& .MuiInput-root": {
							color: "white",
							"&:before": {
								borderBottom: "1px solid rgba(255, 255, 255, 0.42)",
							},
							"&:hover:not(.Mui-disabled):before": {
								borderBottom: "1px solid rgba(255, 255, 255, 0.42)",
							},
							"&:after": {
								borderBottom: "1px solid rgba(255, 255, 255, 0.42)",
							},
							"&:hover:after": {
								borderBottom: "1px solid rgba(255, 255, 255, 0.42)",
							},
						},
					}}
					forcePopupIcon={false}
					getOptionLabel={(o) => o.value}
					renderOption={(props, option) => (
						<li {...props} key={option.key}>
							{option.value}
						</li>
					)}
					renderInput={(params) => (
						<TextField
							variant="standard"
							size="small"
							autoFocus
							sx={{ color: "white" }}
							{...params}
						/>
					)}
				/>
			</Grow>
			{open && (
				<IconButton
					aria-label="Close search"
					variant={variant}
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
			)}
		</>
	);
}

export default RulesSearchIcon;
