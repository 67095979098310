import { Stack } from "@mui/material";
import FactionButton from "./FactionButton";


function FactionsList({ factions, onFactionSelect }) {
	const factionButtons = factions.map(faction => (
		<FactionButton
			key={faction.slug}
			faction={faction}
			onClick={onFactionSelect}
		/>
	));

	return (
		<Stack component="ul" spacing={2} px={2} py={1}>
			{factionButtons}
		</Stack >
	);
}


export default FactionsList;