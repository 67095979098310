import { useSelector } from "react-redux";
import useGameSettings from "../hooks/use-game-settings";


function Factions() {
	const {
		availableGames,
		currentGameSlug
	} = useSelector(state => state.games);
	const { getComponent } = useGameSettings();

	const { factions } = availableGames.find(g => g.slug === currentGameSlug);
	const FactionsComponent = getComponent("pages", "factions");

	return <FactionsComponent factions={factions} />
}


export default Factions;