import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useGameSettings from "../../hooks/use-game-settings";


function GameSelectorIcon({ gameSlug, variant }) {
	const { getGameSettings } = useGameSettings();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const { icon } = getGameSettings();
	const availableGames = useSelector(state => state.games.availableGames);
	const otherGames = availableGames.filter(g => g.slug !== gameSlug);

	function handleClose() {
		setAnchorEl(null);
	};

	function handleClick(e) {
		setAnchorEl(e.currentTarget);
	};

	const otherGamesMenuItems = otherGames.map(({ name, slug }) => (
		<MenuItem
			key={slug}
			component={Link}
			to={slug}
			onClick={handleClose}
			sx={{ gap: 1 }}
		>
			{getGameSettings(slug).iconLight}
			{name}
		</MenuItem>
	));

	return (
		<>
			<IconButton
				aria-label="Select different game"
				onClick={handleClick}
				variant={variant}
			>
				{icon}
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					component: "nav",
				}}
			>
				{otherGamesMenuItems}
			</Menu>
		</>
	);
}


export default GameSelectorIcon;