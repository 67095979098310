import { Stack } from "@mui/material";
import { useContext } from "react";
import FramePanelContent from "../../ui/containers/FramePanelContent";
import HorPanelsCtx from "../../ui/layouts/HorPanelsCtx";
import EntryList from "../entry/EntryList";
import EntryPanel from "../entry/EntryPanel";


function filteringFn(entry, filters) {
	const { types, gameClasses } = filters;

	if (types.length > 0 && !types.includes(entry.type)) {
		return false;
	}

	if (gameClasses.length > 0 && !gameClasses.includes(entry.gameClass)) {
		return false;
	}

	return true;
}


function sortingFn(a, b) {
	return a.toLowerCase()
		.localeCompare(b.toLowerCase());
}


function FactionPanelContent({ faction, filters }) {
	const { entryGroups, entries } = faction;
	const { onPanelAdd } = useContext(HorPanelsCtx);

	function handleEntrySelect(entry) {
		const body = (
			<EntryPanel
				key={entry.slug}
				panel={{ id: entry.slug, data: entry }}
				type="temporary"
			/>
		);

		onPanelAdd({
			id: entry.slug,
			type: "temporary",
			position: "right",
			closable: true,
			pinnable: true,
			body,
			actions: [],
		});
	}

	const entryLists = entryGroups.map(g => {
		const groupEntries = g.entrySlugs.sort(sortingFn).map(s => entries[s]);
		const filteredEntries = groupEntries.filter(e => filteringFn(e, filters));

		return (
			<EntryList
				key={g.slug}
				title={g.name}
				entries={filteredEntries}
				onEntrySelect={handleEntrySelect}
				factionSlug={faction.slug}
			/>
		);
	});

	return (
		<FramePanelContent>
			<Stack spacing={3}>
				{entryLists}
			</Stack>
		</FramePanelContent>
	);
}


export default FactionPanelContent;