import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";


function PromptDialog({ open, onClose, title, content, actions }) {
	const buttons = actions.map(a => (
		<Button
			key={a.label}
			size="small"
			variant={a.highlight && "contained"}
			onClick={a.handler}
		>
			{a.label}
		</Button>
	));

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>{content}</DialogContent>
			<DialogActions>{buttons}</DialogActions>
		</Dialog>
	)
}


export default PromptDialog;