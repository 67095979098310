import FramePanelContent from "../../ui/containers/FramePanelContent";
import EntryPanelHeader from "../entry/EntryPanelHeader";
import ListEntryContent from "./ListEntryContent";
import FramePanel from "../../ui/containers/FramePanel";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import HorPanelsCtx from "../../ui/layouts/HorPanelsCtx";
import FloatingActions from "../../ui/FloatingActions";
import CloseIcon from "@mui/icons-material/Close";
import { getNode } from "../../lib/list";
import ParsedListCtx from "../../store/ParsedListCtx";


function ListEntryPanel({ panel }) {
	// TODO: Finalize issue with re-renderings and remove tmpNode
	const { id, data: tmpnode } = panel;
	const { parsedList } = useContext(ParsedListCtx);
	const node = getNode(parsedList, tmpnode.path)
	const { listEntry } = node;
	const { onPanelRemove } = useContext(HorPanelsCtx);
	const [activeProfileIndex, setActiveProfileIndex] = useState(
		listEntry.profiles ? 0 : null
	);
	const { currentFactionSlug } = useSelector(state => state.games);

	// TODO: rework profiles
	const currentProfile = listEntry.profiles ? listEntry.profiles[activeProfileIndex] : null;
	if (currentProfile && currentProfile.active === false) {
		setActiveProfileIndex(0)
	}

	function handleProfileChange(index) {
		setActiveProfileIndex(index);
	}

	function handlePanelClose() {
		onPanelRemove(id);
	}

	// if (warbandId) {
	// 	const warband = list.warbands.find(w => w.id === warbandId)
	// 	const { character, regiments } = warband;
	// 	listEntry = character.id === entryId ? character : regiments.find(r => r.id === entryId);
	// } else {
	// 	if (list.character.id === entryId) listEntry = list.character;
	// 	if (list.secondCharacter?.id === entryId) listEntry = list.secondCharacter;
	// 	if (!listEntry) {
	// 		for (const r of list.regiments) {
	// 			if (r.id === entryId) listEntry = r;
	// 			if (r.officer?.id === entryId) listEntry = r.officer;
	// 		}
	// 	}
	// 	if (!listEntry) {
	// 		for (const r of list.retinues) {
	// 			if (r.id === entryId) listEntry = r;
	// 			if (r.officer?.id === entryId) listEntry = r.officer;
	// 		}
	// 	}
	// }

	// useEffect(() => {
	// 	if (listEntry?.profiles) {
	// 		setActiveProfile(listEntry?.profiles[0]);
	// 	}
	// }, [listEntry])

	// let listEntry = null;

	// useEffect(() => {
	// 	if (listEntry?.profiles) {
	// 		setActiveProfile(listEntry?.profiles[0]);
	// 	}
	// }, [listEntry])

	// if (warbandId) {
	// 	const warband = list.warbands.find(w => w.id === warbandId)
	// 	if(!warband) return;
	// 	const { character, regiments } = warband;
	// 	listEntry = character.id === entryId ? character : regiments.find(r => r.id === entryId);
	// } else {
	// 	if (list.character.id === entryId) listEntry = list.character;
	// 	if (list.secondCharacter?.id === entryId) listEntry = list.secondCharacter;
	// 	if (!listEntry) {
	// 		for (const r of list.regiments) {
	// 			if (r.id === entryId) listEntry = r;
	// 			if (r.officer?.id === entryId) listEntry = r.officer;
	// 		}
	// 	}
	// 	if (!listEntry) {
	// 		for (const r of list.retinues) {
	// 			if (r.id === entryId) listEntry = r;
	// 			if (r.officer?.id === entryId) listEntry = r.officer;
	// 		}
	// 	}
	// 	if(!listEntry) return;
	// }

	return (
		<FramePanel frameStyle="frame2" color="secondary">
			<EntryPanelHeader
				entry={listEntry}
				activeProfileIndex={activeProfileIndex}
				factionSlug={currentFactionSlug}
			/>
			<FramePanelContent>
				{/* <EntryPanelContent
					entry={entry}
					activeProfile={activeProfile}
					onProfileChange={handleProfileChange}
				/> */}
				<ListEntryContent
					listNode={node}
					activeProfileIndex={activeProfileIndex}
					onProfileChange={handleProfileChange}
				/>
			</FramePanelContent>
		</FramePanel>
	);
}


export default ListEntryPanel;