import { Stack } from "@mui/material";
import QueryRef from "./QueryRef";


function QueriesStack({ queries, ...stackProps }) {
	return (
		<Stack
			direction="row"
			flexWrap="wrap"
			gap={0.5}
			alignItems="center"
			{...stackProps}
		>
			{queries.map(q => (
				<QueryRef key={q.label} query={q} />
			))}
		</Stack>
	)
}


export default QueriesStack;