import { styled, Box } from "@mui/system";


const CenteredContentBox = styled(Box)({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	width: "100%",
	height: "100%",
});


export default CenteredContentBox;