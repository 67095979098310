import { Typography } from "@mui/material";
import FloatingActions from "../../ui/FloatingActions";
import FrameDialogContent from "../../ui/containers/FrameDialogContent";
import FrameHeader from "../../ui/containers/FrameHeader";


function ErrorResponse({ queryLabel }) {
	return (
		<>
			<FrameHeader title="Oops!" />
			<FrameDialogContent>
				<Typography variant="h6" textAlign="center" mb={1} >Our Rules Drones couldn't get this for you!</Typography>
				<Typography mb={1}>Should the rule does not appear you can contact us directly at rules@para-bellum.com</Typography>
				<Typography mb={1}>If you are scanning a QR code make sure the game system you have currently selected corresponds to the QR code you are trying to scan!</Typography>
			</FrameDialogContent>
		</>
	);
}


export default ErrorResponse;