import {
	Divider,
	IconButton,
	Stack,
	SvgIcon,
	Typography
} from "@mui/material";
import { Box } from "@mui/system";
import useGameSettings from "../../../hooks/use-game-settings";
import FrameHeader from "../../../ui/containers/FrameHeader";
import ArmyRules from "./ArmyRules";
import ListShareIcon from "./ListShareIcon";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { getPdf } from "../../../lib/api";
import { useSelector } from "react-redux";
import AlertButton from "../../bits/AlertButton";
import ListSettingsIcon from "../ListSettingsIcon";
import QRCode from "qrcode-svg";
import UserCtx from "../../../store/UserCtx";
import { useContext } from "react";
import LZString from "../../../lib/lz-string";
import FactionQueriesIcon from "../../faction/FactionQueriesIcon";

function hasAlerts(node) {
	return node.alerts?.length > 0;
}

function checkForAlerts(node) {
	if (hasAlerts(node)) return true;
	for (const child of node.children) {
		if (checkForAlerts(child)) return true;
	}
	return false;
}

function ListPanelHeader({ faction, parsedList }) {
	const { id, name, factionSlug, gameSlug, points, cost, options = {}, alerts, tagGroups = [] } = parsedList;
	const { getFactionSettings } = useGameSettings();
	const { icon } = getFactionSettings(factionSlug);
	const armyRules = options?.groups?.find(g => g.slug === "army-rules" && g.active !== false);
	const armyTags = tagGroups.find(g => g.slug === "army-rules");
	const { availableGames, currentGameSlug } = useSelector(state => state.games);
	const game = availableGames.find(g => g.slug === currentGameSlug);
	const { currentUser } = useContext(UserCtx);

	function handlePdf() {
		const minified = JSON.stringify({
			username: currentUser?.username,
			pin: currentUser?.pin,
			list: {
				id,
				gameSlug,
				factionSlug,
				points,
				hasAlerts: checkForAlerts(parsedList),
			}
		});
		const compressed = LZString.compressToEncodedURIComponent(minified);

		const qrCode = currentUser ? new QRCode({
			content: compressed,
			padding: 2,
			// width: 256,
			// height: 256,
			color: "#000000",
			background: "#ffffff",
			ecl: "L",
		}).svg() : null;

		getPdf(`${name}.pdf`, {
			list: {
				game: {
					name: game.name,
					slug: currentGameSlug,
				},
				faction: {
					name: faction.name,
					slug: faction.slug
				},
				data: parsedList,
				qrCode,
			}
		});
	}

	return (
		<FrameHeader>
			<Stack direction="row" spacing={1} pt={[0, 1]}>
				<SvgIcon
					component={icon}
					sx={{
						fontSize: "70px",
						mx: "auto"
					}}
				/>
				<Box flexGrow={1}>
					<Stack direction="row">
						<Stack spacing={0.5} flexGrow={1}>
							<Typography variant="h4" noWrap>{name}</Typography>
							{(armyRules || armyTags) && <ArmyRules optionsGroup={armyRules} tagGroup={armyTags} />}
						</Stack>
						<Stack>
							<Box>
								<Typography variant="h6" textAlign="center">
									{cost}
								</Typography>
								<Divider light />
								<Typography variant="h6" textAlign="center">
									{points}
								</Typography>
							</Box>
						</Stack>
					</Stack>
					<Stack
						direction="row"
						justifyContent="space-between"
						spacing={1}
						mt={1}
					>
						{!!alerts.length && <AlertButton alerts={alerts} concerning="List" />}
						<Box flexGrow={1} />
						<ListShareIcon game={game} faction={faction} list={parsedList} />
						<IconButton variant="light" onClick={handlePdf}>
							<PictureAsPdfIcon />
						</IconButton>
						<FactionQueriesIcon
							queries={faction.queries}
							variant="light"
						/>
						<ListSettingsIcon />
					</Stack>
				</Box>
			</Stack >
		</FrameHeader >
	);
}


export default ListPanelHeader;