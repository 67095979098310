import { Stack, Typography } from "@mui/material";
import Tag from "../../bits/Tag";
import NoiseControlOffIcon from '@mui/icons-material/NoiseControlOff';


function sortingFn(a, b) {
	return a.name.toLowerCase()
		.localeCompare(b.name.toLowerCase());
}

function EntryTags({ tagGroups }) {
	const groups = tagGroups.map(g => (
		<div key={g.slug}>
			<Stack direction="row" alignItems="center" spacing={0.5}>
				<NoiseControlOffIcon fontSize="small" />
				<Typography variant="h5">{g.name}</Typography>
			</Stack>
			{g.description && <Typography>{g.description}</Typography>}
			<Stack
				direction="row"
				flexWrap="wrap"
				gap={0.5}
				alignItems="center"
			>
				{g.tags.map(t => (
					<Tag key={t.slug} data={t} />
				))}
			</Stack>
		</div>
	));

	return (
		<Stack spacing={1}>
			{groups}
		</Stack>
	);
}


export default EntryTags;