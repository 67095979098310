import { useState } from "react";
import FactionPanelHeader from "./FactionPanelHeader";
import FactionPanelContent from "./FactionPanelContent";
import FramePanel from "../../ui/containers/FramePanel";


const initialFilters = {
	types: [],
	gameClasses: [],
};


function FactionPanel({ panel }) {
	const { data: faction } = panel;
	const [filters, setFilters] = useState(initialFilters);

	function handleFiltersApply(filters) {
		setFilters(filters);
	}

	function handleFiltersReset() {
		setFilters(initialFilters);
	}

	return (
		<FramePanel color="darkBgr">
			<FactionPanelHeader
				faction={faction}
				filters={filters}
				onFiltersReset={handleFiltersReset}
				onFiltersApply={handleFiltersApply}
			/>
			<FactionPanelContent faction={faction} filters={filters} />
		</FramePanel >
	);
}


export default FactionPanel;