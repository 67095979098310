import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { useLoaderData, useNavigate, useNavigation, useParams } from "react-router-dom";
import { getList } from "../lib/api";
import { HorPanelsCtxProvider } from "../ui/layouts/HorPanelsCtx";
import { useDispatch, useSelector } from "react-redux";
import { openListSetup } from "../store/utils-slice";
import { setCurrentFaction } from "../store/games-slice";
import useGameSettings from "../hooks/use-game-settings";
import CenteredContentBox from "../ui/containers/CenteredContentBox";
import Loading from "../ui/Loading";
import { newList, updateLinkedNodes, updateOptions, cleanupList } from "../store/list-slice";
import { parseList } from "../lib/list";
import { ParsedListCtxProvider } from "../store/ParsedListCtx";


function Editor() {
	const navigate = useNavigate();
	const { state } = useNavigation();
	const dispatch = useDispatch();
	const { listId } = useParams();
	const { remoteList, localList, faction } = useLoaderData() || {};
	const unsavedData = !listId && localStorage.getItem("unsaved-list");
	const unsavedList = unsavedData ? JSON.parse(unsavedData) : null;
	const { getComponent } = useGameSettings();
	const { currentFactionSlug } = useSelector(state => state.games);
	const EditorComponent = getComponent("pages", "editor");
	const list = useSelector(state => state.list);
	const open = !list && !!remoteList && !!localList;
	const { getGameSettings } = useGameSettings();
	const { targets, effects, rules } = getGameSettings();
	const rulepack = { targets, effects, rules };
	const finalList = localList || remoteList;

	useEffect(() => {
		dispatch(setCurrentFaction(faction.slug));
		dispatch(newList(finalList));
	}, [finalList, faction, dispatch])

	const parsedResults = useMemo(() => {
		if (list.id === listId || list.factionSlug === faction.slug) {
			return parseList(list, rulepack, faction);
		}
	}, [list, faction, rulepack]);

	const { pList: parsedList, cleanup = [] } = parsedResults || {};

	useEffect(() => {
		if (cleanup.length) {
			dispatch(cleanupList(cleanup));
		}
	}, [cleanup, dispatch]);


	function handleResume() {
		if (listId) {
			dispatch(newList(localList));
		} else {
			navigate(unsavedList.id);
		}
	}

	function handleLoad() {
		dispatch(newList(remoteList));
	}

	function handleNew() {
		dispatch(openListSetup());
	}

	if (state === "loading") {
		return (
			<CenteredContentBox>
				<Loading />
			</CenteredContentBox>
		);
	}

	if (!listId && unsavedList) {
		return (
			<Dialog open>
				<DialogTitle>Resume edit?</DialogTitle>
				<DialogContent>
					<Typography>
						You have an unsaved list stored locally. Would you like to resume
						editing or start a new one?
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleNew}>New list</Button>
					<Button onClick={handleResume}>Resume</Button>
				</DialogActions>
			</Dialog>
		);
	} else if (!listId && !unsavedList) {
		return (
			<Dialog open>
				<DialogTitle>No list</DialogTitle>
				<DialogContent>
					<Typography>
						You have not selected any list. Would you like to start a new one?
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleNew}>New list</Button>
				</DialogActions>
			</Dialog>
		);
	}

	return (
		<>
			<HorPanelsCtxProvider>
				<ParsedListCtxProvider>
					{parsedList && <EditorComponent faction={faction} parsedList={parsedList} />}
				</ParsedListCtxProvider>
			</HorPanelsCtxProvider>
			<Dialog open={open}>
				<DialogTitle>Resume edit?</DialogTitle>
				<DialogContent>
					<Typography>
						You have an unsaved version of this list stored locally. Would
						you like to resume editing or load the saved version?
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleLoad}>Load saved</Button>
					<Button variant="contained" onClick={handleResume}>Resume</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}


export function loader({ params }) {
	return getList(params.listId, params.gameSlug);
}


export default Editor;