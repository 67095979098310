import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton, Stack } from "@mui/material";
import { useState } from "react";
import { useDispatch } from 'react-redux';
import { useRouteLoaderData } from 'react-router-dom';
import { updateData, updateLinkedNodes, updateOptions } from '../../store/list-slice';
import FloatingActions from "../../ui/FloatingActions";
import FrameDialog from "../../ui/containers/FrameDialog";
import FrameDialogContent from "../../ui/containers/FrameDialogContent";
import FrameHeader from "../../ui/containers/FrameHeader";
import LabelledGroup from "../../ui/containers/LabelledGroup";
import EntryList from '../entry/EntryList';
import QueryIcon from "../query/QueryIcon";
import ListEntryButton from './ListEntryButton';
import EditIcon from '@mui/icons-material/Edit';

function EntrySelector({ node, selector }) {
	const { name, slug, query, targets = [] } = selector;
	const { linkedNodes, path } = node;
	const [open, setOpen] = useState(false);
	const { faction } = useRouteLoaderData("editor");
	const dispatch = useDispatch();

	function handleOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	function handleSelect(node) {
		dispatch(updateLinkedNodes({
			path,
			selectorSlug: slug,
			nodePaths: [node.path],
		}));
	}

	const buttons = targets.map(t => (
		<ListEntryButton
			key={t.id}
			node={t}
			factionSlug={faction.slug}
			onSelect={handleSelect.bind(null, t)}
			noActions
		/>
	));

	const selectedPaths = linkedNodes[slug] || [];
	const content = targets.filter(t => selectedPaths.includes(t.path)).map(s => (
		<ListEntryButton
			key={s.id}
			node={s}
			factionSlug={faction.slug}
			noActions
		/>
	));


	const actions = [
		{
			id: "Close",
			icon: ArrowBackIcon,
			handler: handleClose,
		}
	];

	return (
		<>
			<LabelledGroup
				label={name}
				action={<IconButton onClick={handleOpen} size="small">
					<EditIcon />
				</IconButton>}
			>
				{content}
			</LabelledGroup>
			<FrameDialog open={open} onClose={handleClose} responsive color="darkBgr" actions={actions}>
				<FrameHeader title={name} subtitle="Select target" />
				<FrameDialogContent>
					<Stack spacing={1}>
						{buttons.length ? buttons : "No valid entries exist in the list."}
					</Stack>
				</FrameDialogContent>
			</FrameDialog>
		</>
	);
}


export default EntrySelector;