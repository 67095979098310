// import { FB_DWEGHOM } from "./fb/dweghom";
// import { FB_NORDS } from "./fb/nords";
// import { TLAOK_HUNDRED } from "./tlaok/hundred";
// import { FB_HUNDRED } from "./fb/hundred";
// import { FB_SPIRES } from "./fb/spires";
// import { FB_WADRHUN } from "./fb/wadrhun";
// import { FB_DOMINION } from "./fb/dominions";
// import { TLAOK_SPIRES } from "./tlaok/spires";
// import { TLAOK_DWEGHOM } from "./tlaok/dweghom";
// import { TLAOK_NORDS } from "./tlaok/nords";
// import { TLAOK_WADRHUN } from "./tlaok/wadrhun";
// import { TLAOK_DOMINION } from "./tlaok/dominion";
import TLAOK_SETTINGS from "./tlaok/settings";
import FB_SETTINGS from "./fb/settings";


const GAMES_SETTINGS = {
	"tlaok": TLAOK_SETTINGS,
	"fb": FB_SETTINGS,
}


// export function getGameSetting(gameSlug, setting) {
// 	const value =
// 		GAMES_SETTINGS[gameSlug]?.[setting] ||
// 		GAMES_SETTINGS["default"]?.[setting];

// 	return value;
// }


// export function getFactionSetting(gameSlug, factionSlug, setting) {
// 	const value =
// 		GAMES_SETTINGS[gameSlug]?.factions[factionSlug]?.[setting] ||
// 		GAMES_SETTINGS[gameSlug]?.factions["default"]?.[setting];

// 	return value;
// }


// export function getEntrySetting(gameSlug, factionSlug, entrySlug, setting) {
// 	const value =
// 		GAMES_SETTINGS[gameSlug]?.factions[factionSlug]?.entries[entrySlug]?.[setting] ||
// 		GAMES_SETTINGS[gameSlug]?.factions[factionSlug]?.entries["default"]?.[setting];

// 	return value;
// }


// // TODO: Remove after setting up the remote fetching of factions.
// export const factionMapping = {
// 	"tlaok": {
// 		"the-hundred-kingdoms": TLAOK_HUNDRED,
// 		"dweghom": TLAOK_DWEGHOM,
// 		"nords": TLAOK_NORDS,
// 		"the-spires": TLAOK_SPIRES,
// 		"the-wadrhun": TLAOK_WADRHUN,
// 		"the-old-dominion": TLAOK_DOMINION,
// 	},
// 	"fb": {
// 		"dweghom": FB_DWEGHOM,
// 		"nords": FB_NORDS,
// 		"the-hundred-kingdoms": FB_HUNDRED,
// 		"the-spires": FB_SPIRES,
// 		"the-wadrhun": FB_WADRHUN,
// 		"the-old-dominion": FB_DOMINION,
// 	}
// }


export default GAMES_SETTINGS;