import { Stack, TextField } from "@mui/material";
import useGameSettings from "../../hooks/use-game-settings";
import StepperField from "../../ui/StepperField";


function ListSettings({ inputs, onNameChange, onPointsChange }) {
	const { name, nameStatus, points, pointsStatus } = inputs;
	const { getGameSettings } = useGameSettings();
	const { listPointsStep, listMinPoints } = getGameSettings();

	function handleStepPointsUp() {
		const newPoints = inputs.points < listMinPoints ?
			listMinPoints :
			inputs.points + listPointsStep;
		onPointsChange(newPoints);
	}

	function handleStepPointsDown() {
		const newPoints = inputs.points - listPointsStep;
		onPointsChange(newPoints < listMinPoints ? listMinPoints : newPoints);
	}

	function handlePointsChange(e) {
		const points = e.target.valueAsNumber;
		if (Number.isInteger(points)) {
			onPointsChange(points);
		}
	}

	function handleNameChange(e) {
		onNameChange(e.target.value)
	}

	return (
		<Stack spacing={2} pt={1} pb={2} px={[0, 1]} alignItems="center">
			<TextField
				label="Name"
				size="small"
				required
				fullWidth
				value={name}
				onChange={handleNameChange}
				helperText={nameStatus}
				error={!!nameStatus}
			/>
			<StepperField
				label="Points"
				size="small"
				required
				fullWidth
				value={points}
				onChange={handlePointsChange}
				onStepUp={handleStepPointsUp}
				onStepDown={handleStepPointsDown}
				helperText={pointsStatus}
				error={!!pointsStatus}
				sx={{
					maxWidth: "250px"
				}}
			/>
		</Stack>
	);
}


export default ListSettings;