import { Stack } from "@mui/material";
import FrameBox from "./FrameBox";


function FramePanel(props) {
	const {
		frameStyle = "frame1",
		color = "primary",
		children,
	} = props;

	return (
		<FrameBox
			frameStyle={frameStyle}
			bgcolor={`${color}.main`}
			color={`${color}.contrastText`}
			width="100%"
			height="100%"
			responsive
		>
			<Stack height="100%" overflow="hidden">
				{children}
			</Stack>
		</FrameBox>
	);
}


export default FramePanel;