import { Stack, Typography } from "@mui/material";
import LabelledGroup from "../../../ui/containers/LabelledGroup";
import Reference from "../../../ui/Reference";
import ReferenceBox from "../../../ui/ReferenceBox";


function Warband({ warband }) {
	const {mainstay = [], restricted = []} = warband;
	
	const mainstayEntries = mainstay.length ? mainstay.map(e => (
		<ReferenceBox key={e.slug}>
			<Typography mx={1}>{e.name}</Typography>
		</ReferenceBox>
	)) : (
		<Typography>None</Typography>
	)
	
	const restrictedEntries = restricted.length ? restricted.map(e => (
		<ReferenceBox key={e.slug}>
			<Typography mx={1}>{e.name}</Typography>
		</ReferenceBox>
	)) : (
		<Typography>None</Typography>
	)

	return (
		<Stack spacing={1}>
			<LabelledGroup label="Mainstay">
				<Stack direction="row" flexWrap="wrap" gap={1}>
					{mainstayEntries}
				</Stack>
			</LabelledGroup>
			<LabelledGroup label="Restricted">
				<Stack direction="row" flexWrap="wrap" gap={1}>
					{restrictedEntries}
				</Stack>
			</LabelledGroup>
		</Stack>
	);
}


export default Warband;