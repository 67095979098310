import { Typography } from "@mui/material";
import RippedBox from "./RippedBox";


function FrameHeader({ title, subtitle, children }) {
	const content = children || (
		<>
			<Typography variant="h4">{title}</Typography>
			<Typography variant="subtitle1">{subtitle}</Typography>
		</>
	);

	return (
		<RippedBox
			rippedSide="bottom"
			bgcolor="primary.main"
			color="primary.contrastText"
			p={2}
		>
			{content}
		</ RippedBox>
	);
}


export default FrameHeader;