// The prefix "p" is used to differentiate elements of the parsed list.

import { v4 as uuid } from 'uuid';
import listGeneral from './rules-to-delete/tlaok/list-general';
import listGeneralFB from './rules-to-delete/fb/list-general';


let pList = null;
let nodeEffects = [];
let nodeRules = [];
let targetFns = {};
let effectFns = {};
let ruleFns = {};
let cleanup = [];
let cleanupSelectors = [];


function priorityDesc(a, b) {
	const { priority: pA = 0 } = a;
	const { priority: pB = 0 } = b;
	return pB - pA;
}


export function createNode(data) {
	return {
		...data,
		id: uuid(),
		selections: [],
		linkedNodes: {},
		children: [],
	};
}


export function getNode(root, path) {
	if (path === "") return root;
	try {
		return path.split("/").filter(id => id !== "").reduce((prev, cur) =>
			prev.children.find(c => c.id === cur), root);
	} catch (e) {
		console.error(`Error in getNode: Invalid path ${path}`);
		return null;
	}
}


function reId(node) {
	node.id = uuid();
	node.children?.forEach(c => reId(c));
}


export function getDuplicate(root, path) {
	let clone = null;
	const splitPath = path.split("/");
	const id = splitPath.pop();
	const parent = getNode(root, splitPath.join("/"));

	if (parent) {
		const node = parent.children.find(c => c.id === id);
		if (node) {
			clone = JSON.parse(JSON.stringify(node));
			if (clone.name) clone.name += " (Copy)";
			reId(clone);
		}
	}

	return clone;
}


function injectFactionOptions(node, faction) {
	// if (!node.options && faction.options) {
	node.options = faction.options ? JSON.parse(JSON.stringify(faction.options)) : null;
	// }
	// if (!node.tagGroups && faction.tagGroups) {
	node.tagGroups = faction.tagGroups ? JSON.parse(JSON.stringify(faction.tagGroups)) : [];
	// }
	return node;
}


/**
 * The parsed node must be mutable so it can't be a shallow copy. Entries and
 * options are deep copies.
 */
function parseNode(pParent, node, faction) {
	const { entrySlug, children = [], selections = [], linkedNodes = [], sizeSteps } = node;

	if (entrySlug && !faction.entries[entrySlug]) return;

	const pNode = {
		...node,
		selections: [...selections],
		linkedNodes: { ...linkedNodes },
		children: [],
		path: pParent ? `${pParent.path}/${node.id}` : "",
		parent: pParent,
		cost: 0,
		alerts: [],
	};

	if (!pParent) injectFactionOptions(pNode, faction);

	if (entrySlug) {
		pNode.listEntry = JSON.parse(JSON.stringify(faction.entries[entrySlug]));
		const { options, rules, effects, entrySelectors } = pNode.listEntry;

		// TODO: Remove the hacks
		if (selections.includes("warlord")) {
			pNode.listEntry.isWarlord = true;
		}

		if (sizeSteps) pNode.listEntry.size += pNode.listEntry.sizeStep * sizeSteps;

		if (options) parseOptions(pNode, options);
		// if (pNode.listEntry.options) parseOptions(pNode, pNode.listEntry.options);
		if (rules) nodeRules.push({ node: pNode, rules: rules });
		if (effects) nodeEffects.push({ node: pNode, effects: effects, source: pNode });
	}

	if (pNode.options) {
		parseOptions(pNode, pNode.options);
	}

	if (pParent) {
		pParent.children.push(pNode);
	}
	else {
		pList = pNode;
	}

	children.forEach(c => parseNode(pNode, c, faction));
}


function parseOptions(pNode, options) {
	const { selections } = pNode;
	const { groups = [], orphans = [], effects = [], rules = [], active } = options;

	let selected = 0;

	orphans.forEach(o => {
		const occurences = selections.filter(s => s === o.slug).length;
		if (occurences) {
			selected += 1;
			o.selected = true;
			o.size = occurences;

			if (o.effects) {
				nodeEffects.push({
					node: pNode,
					effects: o.effects,
					source: o
				});
			}

			if (o.rules) {
				nodeRules.push({
					node: pNode,
					rules: o.rules,
				});
			}

			effects.forEach(e => {
				if (e.type === "MASTERIES_PRE") {
					const targets = getTargets(pNode, { type: "ALL_OTHER_CHARACTERS" });
					targets.forEach(t => effectFns["MASTERIES_PRE"](t, {}, o, pList));
				} else {

					nodeEffects.push({
						node: pNode,
						effects: effects,
						source: o
					});
				}
			})
		}
	});

	groups.forEach(g => selected += parseOptions(pNode, { effects, ...g }));

	if (selected > 0 && rules.length) {
		nodeRules.push({ node: pNode, rules: rules });
	}

	return selected;
}


function getTargets(origin, targets) {
	const { type, params } = targets;
	if (targetFns[type]) {
		return targetFns[type](pList, origin, params);
	} else {
		console.error(`Error in getTargets: Unknown target type ${type}`);
		return [];
	}
}


function applyEffects(pList, faction) {
	const targetedEffects = [];

	// Expand targets
	nodeEffects.forEach(ne => {
		const { node, effects, source } = ne;
		effects.forEach(e => {
			const targetNodes = getTargets(node, e.targets);
			targetNodes.forEach(n => targetedEffects.push({
				type: e.type,
				params: e.params,
				target: n,
				source,
				priority: e.priority,
			}));
		});
	});

	// TODO: Sort by priority
	targetedEffects.sort(priorityDesc);

	// Execute effect functions
	targetedEffects.forEach(e => {
		const { type, params, target, source } = e;
		if (effectFns[type]) {
			if (source.active !== false) {
				effectFns[type](target, params, source, pList, faction);
			}
		} else {
			console.error(`Error in applyEffects: Unknown effect type ${type}`);
		}
	});
}


function applyRules(faction) {
	const targetedRules = [];

	// Expand targets
	nodeRules.forEach(nr => {
		const { node, rules } = nr;
		rules.forEach(r => {
			const targetNodes = getTargets(node, r.targets);
			targetNodes.forEach(n => targetedRules.push({
				type: r.type,
				target: n,
				params: r.params,
			}));
		});
	});

	// Execute rules functions
	targetedRules.forEach(r => {
		const { type, target, params } = r;
		if (ruleFns[type]) {
			ruleFns[type](target, params, faction);
		} else {
			console.error(`Error in applyRules: Unknown rule type ${type}`);
		}
	});
}


function calculateOptionsCost(options) {
	const { groups = [], orphans = [] } = options;
	let cost = 0;

	const groupsCost =
		groups.reduce((prev, g) => calculateOptionsCost(g) + prev, 0);
	cost += groupsCost;

	// TODO: Merge selected and size into one solution.
	orphans.forEach(o => {
		if (o.size > 0 && Number.isInteger(o.cost)) cost += o.cost * o.size;
		else if (o.selected && Number.isInteger(o.cost)) cost += o.cost;
	});

	return cost;
}


function calculateNodeCost(node) {
	const { listEntry, children = [], sizeSteps, type } = node;

	const sumChildren = children.reduce((prev, c) => {
		calculateNodeCost(c);
		return Number.isInteger(c.cost) ? prev + c.cost : prev;
	}, 0);

	if (listEntry) {
		const { size, minSize, sizeStepCost, options } = listEntry;
		// if (size > minSize) {
		// 	listEntry.cost += (size - minSize) * sizeStepCost;
		// }
		if (sizeSteps) {
			listEntry.cost += sizeSteps * sizeStepCost;
		}
		if (options) {
			const optionsCost = calculateOptionsCost(options);
			if (Number.isInteger(listEntry.cost) && optionsCost > 0) {
				listEntry.cost += optionsCost;
			} else if (!Number.isInteger(listEntry.cost) && optionsCost > 0) {
				listEntry.cost = optionsCost;
			}
		}
	}

	node.cost = sumChildren + (listEntry && type !== "officer" ? listEntry.cost : 0);
}

function limit(node) {
	ruleFns["LIMIT_OPTIONS"](node)
	node.children?.forEach(c => limit(c));
}


// Removes selections whose corresponding options are disabled or their entire
// group is disable. Must take place after applying effects.
function cleanupSelections(node) {
	const { selections = [], listEntry, options: nodeOptions = {}, children = [], linkedNodes = {} } = node;
	const toRemove = [];

	if (selections.length) {
		if (listEntry) {
			const { options = {} } = listEntry;
			cleanupGroup(options, selections, toRemove);
		}

		if (nodeOptions) {
			cleanupGroup(nodeOptions, selections, toRemove);
		}
	}

	const toUnlink = [];
	const selectorSlugs = Object.keys(linkedNodes);
	selectorSlugs.forEach(slug => {
		const selectors = listEntry.entrySelectors || [];
		const selector = selectors.find(s => s.slug === slug);

		if (selector.active === false) {
			toUnlink.push(selector.slug);
		}
	});

	const officers = children.filter(c => c.type === "officer");
	officers.forEach(o => {
		if (!selections.includes(o.entrySlug)) {
			cleanup.push({ path: o.path, remove: true });
		}
	});

	const slugs = selections.filter(s => toRemove.includes(s));
	if (slugs.length || toUnlink.length) {
		cleanup.push({ path: node.path, optionSlugs: slugs, selectorSlugs: toUnlink });
	}

	children.forEach(c => cleanupSelections(c));
}

function cleanupGroup(group, selections, toRemove) {
	const { groups = [], orphans = [] } = group;

	orphans.forEach(o => {
		if (o.active === false) {
			o.selected = false;
			toRemove.push(o.slug);
		}
	});

	groups.forEach(g => {
		const { orphans = [] } = g;
		if (g.active === false) {
			toRemove.push(...getAllOptionSlugs(g));
		} else {
			cleanupGroup(g, selections, toRemove)
		}
	});
}

function getAllOptionSlugs(group) {
	const slugs = [];
	const { groups = [], orphans = [] } = group;
	slugs.push(...orphans.map(o => o.slug));
	groups.forEach(g => slugs.push(...getAllOptionSlugs(g)));
	return slugs;
}


export function parseList(list, rulepack, faction) {
	pList = {};
	nodeEffects = [];
	nodeRules = [];
	cleanup = [];
	cleanupSelectors = [];
	targetFns = rulepack.targets;
	effectFns = rulepack.effects;
	ruleFns = rulepack.rules;

	parseNode(null, list, faction);
	applyEffects(pList, faction);
	cleanupSelections(pList);
	calculateNodeCost(pList);
	applyRules(faction);
	limit(pList)
	if (pList.gameSlug === "tlaok") listGeneral(pList);
	if (pList.gameSlug === "fb") listGeneralFB(pList);

	return {
		pList,
		cleanup,
		cleanupSelectors,
	};
}