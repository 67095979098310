import {
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText
} from "@mui/material";
import { styled } from "@mui/system";
import WarningIcon from '@mui/icons-material/Warning';
import { keyframes } from "@emotion/react";
import { useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FrameDialog from "../../ui/containers/FrameDialog";
import FrameHeader from "../../ui/containers/FrameHeader";
import FrameDialogContent from "../../ui/containers/FrameDialogContent";


const sizePulse = keyframes`
	from {transform: scale(1)},
	to {transform: scale(1.1)}
`;


const Alert = styled(WarningIcon)({
	filter: "drop-shadow(0 0 1px #fff)drop-shadow(0 0 2px #fff)",
	animation: `${sizePulse} 0.7s infinite alternate linear`,
});


function AlertButton({ alerts, concerning }) {
	const [open, setOpen] = useState(false);

	function handleOpen(e) {
		setOpen(true);
		e.stopPropagation();
	}

	function handleClose() {
		setOpen(false);
	}

	const actions = [
		{
			id: "Close",
			icon: ArrowBackIcon,
			handler: handleClose,
		},
	];

	return (
		<>
			<IconButton color="error" onClick={handleOpen}>
				<Alert />
			</IconButton>
			<FrameDialog open={open} responsive onClose={handleClose} actions={actions}>
				<FrameHeader title="Issues" subtitle={concerning} />
				<FrameDialogContent>
					<List disablePadding>
						{alerts.map((a, i) => (
							<ListItem key={i}>
								<ListItemIcon sx={{ minWidth: "40px" }}>
									<WarningIcon color="error" />
								</ListItemIcon>
								<ListItemText primary={a.message}></ListItemText>
							</ListItem>
						))}
					</List>
				</FrameDialogContent>
			</FrameDialog>
		</>
	);
}


export default AlertButton;