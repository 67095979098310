import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { attachNode, removeNode } from "../../../store/list-slice";
import FrameDialogContent from "../../../ui/containers/FrameDialogContent";

import FramePanelContent from "../../../ui/containers/FramePanelContent";
import Section from "../../../ui/containers/Section";
import ListEntryButton from "../../list-entry/ListEntryButton";
import CharacterRegiment from "./CharacterRegiment";
import NewCharacterButton from "./NewCharacterButton";
import NewRegimentButton from "./NewRegimentButton";
import Regiment from "./Regiment";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useState, useContext } from "react";
import { createNode } from "../../../lib/list";
import FrameDialog from "../../../ui/containers/FrameDialog";
import FrameHeader from "../../../ui/containers/FrameHeader";
import EntryList from "../../entry/EntryList";
import FloatingActions from "../../../ui/FloatingActions";
import { useDispatch } from "react-redux";
import HorPanelsCtx from "../../../ui/layouts/HorPanelsCtx";
import ThePerfectToolButton from "./ThePerfectToolButton";
import BestOfTheRajakurButton from "./BestOfTheRajakurButton";

function sortingFn(a, b) {
	return a.toLowerCase()
		.localeCompare(b.toLowerCase());
}


function ListPanelContent({ faction, parsedList }) {
	const [nodeToSwap, setNodeToSwap] = useState(null);
	const open = !!nodeToSwap;
	const { onPanelRemove } = useContext(HorPanelsCtx);

	const { children } = parsedList;
	const characters = children.filter(c => c.type === "character");
	const regiments = children.filter(c => c.type === "regiment");
	const dispatch = useDispatch();

	function handleSwap(node, event) {
		setNodeToSwap(node);
		event.stopPropagation();
	}

	function handleClose() {
		setNodeToSwap(null);
	}

	function handleSwapConfirm(entry) {
		const node = createNode({
			type: "character",
			entrySlug: entry.slug,
		});

		const parent = nodeToSwap.parent;
		const characters = parent.children.filter(c => c.type === "character");

		characters.forEach(character => {
			onPanelRemove(character.id);
			character.children.forEach(c => onPanelRemove(c.id));
			dispatch(removeNode({ parentPath: parent.path, nodeId: character.id }));
		});

		dispatch(attachNode({ path: "", node }));
		handleClose();
	}

	const characterSlugs = faction.entryGroups
		.find(g => g.slug === "characters").entrySlugs.filter(s => s !== "mimetic-assassin");

	const allCharacters =
		[...characterSlugs].sort(sortingFn).map(s => faction.entries[s]);

	const actions = [
		{
			id: "close",
			icon: ArrowBackIcon,
			handler: handleClose,
		}
	];

	const firstCharacter = characters[0] ? <CharacterRegiment key={characters[0].id} node={characters[0]} factionSlug={faction.slug} onSwap={handleSwap.bind(null, characters[0])} /> : null;
	const otherCharacters = characters.slice(1).filter(c => c.listEntry.slug !== "mimetic-assassin").map(c => (
		<CharacterRegiment key={c.id} node={c} factionSlug={faction.slug} forceDelete />
	));
	const perfectTool = characters.filter(c => c.listEntry.slug === "mimetic-assassin").map(c => (
		<CharacterRegiment key={c.id} node={c} factionSlug={faction.slug} forceDelete />
	));
	const regimentEntries = regiments.map(r => <Regiment key={r.id} node={r} factionSlug={faction.slug} />);

	return (
		<>
			<FramePanelContent>
				<Stack spacing={5}>
					{characters.length > 0 && (
						<Section title="Character Regiments" darkBgr>
							<Stack spacing={3}>
								{firstCharacter}
								{otherCharacters}
								{perfectTool}
							</Stack>
						</Section>
					)}
					{characters.length < 1 && <NewCharacterButton faction={faction} />}
					{characters.length === 1 && faction.slug === "the-spires" && <ThePerfectToolButton />}
					{characters.length === 1 && faction.slug === "sorcerer-kings" && <BestOfTheRajakurButton />}
					{characters.length > 0 && characters[0].entrySlug === "order-of-the-ashen-dawn" && (
						<NewCharacterButton faction={faction} dawn={true} />
					)}
					{characters.length > 0 && (
						<Section title="Regiments" darkBgr>
							<Stack spacing={3}>
								{regimentEntries}
								{characters.length > 0 && <NewRegimentButton faction={faction} />}
							</Stack>
						</Section>
					)}
				</Stack>
			</FramePanelContent>
			<FrameDialog
				open={open}
				onClose={handleClose}
				responsive
				color="darkBgr"
				actions={actions}
			>
				<FrameHeader title="Select Character" />
				<FrameDialogContent>
					<EntryList
						title="Characters"
						entries={allCharacters}
						onEntrySelect={handleSwapConfirm}
						factionSlug={faction.slug}
					/>
				</FrameDialogContent>
			</FrameDialog>
		</>
	);
}


export default ListPanelContent;