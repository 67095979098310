export function getText(gameName, factionName, list) {
	const { points, cost, name, children, gameSlug } = list;
	let text = "";

	text += "=== " + gameName + " ===\n\n";

	text += `${name} [${cost}/${points}]\n`;
	text += `${factionName}`;

	let subtitle = null;
	const armyRules = list.options?.groups?.find(g => g.slug === "army-rules");
	if (armyRules) {
		subtitle = armyRules.orphans.find(o => o.selected)?.name;
	}
	if (!subtitle) {
		const armyTags = list.tagGroups?.find(g => g.slug === "army-rules");
		if (armyTags) {
			subtitle = armyTags.tags[0];
		}
	}
	if (subtitle) {
		text += `\n${subtitle}`;
	}

	if (gameSlug === "tlaok") {
		children.forEach(w => {
			const character = w.children.find(c => c.type === "character");
			const regiments = w.children.filter(c => c.type !== "character")
			const { listEntry } = character;
			const { name, cost, options = {}, isWarlord } = listEntry;

			text += `\n\n\n== ${isWarlord ? "(Warlord) " : ""}${name} [${cost}]: `;

			const selectionNames = [];
			getSelectionNames(options, selectionNames);
			text += selectionNames.join(", ");

			regiments.forEach(r => {
				const { listEntry } = r;
				const { name, cost, size, options = {} } = listEntry;
				text += `\n\n * ${name} (${size}) [${cost}]: `;

				const selectionNames = [];
				getSelectionNames(options, selectionNames);
				text += selectionNames.join(", ");
			})
		});
	} else if (gameSlug === "fb") {
		const characters = children.filter(c => c.type === "character");
		const regiments = children.filter(c => c.type !== "character")

		characters.forEach(c => {
			const { listEntry } = c;
			const { name, cost, options = {} } = listEntry;
			text += `\n\n * ${name} [${cost}]: `;

			const selectionNames = [];
			getSelectionNames(options, selectionNames);
			text += selectionNames.join(", ");
		})

		regiments.forEach(r => {
			const { listEntry } = r;
			const { name, cost, size, options = {} } = listEntry;
			text += `\n\n * ${name} (${size}) [${cost}]: `;

			const selectionNames = [];
			getSelectionNames(options, selectionNames);
			text += selectionNames.join(", ");
		})

	}

	return text;
}


function getSelectionNames(options, names) {
	const { groups = [], orphans = [] } = options;

	orphans.forEach(o => {
		if (o.selected) names.push(o.name);
	})

	groups.forEach(g => getSelectionNames(g, names));
}
