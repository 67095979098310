import { Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { blueGrey } from '@mui/material/colors';


function LabelledGroup({ label, action, children }) {
	return (
		<Paper variant="outlined">
			<Stack
				direction="row"
				justifyContent="space-between"
				bgcolor={blueGrey[200]}
				py={0.5}
				px={1}
				sx={{
					borderTopLeftRadius: (theme) => theme.shape.borderRadius,
					borderTopRightRadius: (theme) => theme.shape.borderRadius,
				}}
			>
				<Typography variant="h6">{label}</Typography>
				{action}
			</Stack>
			<Box p={1}>
				{children}
			</Box>
		</Paper>
	);
}


export default LabelledGroup;