import { configureStore } from "@reduxjs/toolkit";
import listReducer from "./list-slice";
import gamesReducer from "./games-slice";
import queriesReducer from "./queries-slice";
import utilsReducer from "./utils-slice";


const store = configureStore({
	reducer: {
		games: gamesReducer,
		list: listReducer,
		queries: queriesReducer,
		utils: utilsReducer,
	}
});


export default store;