import { useSelector } from "react-redux";
import GAMES_SETTINGS from "../data/games-settings";


function useGameSettings() {
	const currentGameSlug = useSelector(state => state.games.currentGameSlug);

	function getComponent(type, slug) {
		return GAMES_SETTINGS[currentGameSlug].components[type][slug] ||
			GAMES_SETTINGS[currentGameSlug].components[type]["default"];
	}

	function getFactionSettings(factionSlug) {
		return GAMES_SETTINGS[currentGameSlug].factions[factionSlug];
	}

	function getGameSettings(gameSlug = currentGameSlug) {
		return GAMES_SETTINGS[gameSlug];
	}

	function getEntrySettings(entrySlug) {
		return GAMES_SETTINGS[currentGameSlug].entries[entrySlug];
	}

	function getSupportedGameSlugs() {
		return Object.keys(GAMES_SETTINGS);
	}

	function getSupportedFactionSlugs(gameSlug) {
		return Object.keys(GAMES_SETTINGS[gameSlug].factions);
	}

	return {
		getComponent,
		getFactionSettings,
		getGameSettings,
		getEntrySettings,
		getSupportedFactionSlugs,
		getSupportedGameSlugs,
	}
}


export default useGameSettings;