import FrameDialog from "../../ui/containers/FrameDialog";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { clearQueries, popQuery } from "../../store/queries-slice";
import LoadingResponse from "./LoadingResponse";
import ErrorResponse from "./ErrorResponse";
import DirectiveResponse from "./DirectiveResponse";
import OptionsResponse from "./OptionsResponse";
import EntryResponse from "./EntryResponse";

const mapping = {
	directives: DirectiveResponse,
	"faction-options": OptionsResponse,
	entries: EntryResponse,
};

function QueryDialog() {
	const dispatch = useDispatch();
	const queries = useSelector((state) => state.queries);
	const countQueries = queries.length;
	const lastQuery = queries[countQueries - 1];

	if (!lastQuery) return;
	const { request, response } = lastQuery;

	function handleClose() {
		dispatch(clearQueries());
	}

	function handleBack() {
		dispatch(popQuery());
	}

	const actions = [];
	if (countQueries > 1) {
		actions.push({
			icon: ArrowBackIcon,
			handler: handleBack,
			id: "Back",
		});
	}

	actions.push({
		icon: CloseIcon,
		handler: handleClose,
		id: "Close",
	});

	let content = null;
	if (response === "error") {
		content = <ErrorResponse queryLabel={lastQuery.label} />;
	} else if (response) {
		const Response = mapping[request.type];
		content = (
			<Response request={request} response={response} />
		);
	} else {
		content = (
			<LoadingResponse queryLabel={lastQuery.label} />
		);
	}

	return (
		<FrameDialog open onClose={handleClose} responsive actions={actions}>
			{content}
		</FrameDialog>
	);
}

export default QueryDialog;
