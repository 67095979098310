import {
	Button,
	Typography,
	Link as MuiLink,
	SvgIcon,
	Avatar,
	Stack
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { Link } from "react-router-dom";
import RippedBox from "../../ui/containers/RippedBox";
import { shadow } from "../../themes/mixins";
import useGameSettings from "../../hooks/use-game-settings";


const FactionCardAvatar = styled(Avatar)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	width: "70px",
	height: "70px",
	margin: "-50px 30px 0 auto",
	...shadow,
	"& .MuiSvgIcon-root": {
		fontSize: "48px",
		color: theme.palette.primary.contrastIcon,
	},
}));


function FactionCard({ faction }) {
	const { name, slug } = faction;
	const { getFactionSettings } = useGameSettings();
	const { cardBgr, icon, description, websiteUrl } = getFactionSettings(slug);

	return (
		<RippedBox
			rippedSide="all"
			height="100%"
			display="flex"
			flexDirection="column"
			bgcolor="secondary.main"
			color="secondary.contrastText"
			component="article"
		>
			<RippedBox
				rippedSide="bottom"
				height="120px"
				sx={{
					background: `url(${cardBgr}) center/cover`
				}}
			/>
			<FactionCardAvatar variant="rounded">
				<SvgIcon component={icon} />
			</FactionCardAvatar>
			<Box pt={0} pb={1} px={2} flexGrow={1}>
				<Typography variant="h4" component="h1" mb={1}>{name}</Typography>
				<Typography variant="body1">{description}</Typography>
			</Box>
			<Stack direction="row" justifyContent="end" spacing={1} p={1}>
				<Button
					component={MuiLink}
					href={websiteUrl}
					target="_blank"
					size="small"
				>
					Read More
				</Button>
				<Button
					component={Link}
					to={slug}
					size="small"
					variant="contained"
				>View Faction
				</Button>
			</Stack>
		</RippedBox >
	);
}


export default FactionCard;