import ReactDOM from 'react-dom/client';
import App from './App';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import store from './store/redux-store';
import genericBgr from "./assets/bgr.jpg";


const rootElement = document.getElementById('root');
rootElement.style.cssText += `
	width: 100vw;
	height: 100vh;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
	url(${genericBgr}) center / cover;
`;

const root = ReactDOM.createRoot(rootElement);
root.render(
	<Provider store={store}>
		<SnackbarProvider maxSnack={3}>
			<App />
		</SnackbarProvider>
	</Provider>
);