export const QRCODES = {
	"dc5ed717d0": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "theist-priest"
	},
	"39baedd744": {
		"factionSlug": "dweghom",
		"slugs": ["initiates", "initiates-shields", "initiates-pikes"]
	},
	"92a06eb4aa": {
		"factionSlug": "nords",
		"slug": "raiders"
	},
	"f24fe46a4a": {
		"factionSlug": "nords",
		"slug": "stalkers"
	},
	"9b1673cb73": {
		"factionSlug": "dweghom",
		"slug": "inferno-automata"
	},
	"911e8005b8": {
		"factionSlug": "dweghom",
		"slug": "wardens"
	},
	"57111ae347": {
		"factionSlug": "dweghom",
		"slug": "hold-ballistae"
	},
	"47915cb401": {
		"factionSlug": "the-spires",
		"slug": "abomination"
	},
	"17cf648545": {
		"factionSlug": "nords",
		"slug": "konungyr"
	},
	"4bf9bab0ed": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "imperial-officer"
	},
	"72fbe374b9": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "noble-lord"
	},
	"a573634ea1": {
		"factionSlug": "dweghom",
		"slug": "hellbringer-drake"
	},
	"a8c12ce0eb": {
		"factionSlug": "nords",
		"slugs": ["blooded-nords", "blooded-crow", "blooded-wolf"]
	},
	"6def0a1672": {
		"factionSlug": "nords",
		"slug": "jarl"
	},
	"60d9532d7d": {
		"factionSlug": "dweghom",
		"slug": "ardent-kerawegh"
	},
	"2af8bead77": {
		"factionSlug": "dweghom",
		"slug": "tempered-sorcerer"
	},
	"4c93364b5d": {
		"factionSlug": "dweghom",
		"slug": "tempered-steelshaper"
	},
	"ae45a734df": {
		"factionSlug": "dweghom",
		"slug": "hold-raegh"
	},
	"243b048ced": {
		"factionSlug": "dweghom",
		"slug": "flame-berserkers"
	},
	"1879493a36": {
		"factionSlug": "nords",
		"slug": "trolls"
	},
	"691125c9dd": {
		"factionSlug": "dweghom",
		"slug": "hold-warriors"
	},
	"650be45c63": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "chapter-mage"
	},
	"67bcb32e3f": {
		"factionSlug": "nords",
		"slug": "bow-chosen"
	},
	"313f540293": {
		"factionSlug": "the-spires",
		"slug": "stryx"
	},
	"2e3b98bf37": {
		"factionSlug": "the-spires",
		"slug": "mimetic-assassin"
	},
	"2321597cfb": {
		"factionSlug": "nords",
		"slug": "shaman"
	},
	"09dd0a88f5": {
		"factionSlug": "nords",
		"slug": "huskarls"
	},
	"0059f88fa3": {
		"factionSlug": "nords",
		"slug": "ugr"
	},
	"c2fdf46547": {
		"factionSlug": "nords",
		"slug": "fenr-beastpack"
	},
	"0aabacff1a": {
		"factionSlug": "the-spires",
		"slug": "lineage-highborne"
	},
	"01ea1c803a": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "mounted-noble-lord"
	},
	"e2d7089d2e": {
		"factionSlug": "the-spires",
		"slug": "high-clone-executor"
	},
	"1f90cef7bf": {
		"factionSlug": "the-spires",
		"slug": "centaur-avatara"
	},
	"233f12092d": {
		"factionSlug": "the-spires",
		"slug": "vanguard-clones"
	},
	"f85fefc23a": {
		"factionSlug": "the-spires",
		"slug": "avatara"
	},
	"7680de83ba": {
		"factionSlug": "dweghom",
		"slug": "hold-thanes"
	},
	"4d2823b344": {
		"factionSlug": "the-wadrhun",
		"slug": "raptor-riders"
	},
	"5e61ae3615": {
		"factionSlug": "the-wadrhun",
		"slug": "apex-predator"
	},
	"bd70da3519": {
		"factionSlug": "dweghom",
		"slug": "fireforged"
	},
	"d4424ac5e7": {
		"factionSlug": "the-wadrhun",
		"slug": "hunting-pack"
	},
	"15eeb5ea41": {
		"factionSlug": "nords",
		"slug": "ice-jotnar"
	},
	"c057faceb6": {
		"factionSlug": "nords",
		"slug": "mountain-jotnar"
	},
	"58f9dd7047": {
		"factionSlug": "dweghom",
		"slug": "dragonslayers"
	},
	"eb842dcd08": {
		"factionSlug": "the-wadrhun",
		"slug": "matriarch-queen"
	},
	"e0c6136690": {
		"factionSlug": "the-wadrhun",
		"slug": "scion-of-conquest"
	},
	"c8bb3f6270": {
		"factionSlug": "the-wadrhun",
		"slug": "braves"
	},
	"b7d279592f": {
		"factionSlug": "the-wadrhun",
		"slug": "hunters"
	},
	"534dc46ba5": {
		"factionSlug": "the-wadrhun",
		"slug": "predator"
	},
	"8879acc756": {
		"factionSlug": "the-wadrhun",
		"slug": "warbred"
	},
	"c1bf3cf0aa": {
		"factionSlug": "the-wadrhun",
		"slug": "blooded-wadrhun"
	},
	"78c0ca0f56": {
		"factionSlug": "the-wadrhun",
		"slug": "slingers"
	},
	"5a2394ee41": {
		"factionSlug": "the-wadrhun",
		"slug": "chieftain"
	},
	"fa365ef72e": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "militia"
	},
	"f9adbd9268": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "militia-bowmen"
	},
	"30f64815e3": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "mercenary-crossbowmen"
	},
	"0d2190743c": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "longbowmen"
	},
	"e9b4a0ef3e": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "men-at-arms"
	},
	"10fe37264a": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "household-guard"
	},
	"b66b5fd650": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "steel-legion"
	},
	"3285e890fa": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "gilded-legion"
	},
	"c73f18ed88": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "hunter-cadre"
	},
	"a7cba14fa8": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "household-knights"
	},
	"d048bbfe6a": {
		"factionSlug": "the-spires",
		"slug": "biomancer"
	},
	"a5920616de": {
		"factionSlug": "the-old-dominion",
		"slug": "fallen-divinity"
	},
	"123bc93a25": {
		"factionSlug": "the-spires",
		"slug": "incarnate-sentinels"
	},
	"5a57d76016": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "mounted-squires"
	},
	"881c1734cf": {
		"factionSlug": "the-spires",
		"slug": "force-grown-drones"
	},
	"6da96415a5": {
		"factionSlug": "the-spires",
		"slug": "brute-drones"
	},
	"02be3b9d37": {
		"factionSlug": "the-spires",
		"slug": "pheromancer"
	},
	"5db312b957": {
		"factionSlug": "the-spires",
		"slug": "vanguard-clone-infiltrators"
	},
	"75801d8762": {
		"factionSlug": "the-spires",
		"slug": "marksman-clones"
	},
	"ee56ea017e": {
		"factionSlug": "the-old-dominion",
		"slug": "kheres"
	},
	"63e2d4ee28": {
		"factionSlug": "the-old-dominion",
		"slug": "moroi"
	},
	"eaf090912f": {
		"factionSlug": "the-old-dominion",
		"slug": "legionnaires"
	},
	"f871a5b094": {
		"factionSlug": "the-old-dominion",
		"slug": "praetorian-guard"
	},
	"f39ff87e94": {
		"factionSlug": "the-old-dominion",
		"slug": "archimandrite"
	},
	"d8d010f60c": {
		"factionSlug": "the-old-dominion",
		"slug": "bone-golems"
	},
	"74bec720db": {
		"factionSlug": "the-old-dominion",
		"slug": "strategos"
	},
	"bf48d8dcab": {
		"factionSlug": "the-old-dominion",
		"slug": "xhiliarch"
	},
	"2816702bb5": {
		"factionSlug": "the-old-dominion",
		"slug": "kataphraktoi"
	},
	"049f03dc0c": {
		"factionSlug": "the-old-dominion",
		"slug": "hierodeacon"
	},
	"ef57daf5e2": {
		"factionSlug": "the-wadrhun",
		"slugs": ["apex-queen", "matriarch-queen"]
	},
	"09b601e3ed": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "order-of-the-crimson-tower"
	},
	"a8b9c4d9a8": {
		"factionSlug": "the-spires",
		"slug": "bound-clones"
	},
	"79c95f9182": {
		"factionSlug": "the-spires",
		"slug": "onslaught-drones"
	},
	"5a95fc1fe2": {
		"factionSlug": "nords",
		"slug": "volva"
	},
	"cbb631c6c5": {
		"factionSlug": "nords",
		"slug": "valkyries"
	},
	"b386ac091a": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "imperial-ranger-corps"
	},
	"612a0c6593": {
		"factionSlug": "the-hundred-kingdoms",
		"slugs": ["priory-commander-crimson-tower", "priory-commander"]
	},
	"0c2aeb1173": {
		"factionSlug": "the-old-dominion",
		"slug": "kanephors"
	},
	"82d426a7ad": {
		"factionSlug": "the-old-dominion",
		"slug": "karyatids"
	},
	"60fc467ea5": {
		"factionSlug": "nords",
		"slug": "sea-jotnar"
	},
	"65016c2c1f": {
		"factionSlug": "the-old-dominion",
		"slug": "mounted-strategos"
	},
	"6a1c54b8a0": {
		"factionSlug": "the-city-states",
		"slug": "agema"
	},
	"f6c27177ca": {
		"factionSlug": "the-city-states",
		"slug": "thorakites"
	},
	"f7d8335d88": {
		"factionSlug": "the-city-states",
		"slug": "hoplites"
	},
	"f5a369cc8e": {
		"factionSlug": "the-city-states",
		"slug": "phalangites"
	},
	"9dc250a5a7": {
		"factionSlug": "the-city-states",
		"slug": "polemarch"
	},
	"d87e91816d": {
		"factionSlug": "the-city-states",
		"slug": "minotaur-haspists"
	},
	"e958309cf6": {
		"factionSlug": "the-city-states",
		"slug": "minotaur-thyreans"
	},
	"a2ee97591a": {
		"factionSlug": "the-city-states",
		"slug": "aristarch"
	},
	"828d248625": {
		"factionSlug": "the-old-dominion",
		"slug": "athanatoi"
	},
	"ae703dc457": {
		"factionSlug": "the-old-dominion",
		"slug": "varangian-guard"
	},
	"058b644700": {
		"factionSlug": "the-old-dominion",
		"slug": "buccephaloi"
	},
	"4f54530acf": {
		"factionSlug": "the-wadrhun",
		"slug": "veterans"
	},
	"942f7d7cf3": {
		"factionSlug": "the-wadrhun",
		"slug": "chosen-of-conquest"
	},
	"eea895cc44": {
		"factionSlug": "the-city-states",
		"slug": "hephaestian"
	},
	"0f99967fc6": {
		"factionSlug": "the-city-states",
		"slug": "promethean"
	},
	"8eee3cd931": {
		"factionSlug": "the-city-states",
		"slug": "mechanist"
	},
	"c3cea20219": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "order-of-the-ashen-dawn"
	},
	"40ae2072f3": {
		"factionSlug": "dweghom",
		"slug": "steelforged"
	},
	"65d0b51f94": {
		"factionSlug": "nords",
		"slug": "ulfhednar"
	},
	"647981c7cb": {
		"factionSlug": "nords",
		"slug": "bearsarks"
	},
	"aee4ac2db1": {
		"factionSlug": "the-wadrhun",
		"slug": "thunder-riders"
	},
	"1ac4d7158e": {
		"factionSlug": "the-wadrhun",
		"slug": "thunder-chieftain"
	},
	"31e1e61fcc": {
		"factionSlug": "the-wadrhun",
		"slug": "tontorr"
	},
	"24372bf219": {
		"factionSlug": "the-wadrhun",
		"slug": "drum-beast"
	},
	"bd319bc175": {
		"factionSlug": "the-city-states",
		"slug": "selinoi-hunters"
	},
	"c41383c805": {
		"factionSlug": "the-city-states",
		"slug": "selinoi-rangers"
	},
	"4f7d07e1fb": {
		"factionSlug": "the-city-states",
		"slug": "eidolon"
	},
	"8175ba3662": {
		"factionSlug": "the-city-states",
		"slug": "inquisitors"
	},
	"fcb0be39a4": {
		"factionSlug": "the-spires",
		"slug": "siegebreaker-behemoth"
	},
	"e53c4cd2b3": {
		"factionSlug": "dweghom",
		"slug": "ironclad-drake"
	},
	"10475ac60c": {
		"factionSlug": "nords",
		"slug": "vargyr-lord"
	},
	"e101fc7a20": {
		"factionSlug": "nords",
		"slug": "werewolves"
	},
	"274212da13": {
		"factionSlug": "sorcerer-kings",
		"slug": "efreet-sword-dancers"
	},
	"3861fe28be": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "order-of-the-sealed-temple"
	},
	"b1049d9686": {
		"factionSlug": "the-city-states",
		"slug": "companion-cavalry"
	},
	"13f206f6b6": {
		"factionSlug": "the-city-states",
		"slug": "ipparchos"
	},
	"bbdd96c7f9": {
		"factionSlug": "sorcerer-kings",
		"slug": "sorcerer"
	},
	"bc68942681": {
		"factionSlug": "sorcerer-kings",
		"slug": "efreet-flamecasters"
	},
	"48827d32cf": {
		"factionSlug": "sorcerer-kings",
		"slug": "ghols"
	},
	"a7086c3de2": {
		"factionSlug": "sorcerer-kings",
		"slug": "rajakur"
	},
	"c2f98797f6": {
		"factionSlug": "sorcerer-kings",
		"slug": "maharajah"
	},
	"0ece163ff2": {
		"factionSlug": "sorcerer-kings",
		"slug": "raj"
	},
	"95df978258": {
		"factionSlug": "sorcerer-kings",
		"slug": "sardar"
	},
	"c6e6f92489": {
		"factionSlug": "sorcerer-kings",
		"slug": "rakshasa-bakasura"
	},
	"0b97f66e20": {
		"factionSlug": "sorcerer-kings",
		"slug": "rakshasa-ravanar"
	},
	"30d67ba0b8": {
		"factionSlug": "sorcerer-kings",
		"slug": "steelheart-djinn"
	},
	"19bdd98d76": {
		"factionSlug": "sorcerer-kings",
		"slug": "windborne-djinn"
	},
	"d38304657d": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "imperial-officer"
	},
	"7f44c30b90": {
		"factionSlug": "dweghom",
		"slug": "hold-raegh"
	},
	"8a4fcf4563": {
		"factionSlug": "the-city-states",
		"slug": ["flogobollon", "war-chariots"]
	},
	"ef63692a68": {
		"factionSlug": "the-city-states",
		"slugs": ["skorpios", "war-chariots"]
	},
	"e95016031e": {
		"factionSlug": "the-spires",
		"slugs": "desolation-drones"
	},
	"f6aad7d962": {
		"factionSlug": "the-spires",
		"slug": "leonine-avatara"
	},
	"75713a350b": {
		"factionSlug": "the-spires",
		"slug": "lineage-prideborne"
	},
	"8827100909": {
		"factionSlug": "the-old-dominion",
		"slug": "hashashin"
	},
	"d8d8701ac6": {
		"factionSlug": "the-old-dominion",
		"slug": "cultists"
	},
	"b225703e6f": {
		"factionSlug": "the-city-states",
		"slug": "talos"
	},
	"de98a54c21": {
		"factionSlug": "sorcerer-kings",
		"slugs": ["dhanurveda-archers", "dhanur-disciples"]
	},
	"25814fd3b9": {
		"factionSlug": "sorcerer-kings",
		"slug": "mahut"
	},
	"1a2fc603e0": {
		"factionSlug": "the-old-dominion",
		"slug": "centaur-prodromoi"
	},
	"aa01adabd9": {
		"factionSlug": "the-old-dominion",
		"slug": "centaur-kerykes"
	},
	"a46878379c": {
		"factionSlug": "dweghom",
		"slug": "magmaforged"
	},
	"6f27abbdbf": {
		"factionSlug": "dweghom",
		"slug": "stoneforged"
	},
	"615449de41": {
		"factionSlug": "the-city-states",
		"slug": "clockwork-hoplites"
	},
	"862733f0f3": {
		"factionSlug": "the-city-states",
		"slug": "sacred-band"
	},
	"b762837f59": {
		"factionSlug": "nords",
		"slug": "jotnar-seidr"
	},
	"0381f665cf": {
		"factionSlug": "the-city-states",
		"slug": "promethean-oracle"
	},
	"20146d3e24": {
		"factionSlug": "sorcerer-kings",
		"slug": "mahabharati-initiates"
	},
	"08af9d1a69": {
		"factionSlug": "the-wadrhun",
		"slug": "mounted-predator"
	},
	"49f5d5c26d": {
		"factionSlug": "the-wadrhun",
		"slug": "chosen-of-death"
	},
	"0f0a2d1afb": {
		"factionSlug": "the-wadrhun",
		"slug": "quatl"
	},
	"8fa1dc190b": {
		"factionSlug": "the-wadrhun",
		"slug": "winglord-predator"
	},
	"4a607e90d3": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "crusaders"
	},
	"17c3d2883d": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "sicarii"
	},
	"c67dfb3835": {
		"factionSlug": "the-hundred-kingdoms",
		"slug": "archangel"
	},
	"d7b088fbeb": {
		"factionSlug": "nords",
		"slug": "steel-chosen"
	},
	"3dfbaf7ebb": {
		"factionSlug": "nords",
		"slug": "goltr-beastpack"
	},
}