import { Navigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import axios from "axios";
import UserCtx from "../store/UserCtx";


function OAuth() {
	const { currentUser, onCurrentUserChange } = useContext(UserCtx);

	useEffect(() => {
		async function getUsername() {
			try {
				// const response = await axios.get("https://faas-fra1-afec6ce7.doserverless.co/api/v1/web/fn-76df8e48-87f7-409b-ae54-ddac3ca62c2a/default/test")
				const response = await axios.get("https://living.para-bellum.com/api/v1/users/whoami", { withCredentials: true })
				// const response = await axios.get("http://localhost:3000/api/v1/users/whoami", { withCredentials: true })
				onCurrentUserChange(response.data.data.attributes);
			} catch (error) {
				console.error(error);
			}
		}
		if (!currentUser) getUsername();
	}, []);

	return (<Navigate to="/" replace={true} />);
}


export default OAuth;