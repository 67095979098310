import { styled, Box } from "@mui/system";


const ReferenceBox = styled(Box)(({ theme }) => ({
	display: "inline-flex",
	gap: theme.spacing(1),
	borderRadius: theme.shape.borderRadius,
	cursor: "default",
	// backgroundColor: "transparent",
	position: "relative",
	"&:after": {
		content: "''",
		backgroundColor: "currentColor",
		width: "100%",
		height: "100%",
		top: 0,
		left: 0,
		opacity: 0.1,
		position: "absolute",
		borderRadius: theme.shape.borderRadius,
		pointerEvents: "none",
	},
	"& .var": {
		padding: theme.spacing(0, 1),
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		"&:after": {
			content: "''",
			backgroundColor: "currentColor",
			width: "100%",
			height: "100%",
			top: 0,
			left: 0,
			opacity: 0.15,
			position: "absolute",
			borderRadius: theme.shape.borderRadius,
			pointerEvents: "none",
		},
	},
}));


export default ReferenceBox;