import { Dialog, useMediaQuery } from '@mui/material';
import FloatingActions from '../FloatingActions';
import FrameBox from './FrameBox';


function FrameDialog(props) {
	const {
		open,
		onClose,
		responsive,
		frameStyle = "frame2",
		color = "secondary",
		actions = [],
		children,
	} = props;

	const smDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const isFullScreen = responsive && smDown;
	const hasActions = actions.length > 0;

	return (
		<Dialog
			PaperComponent={FrameBox}
			PaperProps={{
				responsive,
				frameStyle,
				bgcolor: `${color}.main`,
				color: `${color}.contrastText`,
				sx: { overflow: "visible" },
			}}
			fullScreen={isFullScreen}
			fullWidth={!isFullScreen}
			open={open}
			onClose={onClose}
		>
			{children}
			{hasActions && (
				<FloatingActions xPos="right" yPos="bottom" actions={actions} />
			)}
		</Dialog>
	);
}


export default FrameDialog;