import React, { useCallback, useState } from "react";


const ParsedListCtx = React.createContext({
	parsedList: null,
	onParsedListChange: () => { },
});


export function ParsedListCtxProvider(props) {
	const [parsedList, setParsedList] = useState(null);

	const handleParsedListChange = useCallback((list) => {
		setParsedList(list);
	}, []);

	return (
		<ParsedListCtx.Provider
			value={{
				parsedList,
				onParsedListChange: handleParsedListChange,
			}}
		>
			{props.children}
		</ParsedListCtx.Provider>
	);
}


export default ParsedListCtx;