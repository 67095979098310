import { Typography } from "@mui/material";
import ReferenceBox from "../../ui/ReferenceBox";
import QueryIcon from "./QueryIcon";

function QueryRef({ query }) {
	return (
		<ReferenceBox px={1}>
			<Typography component="span" noWrap>
				{query.label}
			</Typography>
			<QueryIcon query={query} />
		</ReferenceBox>
	);
}

export default QueryRef;
