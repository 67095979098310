import { Stack, Typography } from "@mui/material";
import NoiseControlOffIcon from '@mui/icons-material/NoiseControlOff';
import Tag from "./Tag";


function TagGroup({ group }) {
	const { name, description, tags = [] } = group;

	return (
		<section>
			<Stack direction="row" alignItems="center" spacing={0.5}>
				<NoiseControlOffIcon fontSize="small" />
				<Typography variant="h5">{name}</Typography>
			</Stack>
			{description && <Typography>{description}</Typography>}
			<Stack
				direction="row"
				flexWrap="wrap"
				gap={0.5}
				alignItems="center"
			>
				{tags.map(t => (
					<Tag key={t.slug} tag={t} />
				))}
			</Stack>
		</section>
	);
}


export default TagGroup;