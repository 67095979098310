import ReferenceBox from "../../ui/ReferenceBox";
import { Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import QueryIcon from "../query/QueryIcon";

function FlatTag({ tag, ...boxProps }) {
	const { name, variable, range, query } = tag;
	const nameOnly = !variable && !range && !query;

	return (
		<ReferenceBox {...boxProps}>
			<Typography component="span" noWrap ml={1} mr={nameOnly ? 1 : 0}>
				{name}
			</Typography>
			{variable && <Box className="var">{variable}</Box>}
			{range && (
				<>
					<Typography component="span" noWrap>
						Range
					</Typography>
					<Box className="var">{range}"</Box>
				</>
			)}
			{query && <QueryIcon query={query} sx={{ mr: 1 }} />}
		</ReferenceBox>
	);
}

function Tag({ tag }) {
	const { tags } = tag;
	const hasTags = !!tags?.length;

	return hasTags ? (
		<Box
			component={Paper}
			variant="outlined"
			order="1"
			flexBasis="100%"
			p={1}
			pt={0}
			mt={2}
		>
			<FlatTag
				tag={tag}
				top="0"
				sx={{ transform: "translateY(-50%)" }}
				bgcolor="secondary.main"
			/>
			<Stack direction="row" gap={0.5} flexWrap="wrap">
				{tags.map((t) => (
					<FlatTag key={t.slug} tag={t} />
				))}
			</Stack>
		</Box >
	) : (
		<FlatTag tag={tag} />
	);
}

export default Tag;
