import { Stack } from "@mui/material";
import { styled } from '@mui/system';
import android from "../assets/android-download.png";
import ios from "../assets/app-store-download.png.webp";


const StoreImg = styled("img")(({ theme }) => ({
	[theme.breakpoints.up("sm")]: {
		height: "55px",
	},
	[theme.breakpoints.only("xs")]: {
		width: "160px",
	},
}));


function AppPromo() {
	return (
		<Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
			<a
				target="_blank"
				rel="noopener"
				href="https://apps.apple.com/us/app/conquest-companion-app/id6446408799?itsct=apps_box_link&itscg=30200"
			>
				<StoreImg src={ios} />
			</a>
			<a
				target="_blank"
				rel="noopener"
				href="https://play.google.com/store/apps/details?id=com.parabellum.armybuilder"
			>
				<StoreImg src={android} />
			</a>
		</Stack>
	);
}


export default AppPromo;