import {
	Badge,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	ToggleButton,
	ToggleButtonGroup,
	Typography
} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import { useState } from "react";


function FactionFiltersIcon(props) {
	const { filters, onFiltersApply, onFiltersReset, variant } = props;
	const [open, setOpen] = useState(false);
	const [types, setTypes] = useState([]);
	const [gameClasses, setGameClasses] = useState([]);
	const hasActiveFilters = filters.types.length > 0 ||
		filters.gameClasses.length > 0;

	function handleTypesChange(event, types) {
		setTypes(types);
	}

	function handleClassesChange(event, gameClasses) {
		setGameClasses(gameClasses);
	}

	function handleOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	function handleResetClick() {
		setTypes([]);
		setGameClasses([]);
		onFiltersReset();
		handleClose();
	}

	function handleApplyClick() {
		onFiltersApply({
			types,
			gameClasses,
		});
		handleClose();
	}

	return (
		<>
			<IconButton onClick={handleOpen} variant={variant}>
				<Badge color="success" variant="dot" invisible={!hasActiveFilters}>
					<FilterListIcon />
				</Badge>
			</IconButton>
			<Dialog open={open} onClose={handleClose} fullWidth>
				<DialogTitle>Filters</DialogTitle>
				<DialogContent>
					<Typography variant="h6">Type:</Typography>
					<ToggleButtonGroup value={types} onChange={handleTypesChange} fullWidth>
						<ToggleButton value="Infantry">Infantry</ToggleButton>
						<ToggleButton value="Cavalry">Cavalry</ToggleButton>
						<ToggleButton value="Brute">Brute</ToggleButton>
						<ToggleButton value="Monster">Monster</ToggleButton>
					</ToggleButtonGroup>
					<Typography variant="h6" mt={2}>Class:</Typography>
					<ToggleButtonGroup value={gameClasses} onChange={handleClassesChange} fullWidth>
						<ToggleButton value="Light">Light</ToggleButton>
						<ToggleButton value="Medium">Medium</ToggleButton>
						<ToggleButton value="Heavy">Heavy</ToggleButton>
					</ToggleButtonGroup>
				</DialogContent>
				<DialogActions>
					<Button size="small" onClick={handleClose}>Cancel</Button>
					<Button size="small" onClick={handleResetClick}>Reset</Button>
					<Button variant="contained" size="small" onClick={handleApplyClick}>Apply</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}


export default FactionFiltersIcon;