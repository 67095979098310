import { List, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LabelledGroup from "../../../ui/containers/LabelledGroup";
import QueryIcon from "../../query/QueryIcon";

function OptionsList({ options }) {
	const activeOptions = options.filter(o => o.active !== false);
	if (!activeOptions.length) return;

	return (
		<Box component={List} disablePadding px={1}>
			{activeOptions.map((o) => (
				<ListItem key={o.slug} dense disableGutters>
					<Typography variant="body1" mr={1}>
						{o.name}
					</Typography>
					{o.query && <QueryIcon query={o.query} />}
					<Typography variant="body1" flex="1 1 auto" align="right">
						{o.cost || "Free"}
					</Typography>
				</ListItem>
			))}
		</Box>
	);
}

function DetailedOptions({ group }) {
	const { name, description, orphans = [], groups = [], query } = group;

	const groupedOptions = groups.map((g) => (
		<section key={g.slug}>
			<Typography variant="h6">{g.name}</Typography>
			<OptionsList options={g.orphans} />
		</section>
	));

	return (
		<LabelledGroup label={name} action={query && <QueryIcon query={query} />}>
			{description && (
				<Typography variant="body1" mb={1}>
					{description}
				</Typography>
			)}
			<OptionsList options={orphans} />
			{groupedOptions}
		</LabelledGroup>
	);
}

export default DetailedOptions;
