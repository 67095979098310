import FrameDialog from "../../../ui/containers/FrameDialog";
import FrameHeader from "../../../ui/containers/FrameHeader";
import { useState } from "react";
import FrameDialogContent from "../../../ui/containers/FrameDialogContent";
import { Fab, Stack, Typography } from "@mui/material";
import EntryList from "../../entry/EntryList";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useDispatch } from "react-redux";
import { attachNode } from "../../../store/list-slice";
import { createNode } from "../../../lib/list";
import FloatingActions from "../../../ui/FloatingActions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


function sortingFn(a, b) {
	return a.name.toLowerCase()
		.localeCompare(b.name.toLowerCase());
}


function NewRegimentButton({ faction, characterNode }) {
	const { listEntry } = characterNode;
	const { mainstay = [], restricted = [] } = listEntry.warband;
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();

	function handleOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	function handleEntrySelect(type, entry) {
		const regimentNode = createNode({ type, entrySlug: entry.slug });
		dispatch(attachNode({
			path: characterNode.parent.path,
			node: regimentNode
		}));
		handleClose();
	}

	const mainstayEntries =
		mainstay.sort(sortingFn).map(e => faction.entries[e.slug]);

	const restrictedEntries =
		restricted.sort(sortingFn).map(e => faction.entries[e.slug]);

	const actions = [
		{
			id: "close",
			icon: ArrowBackIcon,
			handler: handleClose,
		}
	];

	return (
		<>
			<Fab
				color="primary"
				variant="extended"
				sx={{ alignSelf: "end" }}
				size="medium"
				onClick={handleOpen}
			>
				<AddCircleIcon sx={{ mr: 1 }} />
				<Typography variant="h5">Regiment</Typography>
			</Fab>
			<FrameDialog
				open={open}
				onClose={handleClose}
				responsive
				color="darkBgr"
				actions={actions}
			>
				<FrameHeader title="Add Regiment" />
				<FrameDialogContent>
					<Stack spacing={3}>
						<EntryList
							title="Mainstay"
							entries={mainstayEntries}
							onEntrySelect={handleEntrySelect.bind(null, "mainstay")}
							factionSlug={faction.slug}
						/>
						<EntryList
							title="Restricted"
							entries={restrictedEntries}
							onEntrySelect={handleEntrySelect.bind(null, "restricted")}
							factionSlug={faction.slug}
						/>
					</Stack>
				</FrameDialogContent>
			</FrameDialog>
		</>
	);
}


export default NewRegimentButton;