import { Fab, Stack, useTheme } from "@mui/material";


function FloatingActions({ xPos = "center", yPos = "center", actions }) {
	const theme = useTheme();
	const spacing = theme.spacing(2);

	const xOffset = xPos === "center" ? "-50%" : "0";
	const yOffset = yPos === "center" ? "-50%" : "0";
	const transform = `translate(${xOffset}, ${yOffset})`;

	let top = "50%";
	let bottom = "auto";
	let left = "50%";
	let right = "auto";

	if (yPos === "top") {
		top = spacing;
		bottom = "auto";
	} else if (yPos === "bottom") {
		top = "auto";
		bottom = spacing;
	}

	if (xPos === "left") {
		left = spacing;
		right = "auto";
	} else if (xPos === "right") {
		left = "auto";
		right = spacing;
	}

	const actionFabs = actions.map(a => {
		const {
			id,
			label,
			description,
			handler,
			icon: Icon,
			pronounced = false,
			disabled = false,
			color = "primary",
		} = a;

		return (
			<Fab
				key={id}
				aria-label={description}
				onClick={handler}
				color={color}
				size={label || pronounced ? "medium" : "small"}
				variant={label ? "extended" : "circular"}
				disabled={disabled}
			>
				{Icon && <Icon sx={{ mr: label && 1, ml: label && -1 }} />}
				{label}
			</Fab>
		);
	});

	return (
		<Stack
			flexDirection="row"
			justifyContent="end"
			gap={1}
			position="absolute"
			top={top}
			bottom={bottom}
			left={left}
			right={right}
			sx={{
				transform: transform
			}}
		>
			{actionFabs}
		</Stack >
	);
}


export default FloatingActions;