import {
	IconButton,
	ListItem,
	ListItemButton,
	ListItemText,
	Menu,
	Stack,
	Typography
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch } from "react-redux";
import { updateOptions } from "../../../store/list-slice";
import QueryRef from "../../query/QueryRef";
import { useState } from "react";
import QueryIcon from "../../query/QueryIcon";


function ArmyRules({ optionsGroup, tagGroup }) {
	const { name, orphans = [] } = optionsGroup || {};
	const selected = orphans.find(o => o.selected);
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	function handleOpen(event) {
		setAnchorEl(event.currentTarget);
	}

	const handleClose = () => {
		setAnchorEl(null);
	};

	function handleSelect(slug) {
		dispatch(updateOptions({
			path: "",
			add: slug === "none" ? [] : [slug],
			remove: selected?.slug ? [selected.slug] : [],
		}));
		handleClose();
	}

	if (tagGroup) {
		return <Stack direction="row"><QueryRef query={tagGroup.tags[0].query} /></Stack >
	}

	const options = [
		{ slug: "none", name: "None" },
		...orphans,
	]

	return (
		<Stack direction="row" spacing={1} alignItems="center">
			{!selected && (
				<Typography variant="body1" sx={{ opacity: 0.7 }}>
					{name}
				</Typography>
			)}
			{selected && <QueryRef query={selected.query} />}
			<IconButton variant="light" size="small" onClick={handleOpen}>
				<EditIcon fontSize="small" />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				MenuListProps={{ disablePadding: true }}
			>
				<ListItem divider>
					<ListItemText
						primaryTypographyProps={{ variant: "h4", align: "center" }}
					>
						Army rules
					</ListItemText>
				</ListItem>
				{options.map(o => (
					<ListItem
						key={o.slug}
						disablePadding
						secondaryAction={o.query && <QueryIcon query={o.query} />}
					>
						<ListItemButton onClick={handleSelect.bind(null, o.slug)}>
							{o.name}
						</ListItemButton>
					</ListItem>
				))}
			</Menu>
		</Stack>
	);
}


export default ArmyRules;