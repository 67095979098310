import FloatingActions from "../../ui/FloatingActions";
import FrameDialogContent from "../../ui/containers/FrameDialogContent";
import FrameHeader from "../../ui/containers/FrameHeader";
import Directive from "./Directive";

function DirectiveResponse({ response }) {
	const { name } = response;

	return (
		<>
			<FrameHeader title={name} />
			<FrameDialogContent>
				<Directive data={response} />
			</FrameDialogContent>
		</>
	);
}

export default DirectiveResponse;
