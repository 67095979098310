import CenteredContentBox from "./containers/CenteredContentBox";
import { ReactComponent as Ring } from "../assets/ring.svg";
import { keyframes } from "@emotion/react";
import { SvgIcon } from "@mui/material";


const spin = keyframes`
	from { transform: rotate(0deg) },
	to { transform: rotate(360deg) }
`;

const styles = {
	width: 70,
	height: 70,
	margin: 2,
	filter: "drop-shadow(0px 0px 6px white)",
	animation: `${spin} 6s infinite linear`,
}

function Loading() {
	return (
		<CenteredContentBox p={2}>
			<SvgIcon inheritViewBox component={Ring} sx={styles} />
		</CenteredContentBox>
	);
}


export default Loading;