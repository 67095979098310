import { createSlice } from "@reduxjs/toolkit";


const utilsSlice = createSlice({
	name: "utils",
	initialState: {
		scanOpen: false,
		listSetupOpen: false,
		savedListsOpen: false,
	},
	reducers: {
		openScanner(state) {
			state.scanOpen = true;
		},
		closeScanner(state) {
			state.scanOpen = false;
		},
		openListSetup(state) {
			state.listSetupOpen = true;
		},
		closeListSetup(state) {
			state.listSetupOpen = false;
		},
		openSavedLists(state) {
			state.savedListsOpen = true;
		},
		closeSavedLists(state) {
			state.savedListsOpen = false;
		},
	},
});


export const {
	openScanner,
	closeScanner,
	openListSetup,
	closeListSetup,
	openSavedLists,
	closeSavedLists,
} = utilsSlice.actions;

export default utilsSlice.reducer;