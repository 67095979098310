import { styled } from "@mui/system"
import SimpleBar from 'simplebar-react';


const ContentDiv = styled("div")({
	flex: "1 1 auto",
	overflow: "hidden",
});


const ScrollBar = styled(SimpleBar)(({ theme }) => ({
	padding: theme.spacing(2, 2, 8, 2),
	[theme.breakpoints.only("xs")]: {
		padding: theme.spacing(2, 1, 8, 1),
	}
}));


function FrameDialogContent({children}) {
	return (
		<ContentDiv>
			<ScrollBar>
				{children}
			</ScrollBar>
		</ContentDiv>
	)
}


export default FrameDialogContent;