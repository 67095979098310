import { Modal, Stack } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import HorPanelsCtx from "./HorPanelsCtx";
import appSettings from "../../app-settings";
import CloseIcon from "@mui/icons-material/Close";
import PushPinIcon from '@mui/icons-material/PushPin';
import PanelContainer from "./PanelContainer";


function HorPanelsLayout() {
	const stackRef = useRef();
	const {
		fixed,
		floating,
		maxSlots,
		onMaxSlotsChange,
		onPanelRemove,
		onPanelUpdate
	} = useContext(HorPanelsCtx);

	useEffect(() => {
		const stack = stackRef.current;
		const resizeObserver = new ResizeObserver((entries) => {
			if (entries[0].contentRect) {
				const width = entries[0].contentRect.width;
				let newMaxSlots = Math.floor(width / appSettings.panelMinSize);
				newMaxSlots = newMaxSlots < 1 ? 1 : newMaxSlots;
				if (maxSlots !== newMaxSlots) {
					onMaxSlotsChange(newMaxSlots);
				}
			}
		});

		resizeObserver.observe(stack);
		return () => resizeObserver.unobserve(stack);
	}, [onMaxSlotsChange, maxSlots]);

	function handlePanelClose(id) {
		onPanelRemove(id);
	}
	function handlePanelPin(id) {
		onPanelUpdate(id, { type: "permanent" })
	}

	function handlePanelUnPin(id) {
		onPanelUpdate(id, { type: "temporary" })
	}

	const fixedPanels = fixed.map(p => {
		const { id, type, pinnable, closable, body, actions: panelActions } = p;
		const actions = [...panelActions];

		if (pinnable && type === "temporary") {
			actions.push({
				id: "pin",
				icon: PushPinIcon,
				handler: handlePanelPin.bind(null, id),
				color: "default"
			});
		} else if (pinnable && type === "permanent") {
			actions.push({
				id: "pin",
				icon: PushPinIcon,
				handler: handlePanelUnPin.bind(null, p.id),
				color: "primary"
			});
		}

		if (closable) {
			actions.push({
				id: "close",
				icon: CloseIcon,
				handler: handlePanelClose.bind(null, p.id),
			})
		}

		return <PanelContainer key={p.id} body={body} actions={actions} />;
	});

	let floatingPanel = null;
	if (floating.length > 0) {
		const { id, body, actions: panelActions } = floating[floating.length - 1];
		const actions = [...panelActions];
		const close = handlePanelClose.bind(null, id);

		actions.push({
			id: "close",
			icon: CloseIcon,
			handler: close,
		});

		floatingPanel = (
			<Modal
				open
				onClose={close}
				sx={{
					p: [0, 1],
					display: "flex",
					justifyContent: "center",
				}}
			>
				<PanelContainer body={body} actions={actions} />
			</Modal >
		);
	}

	return (
		<>
			<Stack
				direction="row"
				justifyContent="center"
				height="100%"
				ref={stackRef}
				p={[0, 1]}
				spacing={1}
			>
				{fixedPanels}
			</Stack>
			{floatingPanel}
		</>
	);
}


export default HorPanelsLayout;