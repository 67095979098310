import { Stack, Typography } from "@mui/material";
import FloatingActions from "../../ui/FloatingActions";
import FrameDialogContent from "../../ui/containers/FrameDialogContent";
import FrameHeader from "../../ui/containers/FrameHeader";
import Section from "../../ui/containers/Section";
import Option from "./Option";

function DescriptionAndOrphans({ data }) {
	const { description, orphans = [] } = data;

	if (!description && !orphans.length) return;

	return (
		<Stack spacing={1}>
			{description && <Typography>{description}</Typography>}
			{orphans.map((o) => (
				<Option key={o.slug} option={o} />
			))}
		</Stack>
	);
}

function OptionsResponse({ response }) {
	const { groups = [] } = response;

	return (
		<>
			<FrameHeader title={response.name} />
			<FrameDialogContent>
				<Stack spacing={3}>
					<DescriptionAndOrphans data={response} />
					{groups.map((g) => (
						<Section key={g.slug} title={g.name}>
							<DescriptionAndOrphans data={g} />
						</Section>
					))}
				</Stack>
			</FrameDialogContent>
		</>
	);
}

export default OptionsResponse;
