import FloatingActions from "../../ui/FloatingActions";
import FrameDialogContent from "../../ui/containers/FrameDialogContent";
import FrameHeader from "../../ui/containers/FrameHeader";
import Loading from "../../ui/Loading";


function LoadingResponse({ queryLabel }) {
	return (
		<>
			<FrameHeader title={queryLabel} />
			<FrameDialogContent>
				<Loading />
			</FrameDialogContent>
		</>
	);
}


export default LoadingResponse;