import { useContext, useEffect } from "react";
import ParsedListCtx from "../../store/ParsedListCtx";
import HorPanelsCtx from "../../ui/layouts/HorPanelsCtx";
import HorPanelsLayout from "../../ui/layouts/HorPanelsLayout";
import ListPanel from "./panel/ListPanel";
import FBListPanel from "./fb/ListPanel";


function ListLayout({ faction, parsedList }) {
	const {
		onPanelAdd,
		onPanelsClear,
		onActiveSlotsChange
	} = useContext(HorPanelsCtx);

	const { onParsedListChange } = useContext(ParsedListCtx);

	useEffect(() => {
		onParsedListChange(parsedList);
	}, [parsedList, onParsedListChange]);

	const body = parsedList.gameSlug === "tlaok" ?
		<ListPanel panel={{ data: faction }} /> :
		<FBListPanel panel={{ data: faction }} />;

	useEffect(() => {
		onActiveSlotsChange(2);
		onPanelsClear();
		onPanelAdd({
			id: parsedList.id,
			type: "permanent",
			position: "center",
			order: 0,
			importance: 99,
			body,
		});
	}, [onActiveSlotsChange, onPanelsClear, onPanelAdd, parsedList.id]);

	return (
		<HorPanelsLayout flexibleSide="right" />
	);
}


export default ListLayout;