export const FB_DIRECTIVES = [
	{
		"key": "a-fiery-end",
		"value": "A Fiery End"
	},
	{
		"key": "advanced-combat-tactics",
		"value": "Advanced Combat Tactics"
	},
	{
		"key": "aetheric-interference",
		"value": "Aetheric Interference"
	},
	{
		"key": "aggressive-compulsion",
		"value": "Aggressive Compulsion"
	},
	{
		"key": "amplified-biogenics",
		"value": "Amplified Biogenics"
	},
	{
		"key": "animate-vessel",
		"value": "Animate Vessel"
	},
	{
		"key": "apex-master",
		"value": "Apex Master"
	},
	{
		"key": "arcane-excellence",
		"value": "Arcane Excellence"
	},
	{
		"key": "arcane-interference",
		"value": "Arcane Interference"
	},
	{
		"key": "armor-piercing",
		"value": "Armor Piercing (X)"
	},
	{
		"key": "ashes-to-ashes",
		"value": "Ashes to Ashes"
	},
	{
		"key": "assault-directive",
		"value": "Assault Directive"
	},
	{
		"key": "attracting-pheromones",
		"value": "Attracting Pheromones"
	},
	{
		"key": "aura-of-death",
		"value": "Aura of Death (X)"
	},
	{
		"key": "aura-of-malice",
		"value": "Aura of Malice"
	},
	{
		"key": "avatars-of-the-hunt",
		"value": "Avatars of the Hunt"
	},
	{
		"key": "barrage",
		"value": "Barrage (X)"
	},
	{
		"key": "blasphemous-miasma",
		"value": "Blasphemous Miasma"
	},
	{
		"key": "blasphemous-prayer",
		"value": "Blasphemous Prayer"
	},
	{
		"key": "blessed",
		"value": "Blessed"
	},
	{
		"key": "blinding-light",
		"value": "Blinding Light"
	},
	{
		"key": "blood-of-the-einherjar",
		"value": "Blood of the Einherjar"
	},
	{
		"key": "born-in-shame",
		"value": "Born in Shame"
	},
	{
		"key": "bottom-of-the-food-chain",
		"value": "Bottom of the Food Chain"
	},
	{
		"key": "brace",
		"value": "Brace!"
	},
	{
		"key": "bravery",
		"value": "Bravery"
	},
	{
		"key": "brawler",
		"value": "Brawler"
	},
	{
		"key": "break-their-lines",
		"value": "Break their Lines!"
	},
	{
		"key": "brine-erosion",
		"value": "Brine Erosion"
	},
	{
		"key": "brutal-impact",
		"value": "Brutal Impact (X)"
	},
	{
		"key": "burst-of-faith",
		"value": "Burst of Faith"
	},
	{
		"key": "call-of-the-hunt",
		"value": "Call of the Hunt"
	},
	{
		"key": "call-the-storm",
		"value": "Call the Storm"
	},
	{
		"key": "carved-in-the-annals-of-aghm",
		"value": "Carved in the Annals of Aghm"
	},
	{
		"key": "catalytic-strength",
		"value": "Catalytic Strength"
	},
	{
		"key": "chameleonic-epidermis",
		"value": "Chameleonic Epidermis"
	},
	{
		"key": "cleave",
		"value": "Cleave (X)"
	},
	{
		"key": "clonekind",
		"value": "Clonekind"
	},
	{
		"key": "coordinated-assault",
		"value": "Coordinated Assault"
	},
	{
		"key": "corrosion",
		"value": "Corrosion"
	},
	{
		"key": "coruscation",
		"value": "Coruscation"
	},
	{
		"key": "counter-attack",
		"value": "Counter-Attack"
	},
	{
		"key": "dauntless",
		"value": "Dauntless"
	},
	{
		"key": "deadly-blades",
		"value": "Deadly Blades"
	},
	{
		"key": "deadly-shot",
		"value": "Deadly Shot"
	},
	{
		"key": "deafen",
		"value": "Deafen"
	},
	{
		"key": "deathless-duty",
		"value": "Deathless Duty"
	},
	{
		"key": "decay",
		"value": "Decay (X)"
	},
	{
		"key": "defensive-directive",
		"value": "Defensive Directive"
	},
	{
		"key": "defensive-formation",
		"value": "Defensive Formation"
	},
	{
		"key": "devoted-to-hazlia",
		"value": "Devoted to Hazlia"
	},
	{
		"key": "devout",
		"value": "Devout"
	},
	{
		"key": "disruption",
		"value": "Disruption"
	},
	{
		"key": "divine-purpose",
		"value": "Divine Purpose"
	},
	{
		"key": "dread",
		"value": "Dread"
	},
	{
		"key": "dronekind",
		"value": "Dronekind"
	},
	{
		"key": "dull-blades",
		"value": "Dull Blades"
	},
	{
		"key": "duty-bound",
		"value": "Duty Bound"
	},
	{
		"key": "earth-s-grasp",
		"value": "Earth's Grasp"
	},
	{
		"key": "echoes-of-conquest",
		"value": "Echoes of Conquest"
	},
	{
		"key": "elemental-puissance",
		"value": "Elemental Puissance"
	},
	{
		"key": "embolden",
		"value": "Embolden"
	},
	{
		"key": "encase",
		"value": "Encase"
	},
	{
		"key": "encircle-prey",
		"value": "Encircle Prey"
	},
	{
		"key": "endless-march",
		"value": "Endless March"
	},
	{
		"key": "entropic-transfer",
		"value": "Entropic Transfer"
	},
	{
		"key": "establish-perimeter",
		"value": "Establish Perimeter"
	},
	{
		"key": "eternal-discipline",
		"value": "Eternal Discipline"
	},
	{
		"key": "example-to-follow",
		"value": "Example to Follow"
	},
	{
		"key": "expert-stalkers",
		"value": "Expert Stalkers"
	},
	{
		"key": "expert-trackers",
		"value": "Expert Trackers"
	},
	{
		"key": "expose-weakness",
		"value": "Expose Weakness"
	},
	{
		"key": "false-hopes",
		"value": "False Hopes"
	},
	{
		"key": "fanatical-devotion",
		"value": "Fanatical Devotion"
	},
	{
		"key": "fearless",
		"value": "Fearless (X)"
	},
	{
		"key": "fearsome",
		"value": "Fearsome"
	},
	{
		"key": "feral",
		"value": "Feral"
	},
	{
		"key": "feral-hunt",
		"value": "Feral Hunt"
	},
	{
		"key": "ferric-expansion",
		"value": "Ferric Expansion"
	},
	{
		"key": "fiend-hunter",
		"value": "Fiend Hunter"
	},
	{
		"key": "final-transformation",
		"value": "Final Transformation"
	},
	{
		"key": "fire-and-ruin",
		"value": "Fire and Ruin"
	},
	{
		"key": "flame-burst",
		"value": "Flame Burst"
	},
	{
		"key": "flaming-oratory",
		"value": "Flaming Oratory"
	},
	{
		"key": "flank",
		"value": "Flank"
	},
	{
		"key": "flawless-form",
		"value": "Flawless Form"
	},
	{
		"key": "flawless-strikes",
		"value": "Flawless Strikes"
	},
	{
		"key": "fluid-formation",
		"value": "Fluid Formation"
	},
	{
		"key": "flurry",
		"value": "Flurry"
	},
	{
		"key": "fly",
		"value": "Fly"
	},
	{
		"key": "fortitude-of-faith",
		"value": "Fortitude of Faith"
	},
	{
		"key": "forward-scouts",
		"value": "Forward Scouts"
	},
	{
		"key": "fuelled-by-the-furnace",
		"value": "Fuelled by the Furnace"
	},
	{
		"key": "gift-of-the-einherjar",
		"value": "Gift of the Einherjar"
	},
	{
		"key": "glimmers-of-a-golden-age",
		"value": "Glimmers of a Golden Age"
	},
	{
		"key": "glorious-charge",
		"value": "Glorious Charge"
	},
	{
		"key": "glory-to-valhalla",
		"value": "Glory to Valhalla!"
	},
	{
		"key": "hardened",
		"value": "Hardened (X)"
	},
	{
		"key": "heavenly-blessing",
		"value": "Heavenly Blessing"
	},
	{
		"key": "heavenly-entourage",
		"value": "Heavenly Entourage"
	},
	{
		"key": "heavy-impact",
		"value": "Heavy Impact"
	},
	{
		"key": "heightened-senses",
		"value": "Heightened Senses"
	},
	{
		"key": "hold-s-finest",
		"value": "Hold's Finest"
	},
	{
		"key": "hold-your-ground",
		"value": "Hold your Ground!"
	},
	{
		"key": "house-colors",
		"value": "House Colors"
	},
	{
		"key": "hulking-frame",
		"value": "Hulking Frame"
	},
	{
		"key": "hunters-of-souls",
		"value": "Hunters of Souls"
	},
	{
		"key": "hunting-pack",
		"value": "Hunting Pack"
	},
	{
		"key": "hunting-party",
		"value": "Hunting Party"
	},
	{
		"key": "ice-armor",
		"value": "Ice Armor"
	},
	{
		"key": "icon-of-conquest",
		"value": "Icon of Conquest"
	},
	{
		"key": "if-it-bleeds-we-can-kill-it",
		"value": "If it Bleeds we can Kill it"
	},
	{
		"key": "implacable",
		"value": "Implacable"
	},
	{
		"key": "indifferent-towards-life",
		"value": "Indifferent Towards Life"
	},
	{
		"key": "indomitable",
		"value": "Indomitable"
	},
	{
		"key": "infernal-guardians",
		"value": "Infernal Guardians"
	},
	{
		"key": "inspiring-presence",
		"value": "Inspiring Presence"
	},
	{
		"key": "interlocking-shields",
		"value": "Interlocking Shields"
	},
	{
		"key": "jouster",
		"value": "Jouster"
	},
	{
		"key": "kill-marks",
		"value": "Kill Marks"
	},
	{
		"key": "king-of-mannheim",
		"value": "King of Mannheim"
	},
	{
		"key": "knightly-entourage",
		"value": "Knightly Entourage"
	},
	{
		"key": "lead-by-example",
		"value": "Lead by Example"
	},
	{
		"key": "leader-of-a-thousand",
		"value": "Leader of a Thousand"
	},
	{
		"key": "leader-of-dweghom",
		"value": "Leader of Dweghom"
	},
	{
		"key": "leader-of-legions",
		"value": "Leader of Legions"
	},
	{
		"key": "leap",
		"value": "Leap"
	},
	{
		"key": "legacy-of-glory",
		"value": "Legacy of Glory"
	},
	{
		"key": "lethal-demise",
		"value": "Lethal Demise"
	},
	{
		"key": "lightning-assault",
		"value": "Lightning Assault"
	},
	{
		"key": "linebreaker",
		"value": "Linebreaker"
	},
	{
		"key": "lipsana",
		"value": "Lipsana"
	},
	{
		"key": "living-metal",
		"value": "Living Metal"
	},
	{
		"key": "long-reach",
		"value": "Long Reach"
	},
	{
		"key": "loyalty",
		"value": "Loyalty"
	},
	{
		"key": "madness",
		"value": "Madness"
	},
	{
		"key": "march-of-the-faithful",
		"value": "March of the Faithful"
	},
	{
		"key": "mark-of-conquest",
		"value": "Mark of Conquest"
	},
	{
		"key": "mass-produced",
		"value": "Mass Produced"
	},
	{
		"key": "massive-frame",
		"value": "Massive Frame"
	},
	{
		"key": "mend-flesh",
		"value": "Mend Flesh"
	},
	{
		"key": "mist-weave",
		"value": "Mist Weave"
	},
	{
		"key": "mnemancer-s-apprentice",
		"value": "Mnemancer's Apprentice"
	},
	{
		"key": "moaghm-dorh",
		"value": "Moaghm Dorh!"
	},
	{
		"key": "nekrosis",
		"value": "Nekrosis"
	},
	{
		"key": "ninuah-s-caress",
		"value": "Ninuah's Caress"
	},
	{
		"key": "no-coming-back-from-this",
		"value": "No Coming Back From This"
	},
	{
		"key": "no-time-to-bleed",
		"value": "No Time to Bleed"
	},
	{
		"key": "not-the-largest-we-have-slain",
		"value": "Not the Largest we have Slain"
	},
	{
		"key": "nothing-to-prove",
		"value": "Nothing to Prove"
	},
	{
		"key": "obdurate-bone-and-stone",
		"value": "Obdurate Bone and Stone"
	},
	{
		"key": "oblivious",
		"value": "Oblivious"
	},
	{
		"key": "on-a-mission",
		"value": "On a Mission"
	},
	{
		"key": "on-my-orders",
		"value": "On my Orders"
	},
	{
		"key": "one-voice",
		"value": "One Voice"
	},
	{
		"key": "only-the-finest",
		"value": "Only the Finest"
	},
	{
		"key": "onwards",
		"value": "Onwards!"
	},
	{
		"key": "opportunists",
		"value": "Opportunists"
	},
	{
		"key": "overcharge",
		"value": "Overcharge"
	},
	{
		"key": "overcoming-the-limits-of-flesh",
		"value": "Overcoming the Limits of Flesh"
	},
	{
		"key": "overrun",
		"value": "Overrun"
	},
	{
		"key": "oxidize",
		"value": "Oxidize"
	},
	{
		"key": "pack-insticts",
		"value": "Pack Insticts"
	},
	{
		"key": "pack-leader",
		"value": "Pack Leader"
	},
	{
		"key": "parry",
		"value": "Parry"
	},
	{
		"key": "past-echoes",
		"value": "Past Echoes"
	},
	{
		"key": "peerless-commander",
		"value": "Peerless Commander"
	},
	{
		"key": "pentarchoi",
		"value": "Pentarchoi"
	},
	{
		"key": "pheromantic-communications",
		"value": "Pheromantic Communications"
	},
	{
		"key": "pheromantic-override",
		"value": "Pheromantic Override"
	},
	{
		"key": "pheromone-burst",
		"value": "Pheromone Burst"
	},
	{
		"key": "pike-formation",
		"value": "Pike Formation"
	},
	{
		"key": "pillars-of-stone",
		"value": "Pillars of Stone"
	},
	{
		"key": "polearm-tactics",
		"value": "Polearm Tactics"
	},
	{
		"key": "power-of-faith",
		"value": "Power of Faith"
	},
	{
		"key": "powerful-physique",
		"value": "Powerful Physique"
	},
	{
		"key": "precise-shot",
		"value": "Precise Shot"
	},
	{
		"key": "press-the-advance",
		"value": "Press the Advance!"
	},
	{
		"key": "preternatural-endurance",
		"value": "Preternatural Endurance"
	},
	{
		"key": "pride-of-capitas",
		"value": "Pride of Capitas"
	},
	{
		"key": "priest",
		"value": "Priest (X)"
	},
	{
		"key": "protective-glyphs",
		"value": "Protective Glyphs"
	},
	{
		"key": "prove-yourself-worthy",
		"value": "Prove Yourself Worthy!"
	},
	{
		"key": "prowl",
		"value": "Prowl"
	},
	{
		"key": "purebred-warhorse",
		"value": "Purebred Warhorse"
	},
	{
		"key": "pursuit",
		"value": "Pursuit"
	},
	{
		"key": "pursuit-of-aghm",
		"value": "Pursuit of Aghm"
	},
	{
		"key": "pyrosis",
		"value": "Pyrosis"
	},
	{
		"key": "quicksilver-strike",
		"value": "Quicksilver Strike"
	},
	{
		"key": "raiding-party",
		"value": "Raiding Party"
	},
	{
		"key": "secure-beachhead",
		"value": "Secure Beachhead"
	},
	{
		"key": "rampant-savagery",
		"value": "Rampant Savagery"
	},
	{
		"key": "rapid-revitalization",
		"value": "Rapid Revitalization"
	},
	{
		"key": "rapid-volley",
		"value": "Rapid Volley"
	},
	{
		"key": "ready-your-weapons",
		"value": "Ready Your Weapons!"
	},
	{
		"key": "recite-the-saga",
		"value": "Recite the Saga"
	},
	{
		"key": "reclamation-directive",
		"value": "Reclamation Directive"
	},
	{
		"key": "recuperate",
		"value": "Recuperate"
	},
	{
		"key": "regenerative-tissue",
		"value": "Regenerative Tissue"
	},
	{
		"key": "regroup",
		"value": "Regroup"
	},
	{
		"key": "relentless",
		"value": "Relentless"
	},
	{
		"key": "relentless-aggression",
		"value": "Relentless Aggression"
	},
	{
		"key": "relentless-blows",
		"value": "Relentless Blows"
	},
	{
		"key": "renowned-duellist",
		"value": "Renowned Duellist"
	},
	{
		"key": "reshape-destiny",
		"value": "Reshape Destiny"
	},
	{
		"key": "resonating-essence",
		"value": "Resonating Essence"
	},
	{
		"key": "resonating-life-force",
		"value": "Resonating Life Force"
	},
	{
		"key": "retracting-claws",
		"value": "Retracting Claws"
	},
	{
		"key": "rider",
		"value": "Rider"
	},
	{
		"key": "righteous-assault",
		"value": "Righteous Assault"
	},
	{
		"key": "rime-storm",
		"value": "Rime Storm"
	},
	{
		"key": "roiling-earth",
		"value": "Roiling Earth"
	},
	{
		"key": "runes-of-wisdom",
		"value": "Runes of Wisdom"
	},
	{
		"key": "sacred-censer",
		"value": "Sacred Censer"
	},
	{
		"key": "sacrificial-lamb",
		"value": "Sacrificial Lamb"
	},
	{
		"key": "scholar-of-the-profane",
		"value": "Scholar of the Profane"
	},
	{
		"key": "self-destruct",
		"value": "Self-Destruct"
	},
	{
		"key": "self-repairing-tissue",
		"value": "Self-Repairing Tissue"
	},
	{
		"key": "sensory-receptors",
		"value": "Sensory Receptors"
	},
	{
		"key": "shield",
		"value": "Shield"
	},
	{
		"key": "shield-wall-tactics",
		"value": "Shield Wall Tactics"
	},
	{
		"key": "shieldwall",
		"value": "Shieldwall!"
	},
	{
		"key": "shock-assault",
		"value": "Shock Assault"
	},
	{
		"key": "smite",
		"value": "Smite"
	},
	{
		"key": "sovereign-s-directive",
		"value": "Sovereign's Directive"
	},
	{
		"key": "spearhead",
		"value": "Spearhead"
	},
	{
		"key": "spearmaidens",
		"value": "Spearmaidens"
	},
	{
		"key": "spirit-of-fenrir",
		"value": "Spirit of Fenrir"
	},
	{
		"key": "stampede",
		"value": "Stampede"
	},
	{
		"key": "still-enough-there",
		"value": "Still Enough There"
	},
	{
		"key": "stubborn-determination",
		"value": "Stubborn Determination"
	},
	{
		"key": "superior-creation",
		"value": "Superior Creation"
	},
	{
		"key": "superiority",
		"value": "Superiority"
	},
	{
		"key": "support",
		"value": "Support"
	},
	{
		"key": "suppress",
		"value": "Suppress"
	},
	{
		"key": "sureshot",
		"value": "Sureshot"
	},
	{
		"key": "surprise-attack",
		"value": "Surprise Attack"
	},
	{
		"key": "synaptic-communications",
		"value": "Synaptic Communications"
	},
	{
		"key": "tactical-retreat",
		"value": "Tactical Retreat! "
	},
	{
		"key": "tactician",
		"value": "Tactician"
	},
	{
		"key": "tamed-beast",
		"value": "Tamed Beast"
	},
	{
		"key": "teachings-of-the-wasteland",
		"value": "Teachings of the Wasteland"
	},
	{
		"key": "temper-blades",
		"value": "Temper Blades"
	},
	{
		"key": "tenacious",
		"value": "Tenacious"
	},
	{
		"key": "terrifying",
		"value": "Terrifying (X)"
	},
	{
		"key": "territorial-instincts",
		"value": "Territorial Instincts"
	},
	{
		"key": "the-aghm-of-the-fallen",
		"value": "The Aghm of the Fallen"
	},
	{
		"key": "the-hunt-s-instincts",
		"value": "The Hunt's Instincts"
	},
	{
		"key": "the-mnemancers-will-want-to-see-this",
		"value": "The Mnemancers Will Want to See This"
	},
	{
		"key": "thrill-of-the-kill",
		"value": "Thrill of the Kill"
	},
	{
		"key": "throwing-axes",
		"value": "Throwing Axes"
	},
	{
		"key": "throwing-weapons",
		"value": "Throwing Weapons"
	},
	{
		"key": "to-the-colors",
		"value": "To the Colors!"
	},
	{
		"key": "to-the-front-lines",
		"value": "To the Front Lines!"
	},
	{
		"key": "to-the-last-breath",
		"value": "To the Last Breath"
	},
	{
		"key": "torrential-fire",
		"value": "Torrential Fire"
	},
	{
		"key": "torrents-of-fire",
		"value": "Torrents of Fire"
	},
	{
		"key": "transient-glory",
		"value": "Transient Glory"
	},
	{
		"key": "well-timed-strike",
		"value": "Well Timed Strike"
	},
	{
		"key": "twisted-genius",
		"value": "Twisted Genius"
	},
	{
		"key": "ulterior-motives",
		"value": "Ulterior Motives"
	},
	{
		"key": "undying-entourage",
		"value": "Undying Entourage"
	},
	{
		"key": "unending-vigil",
		"value": "Unending Vigil"
	},
	{
		"key": "unholy-visage",
		"value": "Unholy Visage"
	},
	{
		"key": "unnatural-reflexes",
		"value": "Unnatural Reflexes"
	},
	{
		"key": "unstoppable",
		"value": "Unstoppable"
	},
	{
		"key": "unyielding",
		"value": "Unyielding"
	},
	{
		"key": "valhalla-s-caress",
		"value": "Valhalla's Caress"
	},
	{
		"key": "veteran-warrior",
		"value": "Veteran Warrior"
	},
	{
		"key": "veterans-of-the-raids",
		"value": "Veterans of the Raids"
	},
	{
		"key": "violence-of-action",
		"value": "Violence of Action"
	},
	{
		"key": "virulence",
		"value": "Virulence"
	},
	{
		"key": "visions-of-conquest",
		"value": "Visions of Conquest"
	},
	{
		"key": "way-of-the-hunt",
		"value": "Way of the Hunt"
	},
	{
		"key": "wedge",
		"value": "Wedge!"
	},
	{
		"key": "wizard",
		"value": "Wizard (X)"
	},
	{
		"key": "wolfpack",
		"value": "Wolfpack"
	},
	{
		"key": "you-and-what-army",
		"value": "You and What Army?"
	},
	{
		"key": "you-and-what-army-matriarch",
		"value": "You and What Army?"
	},
	{
		"key": "standard-of-steel",
		"value": "Standard of Steel"
	},
	{
		"key": "standard-of-the-unknown-legionnaire",
		"value": "Standard of the Unknown Legionnaire"
	},
	{
		"key": "pennant-of-the-empire",
		"value": "Pennant of the Empire"
	},
	{
		"key": "icon-of-saint-lazarus",
		"value": "Icon of Saint Lazarus"
	},
	{
		"key": "indomitable-plate",
		"value": "Indomitable Plate"
	},
	{
		"key": "bakkian-token",
		"value": "Bakkian Token"
	},
	{
		"key": "mask-of-eaklides",
		"value": "Mask of Eaklides"
	},
	{
		"key": "caledburn",
		"value": "Caledburn"
	},
	{
		"key": "laurean-lance",
		"value": "Laurean Lance"
	},
	{
		"key": "the-flesh-cleaver",
		"value": "The Flesh Cleaver"
	},
	{
		"key": "finite-state-prototype",
		"value": "Finite State Prototype"
	},
	{
		"key": "lady-s-blessing",
		"value": "Lady's Blessing"
	},
	{
		"key": "pendant-of-saint-nicholas",
		"value": "Pendant of Saint Nicholas"
	},
	{
		"key": "last-resort",
		"value": "Last Resort"
	},
	{
		"key": "hopes-and-prayers",
		"value": "Hopes and Prayers"
	},
	{
		"key": "arcane-tome",
		"value": "Arcane Tome"
	},
	{
		"key": "adaptive-evolution",
		"value": "Adaptive Evolution"
	},
	{
		"key": "biotic-hive",
		"value": "Biotic Hive"
	},
	{
		"key": "extended-pheromantic-signals",
		"value": "Extended Pheromantic Signals"
	},
	{
		"key": "redundant-internal-structure",
		"value": "Redundant Internal Structure"
	},
	{
		"key": "insulated-synaptic-pathways",
		"value": "Insulated Synaptic Pathways"
	},
	{
		"key": "adaptive-senses",
		"value": "Adaptive Senses"
	},
	{
		"key": "burrowing-parasites",
		"value": "Burrowing Parasites"
	},
	{
		"key": "hyper-responsive-optic-nerves",
		"value": "Hyper-Responsive Optic Nerves"
	},
	{
		"key": "ablative-flesh",
		"value": "Ablative Flesh"
	},
	{
		"key": "shields-of-the-fallen",
		"value": "Shields of the Fallen"
	},
	{
		"key": "mnemancer-s-eye",
		"value": "Mnemancer's Eye"
	},
	{
		"key": "steel-enhancements",
		"value": "Steel Enhancements"
	},
	{
		"key": "arena-champion",
		"value": "Arena Champion"
	},
	{
		"key": "memory-of-stone",
		"value": "Memory of Stone"
	},
	{
		"key": "blade-of-ekhidis",
		"value": "Blade of Ekhidis"
	},
	{
		"key": "perfectly-balanced",
		"value": "Perfectly Balanced"
	},
	{
		"key": "flaming-weapon",
		"value": "Flaming Weapon"
	},
	{
		"key": "gifted-in-fire",
		"value": "Gifted in Fire"
	},
	{
		"key": "remembrance-of-the-core",
		"value": "Remembrance of the Core"
	},
	{
		"key": "the-flame-flickers",
		"value": "The Flame Flickers"
	},
	{
		"key": "heart-of-the-mountain",
		"value": "Heart of the Mountain"
	},
	{
		"key": "memory-of-breath",
		"value": "Memory of Breath"
	},
	{
		"key": "raven-messengers",
		"value": "Raven Messengers"
	},
	{
		"key": "figurehead-of-the-naglfar",
		"value": "Figurehead of the Naglfar"
	},
	{
		"key": "jarngreipr",
		"value": "Járngreipr"
	},
	{
		"key": "ty-jokull-the-rimeheart",
		"value": "Ty Jokull, the Rimeheart"
	},
	{
		"key": "the-sail-of-yokstud",
		"value": "The Sail of Yokstud"
	},
	{
		"key": "thorn-from-the-sacred-tree",
		"value": "Thorn from the Sacred Tree"
	},
	{
		"key": "the-shard-of-vinda",
		"value": "The Shard of Vinda"
	},
	{
		"key": "vanquisher-of-monsters",
		"value": "Vanquisher of Monsters"
	},
	{
		"key": "mistcaller-s-raiment",
		"value": "Mistcaller's Raiment"
	},
	{
		"key": "the-horn-of-hjoldgar",
		"value": "The Horn of Hjoldgar"
	},
	{
		"key": "heriolf-s-folly",
		"value": "Heriolf's Folly"
	},
	{
		"key": "scars-of-endekar",
		"value": "Scars of Endekar"
	},
	{
		"key": "song-of-famine",
		"value": "Song of Famine"
	},
	{
		"key": "song-of-famine-command",
		"value": "Song of Famine"
	},
	{
		"key": "whisper-of-death",
		"value": "Whisper of Death"
	},
	{
		"key": "whisper-of-death-command",
		"value": "Whisper of Death"
	},
	{
		"key": "bone-whistle",
		"value": "Bone Whistle"
	},
	{
		"key": "heartsblood-draught",
		"value": "Heartsblood Draught"
	},
	{
		"key": "kiss-of-the-dilosaur",
		"value": "Kiss of the Dilosaur"
	},
	{
		"key": "war-s-stare",
		"value": "War's Stare"
	},
	{
		"key": "the-hidden-fang",
		"value": "The Hidden Fang"
	},
	{
		"key": "dreamcatcher",
		"value": "Dreamcatcher"
	},
	{
		"key": "wind-s-children",
		"value": "Wind's Children"
	},
	{
		"key": "essence-of-the-phonopteryx",
		"value": "Essence of the Phonopteryx"
	},
	{
		"key": "primordial-splinter",
		"value": "Primordial Splinter"
	},
	{
		"key": "sanctified-labaron",
		"value": "Sanctified Labaron"
	},
	{
		"key": "caelestine-banner",
		"value": "Caelestine Banner"
	},
	{
		"key": "legio-i-primigenia",
		"value": "Legio I 'Primigenia'"
	},
	{
		"key": "aventine-armour",
		"value": "Aventine Armour"
	},
	{
		"key": "cuirass-of-hazlia-s-shadow",
		"value": "Cuirass of Hazlia's Shadow"
	},
	{
		"key": "armor-of-the-living-saint",
		"value": "Armor of the Living Saint"
	},
	{
		"key": "severian-s-dagger",
		"value": "Severian's Dagger"
	},
	{
		"key": "skofnung",
		"value": "Skofnung"
	},
	{
		"key": "anastegma-brand-of-the-faithless",
		"value": "Anastegma, Brand of the Faithless"
	},
	{
		"key": "unholy-sacrament",
		"value": "Unholy Sacrament"
	},
	{
		"key": "humble-devotee",
		"value": "Humble Devotee"
	},
	{
		"key": "regeneration",
		"value": "Regeneration X"
	},
	{
		"key": "jury-and-executioner",
		"value": "Jury and Executioner"
	},
	{
		"key": "crimson-order",
		"value": "Crimson Order"
	},
	{
		"key": "descendants-of-the-legendary-legions",
		"value": "Descendants of the Legendary Legions"
	},
	{
		"key": "martial-traditions",
		"value": "Martial Traditions"
	},
	{
		"key": "heroes-of-the-hundred-kingdoms",
		"value": "Heroes of the Hundred Kingdoms"
	},
	{
		"key": "breakthrough",
		"value": "Breakthrough!"
	},
	{
		"key": "secure",
		"value": "Secure!"
	},
	{
		"key": "leader",
		"value": "Leader"
	},
	{
		"key": "standard-bearer",
		"value": "Standard Bearer"
	},
	{
		"key": "imperial-academies",
		"value": "Imperial Academies"
	},
	{
		"key": "press-the-advantage",
		"value": "Press the Advantage!"
	},
	{
		"key": "tip-of-the-spear",
		"value": "Tip of the Spear"
	},
	{
		"key": "spring-to-action",
		"value": "Spring to Action!"
	},
	{
		"key": "dori-and-xyphos",
		"value": "Dori and Xyphos"
	},
	{
		"key": "lead-from-the-front",
		"value": "Lead from the Front"
	},
	{
		"key": "you-know-the-drill",
		"value": "\"You Know the Drill!\""
	},
	{
		"key": "riposte",
		"value": "Riposte!"
	},
	{
		"key": "iron-resolve",
		"value": "Iron Resolve"
	},
	{
		"key": "tactical-retreat-states",
		"value": "Tactical Retreat!"
	},
	{
		"key": "carrier-of-the-godflesh",
		"value": "Carrier of the Godflesh"
	},
	{
		"key": "flesh-meets-steel",
		"value": "Flesh Meets Steel"
	},
	{
		"key": "mobility",
		"value": "Mobility"
	},
	{
		"key": "resist",
		"value": "Resist"
	},
	{
		"key": "liquid-fire",
		"value": "Liquid Fire"
	},
	{
		"key": "carry-orders",
		"value": "Carry Orders"
	},
	{
		"key": "spear-and-hoplon",
		"value": "Spear and Hoplon"
	},
	{
		"key": "doratismos",
		"value": "Doratismos!"
	},
	{
		"key": "icon-of-the-messenger",
		"value": "Icon of the Messenger"
	},
	{
		"key": "labaron-of-the-ancestors",
		"value": "Labaron of the Ancestors"
	},
	{
		"key": "reclaim",
		"value": "Reclaim"
	},
	{
		"key": "front-line-armor-grafts",
		"value": "Front Line Armor Grafts"
	},
	{
		"key": "rapid-assault-modifications",
		"value": "Rapid Assault Modifications"
	},
	{
		"key": "balanced-blades",
		"value": "Balanced Blades"
	},
	{
		"key": "inscription-of-the-blacksmith",
		"value": "Inscription of the Blacksmith"
	},
	{
		"key": "serrated-blades",
		"value": "Serrated Blades"
	},
	{
		"key": "phalanx-tactics",
		"value": "Phalanx Tactics"
	},
	{
		"key": "scorpion",
		"value": "Scorpion"
	},
	{
		"key": "phlogiston-launcher",
		"value": "Phlogiston Launcher"
	},
	{
		"key": "herald-of-the-forge-god",
		"value": "Herald of the Forge God"
	},
	{
		"key": "piercing-heat",
		"value": "Piercing Heat"
	},
	{
		"key": "smoldering-in-ashes",
		"value": "Smoldering in Ashes"
	},
	{
		"key": "damping-force",
		"value": "Damping Force"
	},
	{
		"key": "magnetic-balance",
		"value": "Magnetic Balance"
	},
	{
		"key": "shock-and-steel",
		"value": "Shock and Steel"
	},
	{
		"key": "synchronized-formation",
		"value": "Synchronized Formation"
	},
	{
		"key": "structural-rearrangement",
		"value": "Structural Rearrangement"
	},
	{
		"key": "veteran-hoplite",
		"value": "Veteran Hoplite"
	},
	{
		"key": "higher-calling",
		"value": "Higher Calling"
	},
	{
		"key": "duellist",
		"value": "Duellist"
	},
	{
		"key": "former-household-guard",
		"value": "Former Household Guard"
	},
	{
		"key": "huntress",
		"value": "Huntress"
	},
	{
		"key": "the-trials",
		"value": "The Trials"
	},
	{
		"key": "stoic-elders",
		"value": "Stoic Elders"
	},
	{
		"key": "veteran-champions",
		"value": "Veteran Champions"
	},
	{
		"key": "bellowing-shout",
		"value": "Bellowing Shout"
	},
	{
		"key": "fight-or-be-bound",
		"value": "Fight or be Bound!"
	},
	{
		"key": "a-lifetime-of-war",
		"value": "A Lifetime of War"
	},
	{
		"key": "burnout",
		"value": "Burnout"
	},
	{
		"key": "the-ultimate-sacrifice",
		"value": "The Ultimate Sacrifice"
	},
	{
		"key": "the-ultimate-betrayal",
		"value": "The Ultimate Betrayal"
	},
	{
		"key": "blessed-by-cleon",
		"value": "Blessed by Cleon"
	},
	{
		"key": "legends-amongst-legends",
		"value": "Legends Amongst Legends"
	},
	{
		"key": "shard-of-cleon",
		"value": "Shard of Cleon"
	},
	{
		"key": "shard-of-hazlia",
		"value": "Shard of Hazlia"
	},
	{
		"key": "tip-of-the-lance",
		"value": "Tip of the Lance"
	},
	{
		"key": "chant-of-conquest",
		"value": "Chant of Conquest"
	},
	{
		"key": "flux-powered",
		"value": "Flux Powered"
	},
	{
		"key": "enveloped-in-flames",
		"value": "Enveloped in Flames"
	},
	{
		"key": "grafted-in-fire",
		"value": "Grafted in Fire"
	},
	{
		"key": "knights-of-an-ancient-past",
		"value": "Knights of an Ancient Past"
	},
	{
		"key": "bulwark",
		"value": "Bulwark"
	},
	{
		"key": "fylkning",
		"value": "Fylkning"
	},
	{
		"key": "berserk-attack",
		"value": "Berserk Attack"
	},
	{
		"key": "fast-savage-and-deadly",
		"value": "Fast, Savage and Deadly"
	},
	{
		"key": "ride-the-lightning",
		"value": "Ride the Lightning!",
	},
	{
		"key": "thunderstruck",
		"value": "Thunderstruck",
	},
	{
		"key": "beacons-of-the-profane",
		"value": "Beacons of the Profane",
	},
	{
		"key": "frenzy",
		"value": "Frenzy",
	},
	{
		"key": "unbridled-ferocity",
		"value": "Unbridled Ferocity",
	},
	{
		"key": "guardians-of-the-godflesh",
		"value": "Guardians of the Godflesh",
	},
	{
		"key": "engagement-protocols",
		"value": "Engagement Protocols",
	},
	{
		"key": "annihilation-protocols",
		"value": "Annihilation Protocols",
	},
	{
		"key": "combat-directives",
		"value": "Combat Directives",
	},
	{
		"key": "supporting-fire",
		"value": "Supporting Fire",
	},
	{
		"key": "attuned-element",
		"value": "Attuned Element"
	},
	{
		"key": "infernal-release",
		"value": "Infernal Release"
	},
	{
		"key": "aetheric-release",
		"value": "Aetheric Release"
	},
	{
		"key": "arrogance",
		"value": "Arrogance"
	},
	{
		"key": "hubris",
		"value": "Hubris"
	},
	{
		"key": "magus",
		"value": "Magus"
	},
	{
		"key": "high-lord-of-the-elements",
		"value": "High Lord of the Elements"
	},
	{
		"key": "empowerment",
		"value": "Empowerment"
	},
	{
		"key": "shattering",
		"value": "Shattering"
	},
	{
		"key": "deflection",
		"value": "Deflection"
	},
	{
		"key": "elemental-puppets",
		"value": "Elemental Puppets"
	},
	{
		"key": "spirits-of-avarice",
		"value": "Spirits of Avarice"
	},
	{
		"key": "mindless-savagery",
		"value": "Mindless Savagery"
	},
	{
		"key": "best-money-can-buy",
		"value": "Best Money Can Buy"
	},
	{
		"key": "jaaduee-bled-arcane-blade",
		"value": "Jaaduee Bled - Arcane Blade"
	},
	{
		"key": "favorable-winds",
		"value": "Favorable Winds"
	},
	{
		"key": "arcane-dervish",
		"value": "Arcane Dervish"
	},
	{
		"key": "taskmaster",
		"value": "Taskmaster"
	},
	{
		"key": "bolana-trishool",
		"value": "Bolana Trishool"
	},
	{
		"key": "teer",
		"value": "Teer"
	},
	{
		"key": "dhaatu",
		"value": "Dhaatu"
	},
	{
		"key": "karana",
		"value": "Karana"
	},
	{
		"key": "vaayu",
		"value": "Vaayu"
	},
	{
		"key": "vaayu-command",
		"value": "Vaayu"
	},
	{
		"key": "aag",
		"value": "Aag"
	},
	{
		"key": "aag-command",
		"value": "Aag"
	},
	{
		"key": "elemental-conduit",
		"value": "Elemental Conduit"
	},
	{
		"key": "court-of-fire-maharajah",
		"value": "Court of Fire"
	},
	{
		"key": "court-of-air-maharajah",
		"value": "Court of Air"
	},
	{
		"key": "court-of-fire-sorcerer",
		"value": "Court of Fire"
	},
	{
		"key": "court-of-air-sorcerer",
		"value": "Court of Air"
	},
	{
		"key": "court-of-fire-raj",
		"value": "Court of Fire"
	},
	{
		"key": "court-of-air-raj",
		"value": "Court of Air"
	},
	{
		"key": "lords-of-the-inferno",
		"value": "Lords of the Inferno"
	},
	{
		"key": "rebirth-of-the-phoenix",
		"value": "Rebirth of the Phoenix"
	},
	{
		"key": "gift-of-lightning",
		"value": "Gift of Lightning"
	},
	{
		"key": "gift-of-thunder",
		"value": "Gift of Thunder"
	},
	{
		"key": "children-of-ash-and-cinders",
		"value": "Children of Ash and Cinders"
	},
	{
		"key": "blazing-sign",
		"value": "Blazing Sign"
	},
	{
		"key": "children-of-storm-and-spray",
		"value": "Children of Storm and Spray"
	},
	{
		"key": "fulgent-sign",
		"value": "Fulgent Sign"
	},
	{
		"key": "strength-of-fire",
		"value": "Strength of Fire"
	},
	{
		"key": "blazing-crown",
		"value": "Blazing Crown"
	},
	{
		"key": "galvanic-crown",
		"value": "Galvanic Crown"
	},
	{
		"key": "strength-of-air",
		"value": "Strength of Air"
	},
	{
		"key": "tight-formation",
		"value": "Tight Formation"
	},
	{
		"key": "impact",
		"value": "Impact (X)"
	},
	{
		"key": "aimed-shot",
		"value": "Aimed Shot"
	},
	{
		"key": "master-horsemen",
		"value": "Master Horsemen"
	},
	{
		"key": "chariot",
		"value": "Chariot"
	},
	{
		"key": "cavalry-mounted-spear",
		"value": "Cavalry Mounted Spear"
	},
	{
		"key": "mounted-mastery",
		"value": "Mounted Mastery"
	},
	{
		"key": "hammer-and-anvil",
		"value": "Hammer and Anvil"
	},
	{
		"key": "desperate-pace",
		"value": "Desperate Pace"
	},
	{
		"key": "now",
		"value": "Now!"
	},
	{
		"key": "unparalleled-hunters",
		"value": "Unparalleled Hunters"
	},
	{
		"key": "marksmen-creation",
		"value": "Marksmen Creation"
	},
	{
		"key": "graceful-hunters",
		"value": "Graceful Hunters"
	},
	{
		"key": "resonance-amplifier",
		"value": "Resonance Amplifier"
	},
	{
		"key": "with-me",
		"value": "With Me!"
	},
	{
		"key": "follow",
		"value": "Follow"
	},
	{
		"key": "second-wind",
		"value": "Second Wind"
	},
	{
		"key": "vanguard",
		"value": "Vanguard"
	},
	{
		"key": "inspired",
		"value": "Inspired"
	},
	{
		"key": "talisman-of-mastery",
		"value": "Talisman of Mastery"
	},
	{
		"key": "desolate",
		"value": "Desolate"
	},
	{
		"key": "liquid-anathema",
		"value": "Liquid Anathema"
	},
	{
		"key": "at-home-in-the-wilderness",
		"value": "At Home in the Wilderness"
	},
	{
		"key": "survivalists",
		"value": "Survivalists"
	},
	{
		"key": "mastery-of-the-bow",
		"value": "Mastery of the Bow"
	},
	{
		"key": "mounted-platform",
		"value": "Mounted Platform"
	},
	{
		"value": "You and What Army?",
		"key": "you-and-what-army-maharajah"
	},
	{
		"value": "Vantage Point",
		"key": "vantage-point"
	},
	{
		"value": "Wave of the Hand",
		"key": "wave-of-the-hand"
	},
	{
		"value": "Point",
		"key": "point"
	},
	{
		"value": "Mahut Rider",
		"key": "mahut-rider"
	},
	{
		"value": "Veteran Officer",
		"key": "veteran-officer"
	},
	{
		"value": "Flesh and Machine",
		"key": "flesh-and-machine"
	},
	{
		"value": "Divine Champions",
		"key": "divine-champions"
	},
	{
		"value": "Automaton",
		"key": "automaton"
	},
	{
		"value": "Children of the Forge",
		"key": "children-of-the-forge"
	},
	{
		"value": "Vigor",
		"key": "vigor"
	},
	{
		"value": "Prostagma",
		"key": "prostagma"
	},
	{
		"value": "Molten Aura",
		"key": "molten-aura"
	},
	{
		"value": "Unstable Alloys",
		"key": "unstable-alloys"
	},
	{
		"value": "Tectonic Slam",
		"key": "tectonic-slam"
	},
	{
		"value": "Crystalline Matrices",
		"key": "crystalline-matrices"
	},
	{
		"value": "Aggression Unleashed",
		"key": "aggression-unleashed"
	},
	{
		"value": "Tempered Aggression",
		"key": "tempered-aggression"
	},
	{
		"value": "Whirlwind Parry",
		"key": "whirlwind-parry"
	},
	{
		"value": "Inner Flame",
		"key": "inner-flame"
	},
	{
		"value": "Beacon of Hope",
		"key": "beacon-of-hope"
	},
	{
		"value": "Dori",
		"key": "dori"
	},
	{
		"value": "Skorge",
		"key": "skorge"
	},
	{
		"value": "Blinding Swing",
		"key": "blinding-swing"
	},
	{
		"value": "Spear",
		"key": "spear"
	},
	{
		"value": "Witch Hunter",
		"key": "witch-hunter"
	},
	{
		"value": "Imbue with the Elements",
		"key": "imbue-with-the-elements"
	},
	{
		"value": "Quatl Master",
		"key": "quatl-master"
	},
	{
		"value": "Wardance",
		"key": "wardance"
	},
	{
		"value": "Battle Trance",
		"key": "battle-trance"
	},
	{
		"value": "Hunt Beacon",
		"key": "hunt-beacon"
	},
	{
		"value": "Overhead Shot",
		"key": "overhead-shot"
	},
	{
		"value": "War-Brethren",
		"key": "war-brethren"
	},
];