import React, { useCallback, useReducer } from "react";
import panel from "../../components/list/panel";


const HorPanelsCtx = React.createContext({
	fixed: [],
	floating: [],
	maxSlots: null,
	onPanelAdd: () => { },
	onPanelRemove: () => { },
	onPanelsClear: () => { },
	onPanelUpdate: () => { },
	onMaxSlotsChange: () => { },
	onActiveSlotsChange: () => { },
});


function sortImportance(a, b) {
	return b.panel.importance - a.panel.importance;
}


function sortOrder(a, b) {
	return a.order - b.order;
}


function sortIndex(a, b) {
	return a.index - b.index;
}


function removePanels(orderedGroups, count, forceRemove = false) {
	const groups = orderedGroups;

	orderedGroups.forEach((g, index) => {
		const filtered = g.filter(p => p.type !== "temporary" || count-- <= 0);
		groups[index] = filtered;
	});

	if (forceRemove) {
		groups.forEach((g, index) => {
			if (count > 0) {
				const mapped = g.map((p, i) => ({ panel: p, index: i }));
				const sorted = mapped.sort(sortImportance);
				const sliced = sorted.slice(0, -count);
				count = Math.max(0, count - g.length);
				const unsorted = sliced.sort(sortIndex);
				const unmapped = unsorted.map(e => e.panel);
				groups[index] = unmapped;
			}
		});
	}

	return groups;
}


function reducer(state, action) {
	const {
		centerPanels,
		leftPanels,
		rightPanels,
		floatingPanels,
		maxSlots,
		activeSlots,
	} = state;
	const maxPanels = Math.min(activeSlots, maxSlots);
	const allPanels = [...centerPanels, ...leftPanels, ...rightPanels];

	if (action.type === "PANEL_ADD") {
		const panel = action.payload;

		// No duplicates allowed.
		if (
			centerPanels.some(p => p.id === panel.id) ||
			leftPanels.some(p => p.id === panel.id) ||
			rightPanels.some(p => p.id === panel.id) ||
			floatingPanels.some(p => p.id === panel.id)
		) {
			return state;
		}

		// Refuses to add a panel with invalid properties.
		if (
			!["permanent", "temporary"].includes(panel.type) ||
			!["center", "left", "right"].includes(panel.position)
		) {
			console.error(
				`Failed to add panel: Unkown type (${panel.type}) or position` +
				`(${panel.position}).`
			);
			return state;
		}

		// Sets missing defaults.
		panel.order ||= 0;
		panel.importance ||= 0;
		if (panel.closable !== true) panel.closable = false;
		if (panel.pinnable !== true) panel.pinnable = false;
		panel.actions ||= [];

		const countPermanent =
			allPanels.filter(p => p.type === "permanent").length;
		const hasSlotAvailable = countPermanent < maxPanels;

		// In case no space is available and none can be made, adds the panel as
		// floating.
		if (!hasSlotAvailable) {
			const floating = [...floatingPanels];
			floating.push(panel);

			return {
				...state,
				floatingPanels: floating,
			};
		} else {
			let center = [];
			let left = [];
			let right = [];

			// Frees slots if necessary for the new panel to fit. The order by which
			// groups have panels removed is defined by the order in the array passed
			// in. The same order is preserved in the output.
			const count = allPanels.length - maxPanels + 1;
			if (panel.position === "center") {
				[center, left, right] =
					removePanels([centerPanels, leftPanels, rightPanels], count);
				center.push(panel);
			} else if (panel.position === "left") {
				[left, right, center] =
					removePanels([leftPanels, rightPanels, centerPanels], count);
				left.push(panel);
			} else if (panel.position === "right") {
				[right, left, center] =
					removePanels([rightPanels, leftPanels, centerPanels], count);
				right.push(panel);
			}

			return {
				...state,
				centerPanels: center,
				leftPanels: left,
				rightPanels: right,
			};
		}
	}

	if (action.type === "PANEL_REMOVE") {
		const id = action.payload;

		const center = centerPanels.filter(p => p.id !== id);
		const left = leftPanels.filter(p => p.id !== id);
		const right = rightPanels.filter(p => p.id !== id);
		const floating = floatingPanels.filter(p => p.id !== id);

		return {
			...state,
			centerPanels: center,
			leftPanels: left,
			rightPanels: right,
			floatingPanels: floating,
		}
	}

	if (action.type === "PANEL_UPDATE") {
		const { id, data } = action.payload;
		const panelGroups =
			[centerPanels, leftPanels, rightPanels, floatingPanels];
		const groups = [];

		for (const g of panelGroups) {
			const index = g.findIndex(p => p.id === id);
			if (index >= 0) {
				groups.push([
					...g.slice(0, index),
					{
						...g[index],
						...data,
					},
					...g.slice(index + 1),
				]);
			} else {
				groups.push(g);
			}
		}

		return {
			...state,
			centerPanels: groups[0],
			leftPanels: groups[1],
			rightPanels: groups[2],
			floatingPanels: groups[3],
		}
	}

	if (action.type === "PANELS_CLEAR") {
		return {
			...state,
			centerPanels: [],
			leftPanels: [],
			rightPanels: [],
			floatingPanels: [],
		}
	}

	if (action.type === "MAX_SLOTS_CHANGE") {
		const count = action.payload;
		const newMaxPanels = Math.min(activeSlots, count);
		const diff = allPanels.length - newMaxPanels;

		if (diff > 0) {
			const [left, right, center] =
				removePanels([leftPanels, rightPanels, centerPanels], diff, true);

			return {
				...state,
				centerPanels: center,
				leftPanels: left,
				rightPanels: right,
				maxSlots: count,
			}
		}

		return {
			...state,
			maxSlots: count,
		}
	}

	if (action.type === "ACTIVE_SLOTS_CHANGE") {
		const count = action.payload;
		const newMaxPanels = Math.min(count, maxSlots);
		const diff = allPanels.length - newMaxPanels;

		if (diff > 0) {
			const [left, right, center] =
				removePanels([leftPanels, rightPanels, centerPanels], diff, true);

			return {
				...state,
				centerPanels: center,
				leftPanels: left,
				rightPanels: right,
				activeSlots: count,
			}
		}

		return {
			...state,
			activeSlots: count,
		}
	}

	return state;
}


export function HorPanelsCtxProvider({ children }) {
	const [state, dispatch] = useReducer(reducer, {
		centerPanels: [],
		leftPanels: [],
		rightPanels: [],
		floatingPanels: [],
		maxSlots: 1,
		activeSlots: 1,
	});

	const fixedPanels = [
		...state.leftPanels.slice().reverse(),
		...state.centerPanels.sort(sortOrder),
		...state.rightPanels,
	];

	const handlePanelAdd = useCallback(panel => {
		dispatch({ type: "PANEL_ADD", payload: panel });
	}, [dispatch]);

	const handlePanelRemove = useCallback(id => {
		dispatch({ type: "PANEL_REMOVE", payload: id });
	}, [dispatch]);

	const handlePanelsClear = useCallback(group => {
		dispatch({ type: "PANELS_CLEAR", payload: group });
	}, [dispatch]);

	const handlePanelUpdate = useCallback((id, data) => {
		dispatch({ type: "PANEL_UPDATE", payload: { id, data } });
	}, [dispatch]);

	const handleMaxSlotsChange = useCallback(count => {
		dispatch({ type: "MAX_SLOTS_CHANGE", payload: count });
	}, [dispatch]);

	const handleActiveSlotsChange = useCallback(count => {
		dispatch({ type: "ACTIVE_SLOTS_CHANGE", payload: count });
	}, [dispatch]);

	return (
		<HorPanelsCtx.Provider
			value={{
				fixed: fixedPanels,
				floating: state.floatingPanels,
				maxSlots: state.maxSlots,
				onPanelAdd: handlePanelAdd,
				onPanelRemove: handlePanelRemove,
				onPanelsClear: handlePanelsClear,
				onPanelUpdate: handlePanelUpdate,
				onMaxSlotsChange: handleMaxSlotsChange,
				onActiveSlotsChange: handleActiveSlotsChange,
			}}
		>
			{children}
		</HorPanelsCtx.Provider >
	)
}


export default HorPanelsCtx;