import { Fab, Typography } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useDispatch } from "react-redux";
import { createNode } from "../../../lib/list";
import { attachNode } from "../../../store/list-slice";


function BestOfTheRajakurButton() {
	const dispatch = useDispatch();

	function handleClick() {
		const node = createNode({
			type: "character",
			entrySlug: "sardar"
		})
		dispatch(attachNode({ path: "", node }));
	}

	return (
		<Fab
			color="primary"
			variant="extended"
			sx={{ alignSelf: "center" }}
			size="large"
			onClick={handleClick}
		>
			<AddCircleIcon sx={{ mr: 1 }} />
			<Typography variant="h4">Best of the Rajakur</Typography>
		</Fab>
	);
}


export default BestOfTheRajakurButton;