import { IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useParams } from "react-router-dom";
import FactionFiltersIcon from "./FactionFiltersIcon";
import FactionQueriesIcon from "./FactionQueriesIcon";
import FrameHeader from "../../ui/containers/FrameHeader";
import useGameSettings from "../../hooks/use-game-settings";


function FactionPanelHeader(props) {
	const { filters, onFiltersReset, onFiltersApply, faction } = props;
	const { name, slug, queries = [] } = faction;
	const { getFactionSettings } = useGameSettings();
	const { icon } = getFactionSettings(slug);

	return (
		<FrameHeader>
			<Stack direction="row" spacing={1} >
				<SvgIcon
					component={icon}
					sx={{
						fontSize: "70px",
						m: "auto"
					}}
				/>
				<Stack flex="1 1 auto" justifyContent="space-between">
					<Typography variant="h4">{name}</Typography>
					<Stack direction="row" justifyContent="space-between">
						<div>
							<IconButton
								variant="light"
								component={Link}
								to="../"
							>
								<ArrowBackIcon />
							</IconButton>
							<FactionFiltersIcon
								filters={filters}
								onFiltersReset={onFiltersReset}
								onFiltersApply={onFiltersApply}
								variant="light"
							/>
						</div>
						<div>
							<FactionQueriesIcon
								queries={queries}
								variant="light"
							/>
						</div>
					</Stack>
				</Stack>
			</Stack >
		</FrameHeader>
	);
}


export default FactionPanelHeader;