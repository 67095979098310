import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Warband from "./Warband";

import FramePanelContent from "../../../ui/containers/FramePanelContent";
import NewWarbandButton from "./NewWarbandButton";


function ListPanelContent({ faction, parsedList }) {
	const { children: warbands } = parsedList;
	const countWarbands = warbands.length;

	return (
		<FramePanelContent>
			<Stack spacing={5}>
				{warbands.map(w => <Warband key={w.id} warbandNode={w} faction={faction} />)}
				<NewWarbandButton faction={faction} countWarbands={countWarbands} />
			</Stack>
		</FramePanelContent>
	);
}


export default ListPanelContent;