import { Box } from "@mui/system";
import KeyboardDoubleArrowUpIcon
	from '@mui/icons-material/KeyboardDoubleArrowUp';
import { Typography } from "@mui/material";
import { keyframes } from "@emotion/react";


const moveUp = keyframes`
	from {bottom: 0},
	to {bottom: 20px}
`;


function StartHere() {
	return (
		<>
			<Box position="relative" height="100px">
				<KeyboardDoubleArrowUpIcon
					sx={{
						fontSize: "80px",
						position: "absolute",
						left: "50%",
						bottom: 0,
						transform: "translateX(-50%)",
						animation: `${moveUp} 1.1s infinite`,
					}}
				/>
			</Box>
			<Typography variant="h2" align="center">Start Here</Typography>
		</>
	);
}


export default StartHere;