import { Button } from "@mui/material";
import React, {
	useCallback,
	useContext,
	useReducer,
	useState,
	useEffect,
} from "react";
import FactionsList from "../components/faction/FactionsList";
import ListSettings from "../components/list/ListSettings";
import ListSetupDialog from "../components/list/ListSetupDialog";
import checkListFB, { calculateList } from "../lib/check-list-fb.js.to-delete";
import FrameDialog from "../ui/containers/FrameDialog";
import FloatingActions from "../ui/FloatingActions";
import FrameDialogContent from "../ui/containers/FrameDialogContent";
import FrameHeader from "../ui/containers/FrameHeader";
import GameCtx from "./GameCtx";
import listGeneral from "../lib/rules-to-delete/fb/list-general";
import checkListTLAOK from "../lib/check-list-tlaok.js.to-delete";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import UserCtx from "./UserCtx";
import { useSnackbar } from "notistack";
import SavedListsDialog from "../ui/SavedListsDialog";

const ListCtx = React.createContext({
	list: null,
	lists: null,
	listChanged: false,
	onSavedListsOpen: () => { },
	onListsReload: () => { },
	onListNew: () => { },
	onListUpdate: () => { },
	onListSave: () => { },
	onListLoad: () => { },
	onWarbandNew: () => { },
	onWarbandDuplicate: () => { },
	onWarbandUpdate: () => { },
	onWarbandDelete: () => { },
	onCharacterNew: () => { },
	onCharacterUpdate: () => { },
	onSecondCharacterUpdate: () => { },
	onRegimentNew: () => { },
	onRegimentUpdate: () => { },
});

function isListEmpty(list) {
	const { character, warbands, regiments } = list;
	if (
		!character &&
		(!warbands || warbands.length === 0) &&
		(!regiments || regiments.length === 0)
	) {
		return true;
	} else {
		return false;
	}
}

function listReducer(prevState, action) {
	const { type, data } = action;
	let list = prevState.list;
	let lists = prevState.lists;
	let listChanged = true;

	if (type === "LIST_NEW") {
		list = {
			data,
			id: uuidv4(),
			settings: {
				name: null,
				points: null,
			},
			warbands: [],
			regiments: [],
			retinues: [],
			optionGroups: [],
			rules: [listGeneral],
			alerts: [],
		};
		listChanged = false;
	}

	if (type === "LISTS_LOAD") {
		lists = data.lists;
		listChanged = false;
	}

	if (type === "LIST_UPDATE") {
		const { updateData, checkList } = data;
		list = {
			...prevState.list,
			...updateData,
		};

		checkList(list);
		if (isListEmpty(list)) listChanged = false;
	}

	if (type === "LIST_SAVE") {
		listChanged = false;
	}

	if (type === "LIST_LOAD") {
		const { loadedData, checkList } = data;
		list = {
			warbands: [],
			regiments: [],
			retinues: [],
			optionGroups: [],
			rules: [listGeneral],
			alerts: [],
			...loadedData,
		};

		for (const w of list.warbands) {
			if (!w.regiments) w.regiments = [];
		}

		checkList(list);
		listChanged = false;
	}

	if (type === "WARBAND_NEW") {
		const { character, checkList } = data;
		const warband = {
			id: Date.now(),
			name: `Warband ${prevState.list.warbands.length + 1}`,
			character: {
				...character,
				id: Date.now(),
			},
			regiments: [],
		};

		list = {
			...prevState.list,
			warbands: [...prevState.list.warbands, warband],
		};

		checkList(list);
	}

	if (type === "WARBAND_DUPLICATE") {
		const { warband, checkList } = data;
		const warbandCopy = JSON.parse(JSON.stringify(warband));
		warbandCopy.id = Date.now();
		warbandCopy.name = `Warband ${prevState.list.warbands.length + 1}`;

		warbandCopy.character.id = Date.now();
		warbandCopy.regiments.forEach((v, i) => (v.id = Date.now() - i - 1));

		list = {
			...prevState.list,
			warbands: [...prevState.list.warbands, warbandCopy],
		};

		checkList(list);
	}

	if (type === "WARBAND_DELETE") {
		const { id, checkList } = data;
		const index = prevState.list.warbands.findIndex((w) => w.id === id);

		if (index !== -1) {
			list = {
				...prevState.list,
				warbands: [
					...prevState.list.warbands.slice(0, index),
					...prevState.list.warbands.slice(index + 1),
				],
			};

			checkList(list);
		}
	}

	if (type === "WARBAND_UPDATE") {
		const { id, updateData, checkList } = data;
		const index = prevState.list.warbands.findIndex((w) => w.id === id);

		if (index !== -1) {
			list = {
				...prevState.list,
				warbands: [
					...prevState.list.warbands.slice(0, index),
					{
						...prevState.list.warbands[index],
						...updateData,
					},
					...prevState.list.warbands.slice(index + 1),
				],
			};

			checkList(list);
		}
	}

	if (type === "CHARACTER_NEW") {
		const { character, order, checkList } = data;

		if (character) character.id = Date.now();

		list =
			order === "second"
				? {
					...prevState.list,
					secondCharacter: character,
				}
				: {
					...prevState.list,
					character,
					retinues: [],
				};

		checkList(list);
	}

	if (type === "CHARACTER_UPDATE") {
		const { character, checkList } = data;

		list = {
			...prevState.list,
			character,
		};

		checkList(list);
	}

	if (type === "SECOND_CHARACTER_UPDATE") {
		const { character, checkList } = data;

		list = {
			...prevState.list,
			secondCharacter: character,
		};

		checkList(list);
	}

	if (type === "REGIMENT_NEW") {
		const { regiment, checkList } = data;

		list = {
			...prevState.list,
			regiments: [
				...prevState.list.regiments,
				{
					...regiment,
					id: Date.now(),
				},
			],
		};

		checkList(list);
	}

	if (type === "REGIMENT_UPDATE") {
		const { id, updateData, checkList } = data;
		const index = prevState.list.regiments.findIndex((r) => r.id === id);

		if (index !== -1) {
			list = {
				...prevState.list,
				regiments: [
					...prevState.list.regiments.slice(0, index),
					{
						...prevState.list.regiments[index],
						...updateData,
					},
					...prevState.list.regiments.slice(index + 1),
				],
			};

			checkList(list);
		}
	}

	return { list, listChanged, lists };
}

export function ListCtxProvider({ children }) {
	// const [list, setList] = useState({});
	// const [listData, setListData] = useState({});
	const [open, setOpen] = useState(false);
	const { currentGameSlug } = useContext(GameCtx);
	const { currentUser } = useContext(UserCtx);
	const { enqueueSnackbar } = useSnackbar();
	const [listsOpen, setListsOpen] = useState(false);

	const [listState, dispatch] = useReducer(listReducer, {
		list: null,
		listChanged: false,
		lists: null,
	});

	useEffect(() => {
		async function getLists() {
			const url = "https://armybuilder-6c9d2-default-rtdb.firebaseio.com";
			try {
				const response = await axios.get(
					`${url}/lists/${currentUser.username}/${currentGameSlug}.json`
				);
				dispatch({ type: "LISTS_LOAD", data: { lists: response.data } });
				// setLists(response.data);
			} catch (error) {
				console.error(error);
			}
		}
		if (listsOpen && currentUser) {
			getLists();
		}
	}, [listsOpen, currentUser]);

	function handleSavedListsClose() {
		setListsOpen(false);
	}

	function handleSavedListsOpen() {
		setListsOpen(true);
	}

	async function handleListsReload() {
		const url = "https://armybuilder-6c9d2-default-rtdb.firebaseio.com";
		try {
			const response = await axios.get(
				`${url}/lists/${currentUser.username}/${currentGameSlug}.json`
			);
			dispatch({ type: "LISTS_LOAD", data: { lists: response.data } });
			// setLists(response.data);
		} catch (error) {
			console.error(error);
		}
	}

	// const checkList =
	// currentGameSlug?.slug === "tlaok" ? checkListTLAOK : checkListFB;

	const checkList = () => { };

	function handleListNew(gameSlug) {
		dispatch({ type: "LIST_NEW", data: gameSlug });
		setOpen(true);
	}

	function handleListUpdate(updateData) {
		dispatch({ type: "LIST_UPDATE", data: { updateData, checkList } });
	}

	function handleListLoad(loadedData) {
		dispatch({ type: "LIST_LOAD", data: { loadedData, checkList } });
	}

	function handleNewWarband(character) {
		dispatch({ type: "WARBAND_NEW", data: { character, checkList } });
	}

	function handleWarbandDuplicate(warband) {
		dispatch({ type: "WARBAND_DUPLICATE", data: { warband, checkList } });
	}

	function handleWarbandDelete(id) {
		dispatch({ type: "WARBAND_DELETE", data: { id, checkList } });
	}

	function handleWarbandUpdate(id, updateData) {
		dispatch({ type: "WARBAND_UPDATE", data: { id, updateData, checkList } });
	}

	function handleCharacterNew(character, order) {
		dispatch({ type: "CHARACTER_NEW", data: { character, order, checkList } });
	}

	function handleCharacterUpdate(character) {
		dispatch({ type: "CHARACTER_UPDATE", data: { character, checkList } });
	}

	function handleSecondCharacterUpdate(character) {
		dispatch({
			type: "SECOND_CHARACTER_UPDATE",
			data: { character, checkList },
		});
	}

	function handleRegimentNew(regiment) {
		dispatch({ type: "REGIMENT_NEW", data: { regiment, checkList } });
	}

	function handleRegimentUpdate(id, updateData) {
		dispatch({ type: "REGIMENT_UPDATE", data: { id, updateData, checkList } });
	}

	function handleClose() {
		setOpen(false);
	}

	function handleSettingsChange(settings) {
		// setList(prevList => ({ ...prevList, settings }));
		// setListData(calculateList(list, currentFaction));
	}

	async function handleListSave() {
		const url = "https://armybuilder-6c9d2-default-rtdb.firebaseio.com/lists";
		try {
			const response = await axios.put(
				`${url}/${currentUser.username}/${listState.list.gameSlug}/${listState.list.id}.json`,
				{
					...listState.list,
				}
			);
			dispatch({ type: "LIST_SAVE" });
			enqueueSnackbar("List saved.", { variant: "success" });
		} catch (error) {
			enqueueSnackbar("Failed to save the list.", { variant: "error" });
			console.error(error);
		}
	}

	return (
		<ListCtx.Provider
			value={{
				list: listState.list,
				lists: listState.lists,
				listChanged: listState.listChanged,
				onListNew: handleListNew,
				onListUpdate: handleListUpdate,
				onListSave: handleListSave,
				onListLoad: handleListLoad,
				onWarbandNew: handleNewWarband,
				onWarbandDuplicate: handleWarbandDuplicate,
				onWarbandUpdate: handleWarbandUpdate,
				onWarbandDelete: handleWarbandDelete,
				onCharacterNew: handleCharacterNew,
				onCharacterUpdate: handleCharacterUpdate,
				onSecondCharacterUpdate: handleSecondCharacterUpdate,
				onRegimentNew: handleRegimentNew,
				onRegimentUpdate: handleRegimentUpdate,
				onSavedListsOpen: handleSavedListsOpen,
				onListsReload: handleListsReload,
			}}
		>
			{children}
			{/* <ListSetupDialog open={open} onClose={handleClose} /> */}
			{/* <SavedListsDialog open={listsOpen} onClose={handleSavedListsClose} /> */}
		</ListCtx.Provider>
	);
}

export default ListCtx;
