import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/system';
import useQuery from '../../hooks/use-query';


const Icon = styled(VisibilityIcon)({
	color: "currentColor",
	opacity: 0.3,
	cursor: "pointer",
	"&:hover": {
		opacity: 0.4,
	},
});


function QueryIcon({ query, ...iconProps }) {
	const sendQuery = useQuery();

	function handleClick() {
		sendQuery(query);
	}

	return <Icon onClick={handleClick} {...iconProps} />
}


export default QueryIcon;