import { useEffect } from "react";
import { useParams, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentGame } from "../store/games-slice";
import QueryDialog from "../components/query/QueryDialog";
import ScannerDialog from "../components/ScannerDialog";
import ListSetupDialog from "../components/list/ListSetupDialog";
import SavedListsDialog from "../ui/SavedListsDialog";


function Game() {
	const { gameSlug } = useParams();
	const {
		availableGames,
		currentGameSlug
	} = useSelector((state) => state.games);
	const dispatch = useDispatch();
	const isGameAvailable = !!availableGames.find((g) => g.slug === gameSlug);

	useEffect(() => {
		if (gameSlug !== currentGameSlug && isGameAvailable) {
			dispatch(setCurrentGame(gameSlug));
		}
	}, [gameSlug, currentGameSlug, isGameAvailable, dispatch]);

	if (!isGameAvailable) {
		throw {
			message:
				"Game is not supported. Please check that the URL is correct.",
		};
	}

	// Waits for state to be set.
	if (!currentGameSlug) return;

	return (
		<>
			<Outlet />
			<QueryDialog />
			<ScannerDialog />
			<ListSetupDialog />
			<SavedListsDialog />
		</>
	);
}


export default Game;