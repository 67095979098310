import { createSlice } from "@reduxjs/toolkit";
import { getNode } from "../lib/list";


const listSlice = createSlice({
	name: "list",
	initialState: {},
	reducers: {
		newList(state, { payload: rootNode }) {
			return {
				...rootNode,
				saved: false,
			};
		},
		attachNode(state, { payload }) {
			const { path, node } = payload;
			const parent = getNode(state, path);
			if (parent) {
				parent.children.push(node);
				localStorage.setItem("unsaved-list", JSON.stringify(state));
				state.saved = false;
			} else {
				console.error(`Failed to add list node: Unknown path ${path}`);
			}
		},
		removeNode(state, { payload }) {
			const { parentPath, nodeId } = payload;
			const parent = getNode(state, parentPath);
			if (parent) {
				const index = parent.children.findIndex(c => c.id === nodeId);
				if (index !== -1) {
					// TODO: Linked nodes should be reworked. This is a temp solution to
					// cover existing cases. It may be worth creating a different struct
					// to hold just the links.
					const node = parent.children[index];
					if (node.linkedFrom) {
						const values = Object.values(node.linkedFrom);
						const linkedNode = getNode(state, values[0]);
						if (linkedNode) {
							linkedNode.linkedNodes = {};
						}
					}
					parent.children.splice(index, 1);
					state.saved = false;
					localStorage.setItem("unsaved-list", JSON.stringify(state));
				}
			}
		},
		// duplicateNode(state, { payload }) {
		// 	const { parentPath, nodeId, override } = payload;
		// 	const parent = getNode(state, parentPath);
		// 	if (parent) {
		// 		const node = parent.children.find(c => c.id === nodeId);
		// 		if (node) {
		// 			const clone = {
		// 				...JSON.parse(JSON.stringify(node)),
		// 				...override,
		// 			}
		// 			parent.children.push(clone);
		// 		}
		// 	}

		// 	// const lastDivider = path.lastIndexOf("/");
		// 	// const parentPath = path.substring(0, lastDivider);
		// 	// const nodeId = path.substring(0, lastDivider);
		// 	// const parent = getNode(state, parentPath);
		// 	// const node = parent.children.find(c => );
		// 	// parent.children.push(JSON.parse(JSON.stringify(node)));
		// },
		updateData(state, { payload }) {
			const { path, data } = payload;
			const node = getNode(state, path);

			if (node) {
				Object.keys(data).forEach(k => node[k] = data[k]);
				state.saved = false;
				localStorage.setItem("unsaved-list", JSON.stringify(state));
			}
		},
		updateOptions(state, { payload }) {
			const { path, add = [], remove = [] } = payload;
			const node = getNode(state, path);

			if (node) {
				const filtered = node.selections.filter(s => !remove.includes(s));
				node.selections = filtered.concat(add);
				state.saved = false;
				localStorage.setItem("unsaved-list", JSON.stringify(state));
			}
		},
		updateSaved(state, { payload: status }) {
			state.saved = status;
		},
		updateLinkedNodes(state, { payload }) {
			const { path, selectorSlug, nodePaths } = payload;
			const node = getNode(state, path);
			if (node) {
				node.linkedNodes[selectorSlug] = nodePaths;

				nodePaths.forEach(p => {
					const linkedNode = getNode(state, p);
					if (linkedNode) {
						linkedNode.linkedFrom ||= {};
						linkedNode.linkedFrom[selectorSlug] = path;
					}
				});

				state.saved = false;
				localStorage.setItem("unsaved-list", JSON.stringify(state));
			}
		},
		cleanupList(state, { payload: cleanup }) {
			cleanup.forEach(c => {
				const { path, optionSlugs, selectorSlugs, remove } = c;
				const node = getNode(state, path);
				if (node) {
					if (remove) {
						const parent = getNode(state, path.substring(0, path.lastIndexOf("/")));
						parent.children = parent.children.filter(child => child.id !== node.id);
					}
					else {
						node.selections = node.selections.filter(s => !optionSlugs.includes(s));
						node.linkedNodes = Object.keys(node.linkedNodes)
							.filter(key => !selectorSlugs.includes(key))
							.reduce((obj, key) => {
								obj[key] = node.linkedNodes[key];
								return obj;
							}, {});
					}
				}
			});
		},
	},
});


export const {
	newList,
	attachNode,
	removeNode,
	updateOptions,
	updateData,
	updateSaved,
	updateLinkedNodes,
	cleanupList
} = listSlice.actions;

export default listSlice.reducer;