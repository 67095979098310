import { factionMapping, FB, TLAOK } from "../data/games-settings";
import axios from "axios";
import appSettings from "../app-settings";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// export async function getGame(slug) {
// 	// await delay(1000);
// 	return slug === "tlaok" ? TLAOK : FB;
// }

export async function getFaction(gameSlug, factionSlug) {
	try {
		const { data } = await axios.get(
			`${appSettings.backendUrl}/${gameSlug}/factions/${factionSlug}.json`
		);
		if (!data?.slug) {
			throw { message: "Server returned invalid game." };
		}
		return data;
	} catch (error) {
		throw {
			message: `Failed to fetch ${factionSlug}.`,
			details: error.message,
		};
	}
}


export async function getQuery(request) {
	const { gameSlug, type, slug, factionSlug } = request;
	const url = type === "entries" ?
		`${appSettings.backendUrl}/${gameSlug}/factions/${factionSlug}/entries/${slug}.json` :
		`${appSettings.backendUrl}/${gameSlug}/${type}/${slug}.json`;

	try {
		const response = await axios.get(url);
		return response.data;
	} catch (error) {
		throw { message: `Failed to fetch ${slug}.`, details: error.message };
	}
}


export async function getGames() {
	try {
		const { data } = await axios.get(`${appSettings.backendUrl}games.json`);
		if (!data?.length) {
			throw { message: "Server returned no games." };
		}
		return data;
	} catch (error) {
		throw { message: "Failed to fetch games.", details: error.message };
	}
}


export async function getList(listId) {
	try {
		const { data: remoteList } = await axios.get(
			`${appSettings.backendUrl}/lists/${listId}.json`
		);
		const localData = localStorage.getItem("unsaved-list");
		const localDataObj = localData ? JSON.parse(localData) : null;
		const localList = localDataObj?.id === listId ? localDataObj : null;
		if (!remoteList && !localList) {
			throw { message: "List does not exist." };
		}
		const gameSlug = remoteList?.gameSlug || localList?.gameSlug;
		const factionSlug = remoteList?.factionSlug || localList?.factionSlug;
		const { data: faction } = await axios.get(
			`${appSettings.backendUrl}/${gameSlug}/factions/${factionSlug}.json`
		);
		if (!faction?.slug) {
			throw { message: "Invalid game." };
		}
		return { remoteList, localList, faction };
	} catch (error) {
		throw { message: "Failed to fetch list.", details: error.message };
	}
}


export async function getPdf(filename, data) {
	fetch("https://oldab.para-bellum.com/export", {
		// fetch("http://192.168.1.9:3001/export", {
		body: JSON.stringify(data, (key, value) => {
			if (["parent", "entrySelectors"].includes(key)) {
				return undefined;
				// } else if (value === "tlaok2") {
				// 	return "tlaok";
			} else {
				return value;
			}
		}),
		method: 'POST',
		headers: {
			'Content-Type': 'application/json; charset=utf-8'
		},
	})
		.then(response => response.blob())
		.then(response => {
			const blob = new Blob([response], { type: 'application/pdf' });
			const downloadUrl = URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = downloadUrl;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
		})
}