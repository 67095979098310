import { styled, Box } from "@mui/system";
import useGameSettings from "../../hooks/use-game-settings";


const EntryCount = styled(Box)(({ theme, count }) => {
	const { getGameSettings } = useGameSettings();
	const { entryCountIcon } = getGameSettings();

	return {
		position: "relative",
		width: "1.4rem",
		minWidth: "1.4rem",
		height: "1.4rem",
		minHeight: "1.4rem",
		background: `url(${entryCountIcon}) center/contain no-repeat`,
		"&:after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			color: theme.palette.common.white,
			content: `"${count}"`,
			textAlign: "center",
			lineHeight: "normal",
		}
	}
});


export default EntryCount;