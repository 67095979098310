import { Box, styled } from '@mui/system';
import frame1 from "../../assets/decorative/frame-1.svg";
import frame2 from "../../assets/decorative/frame-2.svg";
import { shadow } from '../../themes/mixins';


const FRAMES = {
	"frame1": frame1,
	"frame2": frame2,
}


const FrameBox = styled(Box)(({ theme, responsive, frameStyle }) => ({
	position: "relative",
	[theme.breakpoints.up(responsive ? "sm" : "xs")]: {
		border: "solid transparent 5px",
		backgroundClip: "padding-box",
		"&:after": {
			content: "''",
			position: "absolute",
			top: "-5px",
			left: "-5px",
			width: "calc(100% + 10px)",
			height: "calc(100% + 10px)",
			borderImage: `url(${FRAMES[frameStyle]}) 20% round`,
			borderWidth: "65px",
			borderStyle: "solid",
			pointerEvents: "none",
			...shadow,
		}
	}
}));


export default FrameBox;