export const QR_DIRECTIVES = {
	"tlaok": {
		"DIRck1ef": { "slug": "call-fog" },
		"DIRq0rzs": { "slug": "divine-sanction" },
		"DIRj7ei8": { "slug": "earth-to-mud" },
		"DIR831zz": { "slug": "fervor" },
		"DIR6dz6o": { "slug": "fire-dart" },
		"DIRic5fn": { "slug": "guide" },
		"DIRd6bf0": { "slug": "heavenly-blessing" },
		"DIRnqn2f": { "slug": "holy-fire" },
		"DIRq21g1": { "slug": "kindle-courage" },
		"DIRjza2v": { "slug": "ninuah-s-tears" },
		"DIRpa5pg": { "slug": "saint-s-favor" },
		"DIRjwe63": { "slug": "seeking-winds" },
		"DIR17q9u": { "slug": "stone-spikes" },
		"DIRa8rqq": { "slug": "accelerated-hibernation" },
		"DIRbkyv1": { "slug": "catalytic-rupture" },
		"DIRyd7k7": { "slug": "essence-transfer" },
		"DIRxkg9u": { "slug": "grant-virulence" },
		"DIRtwmd4": { "slug": "harvest-essence" },
		"DIRm1id8": { "slug": "induced-vigor" },
		"DIRjptu1": { "slug": "pheromantic-compulsion" },
		"DIRkyi7s": { "slug": "pheromantic-drive" },
		"DIR7yseb": { "slug": "siphon-strength" },
		"DIRkds8g": { "slug": "unstable-enhancement" },
		"DIRabnp7": { "slug": "broken-ground" },
		"DIRm3r6m": { "slug": "coruscation" },
		"DIRswvhg": { "slug": "dismay" },
		"DIRe1l7h": { "slug": "eruption" },
		"DIRw5a4r": { "slug": "fireball" },
		"DIRpuitz": { "slug": "flame-wall" },
		"DIRoo95j": { "slug": "hone-blades" },
		"DIR36ifi": { "slug": "magmatic-seep" },
		"DIRwknhj": { "slug": "pyroclast" },
		"DIR3gebc": { "slug": "resolve" },
		"DIRxlql9": { "slug": "rock-shaping" },
		"DIRn3z0e": { "slug": "roots-of-stone" },
		"DIR910p6": { "slug": "temper-plate" },
		"DIR7qtu7": { "slug": "unmake-armor" },
		"DIRs6awm": { "slug": "aspect-of-fenrir" },
		"DIRtff8n": { "slug": "blurred-vision" },
		"DIRvwgbv": { "slug": "dominate" },
		"DIRlvs4l": { "slug": "encase" },
		"DIRoua3i": { "slug": "enrage" },
		"DIRxqygl": { "slug": "fruit-of-the-golden-tree" },
		"DIRpqit6": { "slug": "glory-of-valhalla" },
		"DIR61ikz": { "slug": "hela-s-caress" },
		"DIRoewyw": { "slug": "ice-armor" },
		"DIRbkn7t": { "slug": "mist-weave" },
		"DIRdq87h": { "slug": "reshape-destiny" },
		"DIRdxrg1": { "slug": "rime-storm" },
		"DIRm98kw": { "slug": "warbringer-s-light" },
		"DIRug13v": { "slug": "blackflame-coruscation" },
		"DIR8drk8": { "slug": "blasphemous-power" },
		"DIRptehi": { "slug": "dark-immolation" },
		"DIRlx3bk": { "slug": "dark-supplication" },
		"DIRr4vig": { "slug": "drain-will" },
		"DIReljwi": { "slug": "hazlia-s-touch" },
		"DIR72wby": { "slug": "immolation" },
		"DIRcwept": { "slug": "insanity" },
		"DIRn9cbn": { "slug": "translocation" },
		"DIRyfwbt": { "slug": "unholy-baptism" },
		"DIRkwvow": { "slug": "cacophony" },
		"DIR315tv": { "slug": "crescendo" },
		"DIRosyib": { "slug": "dissonance" },
		"DIRu65kb": { "slug": "crucible-s-fire" },
		"DIRj3l8m": { "slug": "molon-labe" },
		"DIRwg7dx": { "slug": "quench-blades" },
		"DIRu1e08": { "slug": "clockwork-parade" },
		"DIRsfrb1": { "slug": "aggression-directive" },
		"DIR8p83t": { "slug": "temper-resolve" },
		"DIR7xpkk": { "slug": "trident-strike" },
		"DIRl98fd": { "slug": "iron-stride" },
		"DIRq6yth": { "slug": "othismos" },
		"DIRe8ib9": { "slug": "torque" },
		"DIRpd5we": { "slug": "rancor" },
		"DIRjvd4e": { "slug": "fear" },
		"DIRj87ew": { "slug": "hoarfrost" },
		"DIRdmm09": { "slug": "undying-devotion" },
		"DIRh9mdl": { "slug": "benediction-of-the-black-sun" },
		"DIR51l0g": { "slug": "minor-elemental-missile" },
		"DIRmpdcv": { "slug": "adrenal-surge" },
		"DIRxbt8w": { "slug": "subdermal-keratin-induction" },
		"DIRo8i3r": { "slug": "discordance" },
		"DIRx8qa4": { "slug": "resonance" },
		"DIR97lo8": { "slug": "conflagration" },
		"DIRxnnt3": { "slug": "fiery-dominion" },
		"DIRddnz5": { "slug": "intrusive-thoughts" },
		"DIRp3oya": { "slug": "spiteful-winds" },
		"DIRpu489": { "slug": "incite-rage" },
		"DIR60iu9": { "slug": "sayf" },
		"DIR6wbec": { "slug": "far-sight" },
		"DIRpq8h8": { "slug": "entropic-shield" },
		"DIRyljih": { "slug": "laser-shark" },
		"DIRah5ts": { "slug": "burn-to-cinders" },
		"DIR06g24": { "slug": "scorching-scirocco" },
		"DIRrvy5g": { "slug": "wreathed-in-flames" },
		"DIR2eahl": { "slug": "lifting-winds" },
		"DIRvj9sz": { "slug": "lightning-bolt" },
		"DIRzudav": { "slug": "homing-winds" },
		"DIR73bjx": { "slug": "cauterize" },
		"DIR7bk66": { "slug": "ignite" },
		"DIRwqzua": { "slug": "searing-sandstorm" },
		"DIRspxxv": { "slug": "air-step" },
		"DIR3rbxf": { "slug": "tailwind" },
		"DIR7nuu1": { "slug": "molten-blades" },
		"DIR1gwy4": { "slug": "wreathed-in-fire" },
		"DIR82mj0": { "slug": "storm-s-wrath" },
		"DIRnqaqm": { "slug": "wind-kissed-blades" },
		"DIRi28d0": { "slug": "awakening" },
		"DIRsk71i": { "slug": "invoke-the-bound" },
	},
	"fb": {
		"DIR6lq89": { "slug": "a-fiery-end" },
		"DIRy8yzr": { "slug": "coruscation" },
		"DIRkmyk3": { "slug": "earth-s-grasp" },
		"DIRqkho2": { "slug": "ferric-expansion" },
		"DIRzjfef": { "slug": "fuelled-by-the-furnace" },
		"DIRz2gm1": { "slug": "living-metal" },
		"DIRcc4kf": { "slug": "moaghm-dorh" },
		"DIRk7uom": { "slug": "oxidize" },
		"DIRgrged": { "slug": "temper-blades" },
		"DIRg4xi7": { "slug": "blinding-light" },
		"DIR8vy3g": { "slug": "brine-erosion" },
		"DIRotles": { "slug": "encase" },
		"DIRm1li9": { "slug": "glory-to-valhalla" },
		"DIRf0oiy": { "slug": "ice-armor" },
		"DIRu8fkq": { "slug": "mist-weave" },
		"DIRvf36i": { "slug": "prove-yourself-worthy" },
		"DIR5lwol": { "slug": "reshape-destiny" },
		"DIRu39qp": { "slug": "rime-storm" },
		"DIRr53fj": { "slug": "spirit-of-fenrir" },
		"DIRskk2l": { "slug": "valhalla-s-caress" },
		"DIRuonwg": { "slug": "ashes-to-ashes" },
		"DIRjv644": { "slug": "aura-of-malice" },
		"DIRpn2sj": { "slug": "blasphemous-miasma" },
		"DIR5ck11": { "slug": "blasphemous-prayer" },
		"DIRts4r7": { "slug": "duty-bound" },
		"DIRu34g6": { "slug": "glimmers-of-a-golden-age" },
		"DIR19eb4": { "slug": "madness" },
		"DIR3rgsc": { "slug": "nekrosis" },
		"DIRhjhxy": { "slug": "past-echoes" },
		"DIRdy0tz": { "slug": "pyrosis" },
		"DIR99l3q": { "slug": "resonating-essence" },
		"DIRnm9fo": { "slug": "unholy-visage" },
		"DIRfohpc": { "slug": "deafen" },
		"DIR6srbr": { "slug": "disruption" },
		"DIR35g72": { "slug": "embolden" },
		"DIRt5fcq": { "slug": "aggressive-compulsion" },
		"DIRvrk99": { "slug": "amplified-biogenics" },
		"DIR33ohv": { "slug": "catalytic-strength" },
		"DIRui1w7": { "slug": "entropic-transfer" },
		"DIR7cg7p": { "slug": "establish-perimeter" },
		"DIRa51mh": { "slug": "heightened-senses" },
		"DIRzfegp": { "slug": "mend-flesh" },
		"DIR6lf3g": { "slug": "pack-insticts" },
		"DIRz2lgo": { "slug": "pheromone-burst" },
		"DIR4p5a4": { "slug": "rapid-revitalization" },
		"DIRyxpm1": { "slug": "recuperate" },
		"DIRhd8dh": { "slug": "territorial-instincts" },
		"DIR28ktd": { "slug": "virulence" },
		"DIR0gzwf": { "slug": "aetheric-interference" },
		"DIR6xjnv": { "slug": "flame-burst" },
		"DIRt6m9z": { "slug": "ninuah-s-caress" },
		"DIR281q5": { "slug": "roiling-earth" },
		"DIR6rg7c": { "slug": "burst-of-faith" },
		"DIRgrv70": { "slug": "divine-purpose" },
		"DIR5yu9v": { "slug": "fortitude-of-faith" },
		"DIRyghfi": { "slug": "smoldering-in-ashes" },
		"DIR1edtk": { "slug": "piercing-heat" },
		"DIRiw5gr": { "slug": "damping-force" },
		"DIR7e5nk": { "slug": "resist" },
		"DIR7exso": { "slug": "magnetic-balance" },
		"DIRtdlsy": { "slug": "shock-and-steel" },
		"DIR9ty8c": { "slug": "structural-rearrangement" },
		"DIR8z8er": { "slug": "synchronized-formation" },
		"DIR429ic": { "slug": "mobility" },
		"DIR6efog": { "slug": "liquid-fire" },
		"DIRyljih": { "slug": "laser-shark" },
		"DIRpnwpd": { "slug": "lords-of-the-inferno" },
		"DIRmql49": { "slug": "rebirth-of-the-phoenix" },
		"DIRrn678": { "slug": "gift-of-lightning" },
		"DIRb0zlx": { "slug": "gift-of-thunder" },
		"DIRlogis": { "slug": "children-of-ash-and-cinders" },
		"DIRksw4b": { "slug": "blazing-sign" },
		"DIRiilg2": { "slug": "children-of-storm-and-spray" },
		"DIRu70zh": { "slug": "fulgent-sign" },
		"DIRrdlmm": { "slug": "strength-of-fire" },
		"DIRjn757": { "slug": "blazing-crown" },
		"DIRqb58g": { "slug": "galvanic-crown" },
		"DIRal9f2": { "slug": "strength-of-air" },
	},
};