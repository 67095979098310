import { Avatar, Chip, Stack, SvgIcon, Typography } from "@mui/material";
import FrameHeader from "../../ui/containers/FrameHeader";
import EntryCount from "./EntryCount";
import { styled, Box } from "@mui/system";
import { ReactComponent as WarlordIcon } from "../../assets/icons/warlord.svg";
import useGameSettings from "../../hooks/use-game-settings";

const EntryAvatar = styled(Avatar)({
	width: "75px",
	height: "75px",
});

function EntryPanelHeader({ entry, activeProfileIndex = null, factionSlug }) {
	const { name, slug, type, gameClass, cost, size, profiles } = entry;
	const { getEntrySettings, getFactionSettings } = useGameSettings();
	const icon = getEntrySettings(slug)?.icon ||
		getFactionSettings(factionSlug).defaultEntryIcon;
	const activeProfile = activeProfileIndex === null ? null : profiles[activeProfileIndex];
	const subtitle = activeProfile ? activeProfile.name : null;
	const statline = activeProfile?.statline || entry.statline;
	const statlineOrder = ["M", "V", "C", "A", "W", "R", "D", "E", "CR"];

	return (
		<FrameHeader>
			<Stack direction="row" spacing={1}>
				<EntryAvatar variant="rounded" src={icon} />
				<Box flexGrow={1}>
					<Stack direction="row" spacing={0.5} alignItems="center">
						{entry.isWarlord && (
							<SvgIcon fontSize="small">
								<WarlordIcon />
							</SvgIcon>
						)}
						<Typography variant="h5" flexGrow={1}>
							{name}
						</Typography>
						<Typography variant="h5" textAlign="right">
							{cost === 0 ? "Free" : cost}
						</Typography>
					</Stack>
					{subtitle && (
						<Typography variant="subtitle1" lineHeight={1}>
							{subtitle}
						</Typography>
					)}
					<Stack
						direction="row"
						spacing={1}
						mt={1}
						justifyContent="space-between"
					>
						<Stack direction="row" gap={0.5} flexWrap="wrap">
							<Chip label={type} size="small" />
							{gameClass && <Chip label={gameClass} size="small" />}
						</Stack>
						<EntryCount count={size} />
					</Stack>
				</Box>
			</Stack>
			<Stack direction="row" mt={2}>
				{statlineOrder.map(
					(key) =>
						statline[key] !== undefined && (
							<Box key={key} textAlign="center" flex="1 1 0">
								<Typography
									variant="h5"
									lineHeight={1.6}
									borderBottom="1px solid white"
								>
									{key}
								</Typography>
								<Typography variant="h5" lineHeight={1.6}>
									{statline[key]}
								</Typography>
							</Box>
						)
				)}
			</Stack>
		</FrameHeader>
	);
}

export default EntryPanelHeader;
