import { Box, styled } from '@mui/system';
import ShadowBox from './ShadowBox';
import sq1 from "../../assets/decorative/rips-square1.svg";
import sq2 from "../../assets/decorative/rips-square2.svg";
import sq3 from "../../assets/decorative/rips-square3.svg";
import sq4 from "../../assets/decorative/rips-square4.svg";
import sq5 from "../../assets/decorative/rips-square5.svg";
import rips1 from "../../assets/decorative/rips-bottom1.svg";
import rips2 from "../../assets/decorative/rips-bottom2.svg";
import { useMemo } from 'react';


const squares = [sq1, sq2, sq3, sq4, sq5];
const rips = [rips1, rips2];


const Rips = styled(Box)(({ rippedSide }) => {
	const mask = useMemo(() => {
		if (rippedSide === "all") {
			return squares[Math.floor(Math.random() * squares.length)];
		} else {
			return rips[Math.floor(Math.random() * rips.length)];
		}
	}, [rippedSide]);

	let maskSettings = {};
	if (rippedSide === "all") {
		maskSettings = {
			mask: `url(${mask}) top left / 100% 100%`,
		}
	} else if (rippedSide === "bottom") {
		maskSettings = {
			mask: `
				url(${mask}) bottom left / auto 10px repeat-x,
				linear-gradient(rgb(0,0,0), rgb(0,0,0))
					top left / 100% calc(100% - 9px) no-repeat
			`,
		};
	}

	return {
		width: "100%",
		height: "100%",
		...maskSettings,
	};
});


/**
 * The ripped effect is created through a CSS mask. In order to cast the shadow
 * though, a shadowed wrapper is needed otherwise the shadow would be clipped
 * by the mask.
 * Passing Box properties now becomes tricky because the actual content Box is
 * wrapped by the shadow Box. The approach here is to pass any width/height
 * properties to the shadow Box while having the content Box always occupy the
 * entire parent space. Any other properties are passed to the content Box only.
 */
function RippedBox(props) {
	const { width, height, maxWidth, maxHeight, ...other } = props;

	return (
		<ShadowBox
			width={width}
			maxWidth={maxWidth}
			height={height}
			maxHeight={maxHeight}
		>
			<Rips {...other} />
		</ShadowBox>
	);
}


export default RippedBox;