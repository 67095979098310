import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';


function GameHomeIcon({ gameSlug, variant }) {
	return (
		<IconButton
			component={Link}
			to={gameSlug}
			aria-label="Game Home"
			variant={variant}
		>
			<HomeIcon />
		</IconButton>
	);
}


export default GameHomeIcon;