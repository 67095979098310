import { Stack } from "@mui/material";
import React, { useContext } from "react";
import GameCtx from "../../store/GameCtx";
import ListEntryLabelledGroup from "./ListEntryOptionsGroup";
import RetinueLabelledGroup from "./RetinueOptionsGroup";


function ListEntryOptions({ optionGroups, onUpdate, listEntry }) {
	const { currentGameSlug } = useContext(GameCtx);

	return (
		<Stack spacing={1}>
			{optionGroups.filter(g => g.active !== false).map(g => (
				<React.Fragment key={g.slug}>
					{(g.slug === "retinue" && currentGameSlug === "fb") ?
						<RetinueLabelledGroup key={g.name} group={g} onUpdate={onUpdate} /> :
						<ListEntryLabelledGroup key={g.name} group={g} onUpdate={onUpdate} listEntry={listEntry} />
					}
				</React.Fragment>
			))}
		</Stack>
	);
}


export default ListEntryOptions;