import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import useQuery from "../../hooks/use-query";


function FactionQueriesIcon({ queries, variant }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const sendQuery = useQuery();
	const open = Boolean(anchorEl);

	function handleClick(event) {
		setAnchorEl(event.currentTarget);
	};

	function handleMenuClose() {
		setAnchorEl(null);
	};

	function handleMenuItemClick(query) {
		sendQuery(query);
		setAnchorEl(null);
	}

	return (
		<>
			<IconButton
				id="faction-queries-button"
				aria-label="queries to faction rules"
				aria-controls={open ? 'faction-queries-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				variant={variant}
			>
				<MenuBookIcon />
			</IconButton>
			<Menu
				id="faction-queries-menu"
				anchorEl={anchorEl}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				open={open}
				onClose={handleMenuClose}
				MenuListProps={{
					'aria-labelledby': 'faction-queries-button',
				}}
			>
				{queries.map(q => (
					<MenuItem
						key={q.label}
						onClick={handleMenuItemClick.bind(null, q)}
					>
						{q.label}
					</MenuItem>
				))}
			</Menu>
		</>
	);
}


export default FactionQueriesIcon;