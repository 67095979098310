import { styled } from '@mui/system';
import { Link } from "react-router-dom";
import GAMES_SETTINGS from '../../data/games-settings';
import bgr from "../../assets/cloud.jpg";
import { grow, shadow } from '../../themes/mixins';


const LogoImg = styled("img")(({ theme, tint }) => ({
	background: `
		linear-gradient(
			0,
			${tint || "transparent"},
			${tint || "transparent"}
		),
		url(${bgr}) center/cover
	`,
	backgroundBlendMode: "overlay",
	borderRadius: theme.shape.borderRadius,
	padding: theme.spacing(2),
	height: "120px",
	[theme.breakpoints.only("xs")]: {
		height: "100px",
	},
	...shadow,
	...grow,
}));


function GameHomeButton({ game }) {
	const { name, slug } = game;
	const { logo, tint } = GAMES_SETTINGS[slug];

	return (
		<Link to={slug}>
			<LogoImg tint={tint} src={logo} alt={name} />
		</Link>
	);
}


export default GameHomeButton;