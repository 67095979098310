import { useContext } from "react";
import FramePanel from "../../../ui/containers/FramePanel";
import FloatingActions from "../../../ui/FloatingActions";
import SaveIcon from '@mui/icons-material/Save';
import UserCtx from "../../../store/UserCtx";
import ListPanelHeader from "./ListPanelHeader";
import ListPanelContent from "./ListPanelContent";
import ParsedListCtx from "../../../store/ParsedListCtx";
import { useDispatch, useSelector } from "react-redux";
import { updateSaved } from "../../../store/list-slice";
import appSettings from "../../../app-settings";
import axios from "axios";
import { useSnackbar } from "notistack";
import { v4 as uuid } from 'uuid';
import { Navigate, useNavigate } from "react-router-dom";


function ListPanel({ panel }) {
	const faction = panel.data;
	const { currentUser, savedLists, onSavedListsChange } = useContext(UserCtx);
	const { parsedList } = useContext(ParsedListCtx);
	const dispatch = useDispatch();
	const list = useSelector(state => state.list);
	const { saved: listSaved } = list;
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();


	async function handleSave() {
		const { gameSlug, id } = list;

		try {
			const { data: remoteList } = await axios.get(
				`${appSettings.backendUrl}/lists/${id}.json`
			);
			const localExists = savedLists.some(l => l.id === id);

			if (remoteList && !localExists) {
				const newId = uuid();
				await axios.put(`${appSettings.backendUrl}/lists/${newId}.json`, {
					...list,
					id: newId,
				});
				const updatedLists = [
					...savedLists,
					{
						id: newId,
						name: list.name,
						factionSlug: list.factionSlug,
						gameSlug: list.gameSlug,
						points: list.points,
					}
				];
				await axios.put(`${appSettings.backendUrl}/userlists/${currentUser.username}.json`, updatedLists);
				onSavedListsChange(updatedLists);
				dispatch(updateSaved(true));
				enqueueSnackbar("List saved.", { variant: "success" })
				navigate("../" + newId);
			} else {
				await axios.put(`${appSettings.backendUrl}/lists/${list.id}.json`, {
					...list
				});
				if (!savedLists.some(l => l.id === list.id)) {
					const updatedLists = [
						...savedLists,
						{
							id: list.id,
							name: list.name,
							factionSlug: list.factionSlug,
							gameSlug: list.gameSlug,
							points: list.points,
						}
					];
					await axios.put(`${appSettings.backendUrl}/userlists/${currentUser.username}.json`, updatedLists);
					onSavedListsChange(updatedLists);
				}
				dispatch(updateSaved(true));
				enqueueSnackbar("List saved.", { variant: "success" })
			}
		} catch (error) {
			enqueueSnackbar("Failed to save the list.", { variant: "error" })
			console.error(error);
		}
	}

	const actions = [
		{ description: "Save", icon: SaveIcon, handler: handleSave, pronounced: true, disabled: listSaved },
	]

	return (
		<FramePanel color="darkBgr">
			<ListPanelHeader faction={faction} parsedList={parsedList} />
			<ListPanelContent faction={faction} parsedList={parsedList} />
			{currentUser && <FloatingActions xPos="right" yPos="bottom" actions={actions} />}
		</FramePanel >
	);
}


export default ListPanel;