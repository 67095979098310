import { Stack, SvgIcon, Typography } from "@mui/material";
import useGameSettings from "../../hooks/use-game-settings";
import { grow, shadow } from "../../themes/mixins";


function FactionButton({ faction, onClick }) {
	const { name, slug } = faction;
	const { getFactionSettings } = useGameSettings();
	const { icon, cardBgr } = getFactionSettings(slug);

	function handleClick() {
		onClick(slug);
	}

	return (
		<Stack
			component="li"
			direction="row"
			alignItems="center"
			spacing={1}
			borderRadius={1}
			sx={{
				background: `
					linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
					url(${cardBgr}) center/cover no-repeat
				`,
				cursor: "pointer",
				...shadow,
				...grow,
			}}
			onClick={handleClick}
		>
			<SvgIcon
				component={icon}
				sx={{
					backgroundColor: theme => theme.palette.primary.main,
					color: theme => theme.palette.primary.contrastIcon,
					height: "70px",
					width: "70px",
					padding: 1,
					borderTopLeftRadius: theme => theme.shape.borderRadius,
					borderBottomLeftRadius: theme => theme.shape.borderRadius,
				}}
			/>
			<Typography
				variant="h3"
				color="primary.contrastIcon"
				flexGrow={1}
				align="center"
			>
				{name}
			</Typography>
		</Stack >
	);
}


export default FactionButton;