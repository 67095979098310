import { Stack } from "@mui/material";
import EntryButton from "./EntryButton";
import Section from "../../ui/containers/Section";


function EntryList(props) {
	const { title, entries, onEntrySelect, factionSlug } = props;

	return (
		<Section title={title} darkBgr>
			<Stack spacing={1}>
				{entries.map(e => (
					<EntryButton
						key={e.slug}
						factionSlug={factionSlug}
						entry={e}
						onSelect={onEntrySelect.bind(null, e)}
					/>
				))}
			</Stack>
		</Section>
	);
}


export default EntryList;