import { Typography } from "@mui/material";
import LabelledGroup from "../../../ui/containers/LabelledGroup";
import QueryIcon from "../../query/QueryIcon";


function DescriptionOnly({ group }) {
	const { name, description, query } = group;

	return (
		<LabelledGroup label={name} action={query && <QueryIcon query={query} />}>
			<Typography>{description}</Typography>
		</LabelledGroup>
	);
}


export default DescriptionOnly;