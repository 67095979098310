export const shadow = {
	filter: "drop-shadow(0 0 2px black) drop-shadow(0 2px 4px black)",
};

export const grow = {
	transition: "200ms",
	"&:hover": {
		transform: "scale(1.05)"
	},
}

export const centeredContent = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	width: "100%",
	height: "100%",
};