import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import ListEntryButton from "../../list-entry/ListEntryButton";

function Regiment({ node, factionSlug }) {
	return (
		<Stack spacing={1}>
			<ListEntryButton node={node} factionSlug={factionSlug} />
			{node.children.map(officer => (
				<Box pl={3} key={officer.id}>
					<ListEntryButton node={officer} factionSlug={factionSlug} noActions />
				</Box>
			))}
		</Stack>
	)
}


export default Regiment;