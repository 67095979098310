import axios from "axios";
import React, { useCallback, useEffect, useState, useContext } from "react";
import SavedListsDialog from "../ui/SavedListsDialog";
import GameCtx from "./GameCtx";


const UserCtx = React.createContext({
	currentUser: null,
	savedLists: [],
	onCurrentUserChange: () => { },
	onSavedListsChange: () => { },
});

export function UserCtxProvider(props) {
	const [currentUser, setCurrentUser] = useState(null);
	const [savedLists, setSavedLists] = useState([]);


	const handleCurrentUserChange = useCallback((user, savedLists) => {
		setCurrentUser(user);
		setSavedLists(savedLists);
	}, []);

	function handleSavedListsChange(lists) {
		setSavedLists(lists);
	}


	return (
		<UserCtx.Provider
			value={{
				currentUser,
				savedLists,
				onCurrentUserChange: handleCurrentUserChange,
				onSavedListsChange: handleSavedListsChange,
			}}
		>
			{props.children}
		</UserCtx.Provider>
	);
}


export default UserCtx;