import FrameDialog from "../../../ui/containers/FrameDialog";
import FrameHeader from "../../../ui/containers/FrameHeader";
import { useState } from "react";
import FrameDialogContent from "../../../ui/containers/FrameDialogContent";
import { Fab, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useDispatch } from "react-redux";
import { attachNode } from "../../../store/list-slice";
import { createNode } from "../../../lib/list";
import EntryList from "../../entry/EntryList";
import FloatingActions from "../../../ui/FloatingActions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StartHere from "../StartHere";


function sortingFn(a, b) {
	return a.toLowerCase()
		.localeCompare(b.toLowerCase());
}





function NewWarbandButton({ faction, countWarbands }) {
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();

	function handleClose() {
		setOpen(false);
	}

	function handleOpen() {
		setOpen(true);
	}

	function handleEntrySelect(entry) {
		const warbandName = `Warband ${countWarbands + 1}`;

		const warbandNode = createNode({
			type: "warband",
			name: warbandName,
		});

		warbandNode.children.push(createNode({
			type: "character",
			entrySlug: entry.slug,
		}));

		dispatch(attachNode({ path: "", node: warbandNode }));
		handleClose();
	}

	const characterSlugs = faction.entryGroups
		.find(g => g.slug === "characters").entrySlugs;

	const characters =
		[...characterSlugs].sort(sortingFn).map(s => faction.entries[s]);

	const actions = [
		{
			id: "close",
			icon: ArrowBackIcon,
			handler: handleClose,
		}
	];


	return (
		<>
			<Box textAlign="center">
				<Fab
					color="primary"
					variant="extended"
					size="large"
					onClick={handleOpen}
				>
					<AddCircleIcon sx={{ mr: 1 }} />
					<Typography variant="h4">Warband</Typography>
				</Fab>
				{countWarbands < 1 && <StartHere />}
			</Box>
			<FrameDialog
				open={open}
				onClose={handleClose}
				responsive
				color="darkBgr"
				actions={actions}
			>
				<FrameHeader title="Select Character" />
				<FrameDialogContent>
					<EntryList
						title="Characters"
						entries={characters}
						onEntrySelect={handleEntrySelect}
						factionSlug={faction.slug}
					/>
				</FrameDialogContent>
			</FrameDialog>
		</>
	);
}


export default NewWarbandButton;