import { Box, Paper, Stack, Typography } from "@mui/material";
import Directive from "./Directive";

function Option({ option }) {
	const { name, tier, cost } = option;

	return (
		<Box component={Paper} variant="outlined" p={1}>
			<Stack direction="row" justifyContent="space-between">
				<Typography variant="h6">{(tier && `Tier ${tier}`) || name}</Typography>
				<Typography variant="h6">{cost || "Free"}</Typography>
			</Stack>
			<Directive data={option} />
		</Box>
	);
}

export default Option;
