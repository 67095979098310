import {
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem
} from "@mui/material";
import { useState, useContext } from "react";
import ShareIcon from '@mui/icons-material/Share';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import LinkIcon from '@mui/icons-material/Link';
import { getText } from "../../../lib/get-text-tlaok";
import { useSnackbar } from "notistack";
import UserCtx from "../../../store/UserCtx";


function ListShareIcon({ game, faction, list }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const { enqueueSnackbar } = useSnackbar();
	const { currentUser } = useContext(UserCtx)

	function handleOpen(event) {
		setAnchorEl(event.currentTarget);
	};

	function handleClose() {
		setAnchorEl(null);
	};

	function handleTextCopy() {
		const text = getText(game.name, faction.name, list);
		navigator.clipboard.writeText(text);
		enqueueSnackbar("Copied text to clipboard.", { variant: "success" })
		handleClose();
	}

	function handleUrlCopy() {
		navigator.clipboard.writeText(window.location.href)
		enqueueSnackbar("Copied list URL to clipboard.", { variant: "success" })
		handleClose();
	}

	function handleFacebookShare() {
		const url = "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href;
		window.open(url, "_blank");
		handleClose();
	}

	function handleTwitterShare() {
		const url = "https://twitter.com/intent/tweet?text=" + window.location.href;
		window.open(url, "_blank");
		handleClose();
	}

	const actions = [
		{
			Icon: <FontDownloadIcon />,
			label: "Copy Text",
			handler: handleTextCopy,
		}
	];

	if (currentUser) {
		actions.push(...[
			{
				Icon: <LinkIcon />,
				label: "Copy Link",
				handler: handleUrlCopy,
			},
			{
				Icon: <FacebookIcon />,
				label: "Share on Facebook",
				handler: handleFacebookShare,
			},
			{
				Icon: <TwitterIcon />,
				label: "Share on Twitter",
				handler: handleTwitterShare,
			}
		])
	}

	return (
		<>
			<IconButton
				variant="light"
				onClick={handleOpen}
				id="share-button"
				aria-controls={open ? 'share-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
			>
				<ShareIcon />
			</IconButton>
			<Menu
				id="share-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'share-button',
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				{actions.map(a => (
					<MenuItem onClick={a.handler} key={a.label}>
						<ListItemIcon>{a.Icon}</ListItemIcon>
						<ListItemText>{a.label}</ListItemText>
					</MenuItem>
				))}
			</Menu>
		</>
	);
}


export default ListShareIcon;