import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useRouteError } from "react-router-dom";


function RouteError() {
	const error = useRouteError();

	return (
		<Dialog
			fullWidth
			maxWidth="xs"
			open
		>
			<DialogTitle>Error</DialogTitle>
			<DialogContent>
				{error.message}
			</DialogContent>
		</Dialog>
	);
}


export default RouteError;