import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Avatar, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Popover, Typography } from '@mui/material';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import UserCtx from '../store/UserCtx';
import FolderIcon from '@mui/icons-material/Folder';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation, useNavigate } from 'react-router-dom';
import ListCtx from '../store/ListCtx';
import appSettings from '../app-settings';
import { useDispatch } from 'react-redux';
import { openSavedLists } from '../store/utils-slice';


function LoginIconButton() {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const { currentUser, onCurrentUserChange } = useContext(UserCtx);
	const { onSavedListsOpen } = useContext(ListCtx);
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// useEffect(() => {
	// 	async function getUsername() {
	// 		try {
	// 			// const response = await axios.get("https://faas-fra1-afec6ce7.doserverless.co/api/v1/web/fn-76df8e48-87f7-409b-ae54-ddac3ca62c2a/default/test")
	// 			// const response = await axios.get("https://living.para-bellum.com/api/v1/users/whoami", { withCredentials: true })
	// 			const response = await axios.get("http://localhost:3000/api/v1/users/whoami", { withCredentials: true })
	// 			onCurrentUserChange(response.data.data.attributes);
	// 		} catch (error) {
	// 			console.error(error);
	// 		}
	// 	}
	// 	if (!currentUser) getUsername();
	// }, []);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	function handleMyListsOpen() {
		dispatch(openSavedLists());
		handleClose();
	}

	async function handleLogin() {
		try {
			const response = await axios.get("https://living.para-bellum.com/api/v1/users/whoami", { withCredentials: true })
			// const response = await axios.get("http://localhost:3000/api/v1/users/whoami", { withCredentials: true })
			const user = response.data.data.attributes;
			const listsResponse = await axios.get(`${appSettings.backendUrl}userlists/${user.username}.json`);
			onCurrentUserChange(user, listsResponse.data || []);
		} catch (error) {
			// window.location.replace("http://localhost:3000/users/auth/openid_connect?origin=ab")
			window.location.replace("https://living.para-bellum.com/users/auth/openid_connect?origin=ab")
		}
	}

	function handleLogout() {
		handleClose();
		onCurrentUserChange(null);
	}


	return (
		<>
			<IconButton aria-label="Account" variant="light" onClick={handleClick}>
				{currentUser ? (
					<Avatar sx={{ width: 24, height: 24 }} variant="rounded">{currentUser.username[0].toUpperCase()}</Avatar>
				) : (
					<AccountCircleIcon />
				)}
			</IconButton>
			<Popover
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				{currentUser ? (
					<>
						<Typography variant="h6" align='center' my={1}>{currentUser.username}</Typography>
						<Divider />
						<List disablePadding>
							{location.pathname !== "/" && (
								<ListItem disablePadding>
									<ListItemButton onClick={handleMyListsOpen}>
										<ListItemIcon><FolderIcon /></ListItemIcon>
										<ListItemText primary="My Lists" />
									</ListItemButton>
								</ListItem>
							)}
							<ListItem disablePadding>
								<ListItemButton onClick={handleLogout}>
									<ListItemIcon><LogoutIcon /></ListItemIcon>
									<ListItemText primary="Logout" />
								</ListItemButton>
							</ListItem>
						</List>
					</>
				) : (
					<List disablePadding>
						<ListItem disablePadding>
							{/* <ListItemButton component="a" href="https://living.para-bellum.com/users/auth/openid_connect?origin=ab"> */}
							{/* <ListItemButton component="a" href="http://localhost:3000/users/auth/openid_connect?origin=ab"> */}
							<ListItemButton onClick={handleLogin}>
								<ListItemText primary="Login" />
							</ListItemButton>
						</ListItem>
					</List>
				)}
			</Popover>
		</>
	);
}


export default LoginIconButton;