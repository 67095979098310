import { Avatar, Fab, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, SvgIcon } from "@mui/material";
import { useContext } from "react";
import UserCtx from "../store/UserCtx";
import FrameDialog from "./containers/FrameDialog";
import FrameDialogContent from "./containers/FrameDialogContent";
import DeleteIcon from '@mui/icons-material/Delete';
import ListCtx from "../store/ListCtx";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import FloatingActions from "./FloatingActions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GAMES_SETTINGS from "../data/games-settings";
import GameCtx from "../store/GameCtx";
import { useDispatch, useSelector } from "react-redux";
import FrameHeader from "./containers/FrameHeader";
import { closeSavedLists } from "../store/utils-slice";
import PromptDialog from "./PromptDialog";
import { useState } from "react";
import { useSnackbar } from "notistack";
import appSettings from "../app-settings";


function SavedListsDialog() {
	const { list, onListLoad, lists, onListsReload } = useContext(ListCtx);
	const navigate = useNavigate();
	const { currentUser, savedLists, onSavedListsChange } = useContext(UserCtx);
	const { gameSlug } = useParams();
	const open = useSelector(state => state.utils.savedListsOpen);
	const currentGameSlug = useSelector(state => state.games.currentGameSlug);
	const dispatch = useDispatch();
	const [listToDelete, setListToDelete] = useState(null);
	const openDelete = !!listToDelete;
	const { enqueueSnackbar } = useSnackbar();

	function handleClose() {
		dispatch(closeSavedLists());
	}

	function handleListSelect(list) {
		navigate(`../${list.gameSlug}/editor/${list.id}`);
		handleClose();
	}

	async function handleDeleteConfirm() {
		const { gameSlug, id } = listToDelete;
		try {
			await axios.delete(`${appSettings.backendUrl}/lists/${gameSlug}/${id}.json`);
			const updatedLists = savedLists.filter(l => l.id !== id);
			await axios.put(`${appSettings.backendUrl}/userlists/${currentUser.username}.json`, updatedLists);
			onSavedListsChange(updatedLists);
			enqueueSnackbar("List removed.", { variant: "success" })
		} catch (error) {
			console.error(error);
			enqueueSnackbar("Failed to remove the list.", { variant: "error" })
		}
		handleDeleteClose();
	}

	function handleDeleteOpen(list) {
		setListToDelete(list);
	}

	function handleDeleteClose() {
		setListToDelete(null);
	}

	let listItems = (
		<ListItem disablePadding>
			<ListItemText
				sx={{ textAlign: "center" }}
				primary="No lists found."
			/>
		</ListItem>
	);

	listItems = savedLists?.filter(l => l.gameSlug === currentGameSlug).map(list => (
		<ListItem
			key={list.id}
			disablePadding
			secondaryAction={
				<IconButton edge="end" aria-label="delete" onClick={handleDeleteOpen.bind(null, list)}>
					<DeleteIcon />
				</IconButton>
			}>
			<ListItemAvatar>
				<Avatar sx={{ backgroundColor: "darkBgr.main" }}>
					<SvgIcon
						component={GAMES_SETTINGS[gameSlug].factions[list.factionSlug].icon}
					/>
				</Avatar>
			</ListItemAvatar>
			<ListItemButton onClick={handleListSelect.bind(null, list)}>
				<ListItemText
					primary={list.name}
					secondary={list.points}
				/>
			</ListItemButton>
		</ListItem>
	));


	return (
		<>
			<FrameDialog
				open={open}
				onClose={handleClose}
				responsive
				actions={[
					{
						id: "Close",
						handler: handleClose,
						icon: ArrowBackIcon,
					}
				]}
			>
				<FrameHeader title="Saved lists" />
				<FrameDialogContent>
					<List>
						{listItems}
					</List>
				</FrameDialogContent>
			</FrameDialog>
			{openDelete && (
				<PromptDialog
					open
					onClose={handleDeleteClose}
					title="Remove list?"
					content={`
					List "${listToDelete.name}" will be permanently removed. Are you sure?
				`}
					actions={[
						{ label: "Cancel", handler: handleDeleteClose },
						{ label: "Yes", handler: handleDeleteConfirm },
					]}
				/>
			)}
		</>
	);
}


export default SavedListsDialog;