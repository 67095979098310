import { Grid } from "@mui/material";
import FactionCard from "./FactionCard";
import CenteredContentScrollbar
	from "../../ui/containers/CenteredContentScrollbar";


function FactionsGrid({ factions }) {
	const factionCards = factions.map(faction => (
		<Grid item xs={12} sm={6} md={4} key={faction.slug}>
			<FactionCard faction={faction} />
		</Grid>
	));

	return (
		<CenteredContentScrollbar>
			<Grid
				container
				spacing={2}
				p={2}
				maxWidth="lg"
			>
				{factionCards}
			</Grid>
		</CenteredContentScrollbar>
	);
}


export default FactionsGrid;