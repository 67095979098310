import { useContext, useEffect } from "react";
import HorPanelsCtx from "../../ui/layouts/HorPanelsCtx";
import HorPanelsLayout from "../../ui/layouts/HorPanelsLayout";
import FactionPanel from "./FactionPanel";


function FactionLayout({ faction }) {
	const {
		onPanelAdd,
		onPanelsClear,
		onActiveSlotsChange
	} = useContext(HorPanelsCtx);

	useEffect(() => {
		onActiveSlotsChange(3);
		onPanelsClear();
		onPanelAdd({
			id: faction.slug,
			type: "permanent",
			position: "center",
			order: 0,
			importance: 99,
			body: <FactionPanel key={faction.slug} panel={{ data: faction }} />,
			actions: [],
		});
	}, [faction]);

	return (
		<HorPanelsLayout />
	);
}


export default FactionLayout;