import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	TextField
} from "@mui/material";
import Section from "../../../ui/containers/Section";
import { useRef, useState, useContext } from "react";
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ListEntryButton from "../../list-entry/ListEntryButton";
import NewRegimentButton from "./NewRegimentButton";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from "react-redux";
import { attachNode, removeNode, updateData } from "../../../store/list-slice";
import { getDuplicate } from "../../../lib/list";
import PromptDialog from "../../../ui/PromptDialog";
import AlertButton from "../../bits/AlertButton";
import HorPanelsCtx from "../../../ui/layouts/HorPanelsCtx";



function Warband({ warbandNode, faction }) {
	const { id, name, children, parent, path, alerts } = warbandNode;
	const [openEdit, setOpenEdit] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const dispatch = useDispatch();
	const list = useSelector(state => state.list);
	const nameRef = useRef();
	const [nameStatus, setNameStatus] = useState(null);
	const { onPanelRemove } = useContext(HorPanelsCtx);

	function handleDeleteOpen() {
		setOpenDelete(true);
	}

	function handleDeleteClose() {
		setOpenDelete(false);
	}

	function handleDeleteConfirm() {
		const panelIds = children.map(c => c.id);
		panelIds.forEach(id => onPanelRemove(id));
		dispatch(removeNode({ parentPath: parent.path, nodeId: id }));
		setOpenDelete(false);
	}

	function handleDuplicate() {
		const clone = getDuplicate(list, path);
		if (clone) {
			dispatch(attachNode({ path: parent.path, node: clone }));
		}
	}

	function handleEditOpen() {
		setOpenEdit(true);
	}

	function handleEditClose() {
		setOpenEdit(false);
	}

	function handleEditSave() {
		const currentName = nameRef.current.value;
		if (currentName?.length < 1 || currentName?.length > 30) {
			setNameStatus("Set a name between 1 and 30 characters long.")
		} else {
			dispatch(updateData({ path, data: { name: currentName } }));
			handleEditClose();
		}
	}

	function handleNameChange(e) {
		setNameStatus(null);
	}

	const characterNode = children.find(c => c.type === "character");
	const characterButton =
		<ListEntryButton node={characterNode} factionSlug={faction.slug} />

	const canHaveWarband = characterNode.listEntry?.warband?.mainstay || characterNode.listEntry?.warband?.restricted

	const mainstayNodes = children.filter(c => c.type === "mainstay");
	const mainstayButtons = mainstayNodes.map((node, i) =>
		<ListEntryButton
			key={i}
			node={node}
			factionSlug={faction.slug}
		/>
	)

	const restrictedNodes = children.filter(c => c.type === "restricted");
	const restrictedButtons = restrictedNodes.map((node, i) =>
		<ListEntryButton
			key={i}
			node={node}
			factionSlug={faction.slug}
		/>
	)

	const actions = [
		{
			label: "Remove",
			icon: DeleteIcon,
			handler: handleDeleteOpen,
			collapsible: true
		},
		{
			label: "Duplicate",
			icon: ContentCopyIcon,
			handler: handleDuplicate,
			collapsible: true
		},
		{
			label: "Edit",
			icon: EditIcon,
			handler: handleEditOpen,
			collapsible: true
		},
	];

	const alertIcon = !!alerts.length && (
		<AlertButton alerts={alerts} concerning="Warband" />
	);

	return (
		<>
			<Section title={name} actions={actions} darkBgr prefix={alertIcon}>
				<Stack spacing={1}>
					{characterButton}
					<Stack spacing={1} pl={3}>
						{mainstayButtons}
						{restrictedButtons}
					</Stack>
					{canHaveWarband && <NewRegimentButton characterNode={characterNode} faction={faction} />}
				</Stack>
			</Section>
			<PromptDialog
				open={openDelete}
				onClose={handleDeleteClose}
				title="Remove warband?"
				content={`
					The entire Warband will be removed from the list. Are you sure?
				`}
				actions={[
					{ label: "Cancel", handler: handleDeleteClose },
					{ label: "Yes", handler: handleDeleteConfirm },
				]}
			/>
			<Dialog open={openEdit} onClose={handleEditClose}>
				<DialogTitle>Warband name</DialogTitle>
				<DialogContent>
					<TextField
						fullWidth
						size="small"
						defaultValue={name}
						onChange={handleNameChange}
						inputRef={nameRef}
						helperText={nameStatus}
						error={!!nameStatus}
					/>
				</DialogContent>
				<DialogActions>
					<Button size="small" onClick={handleEditClose}>Cancel</Button>
					<Button size="small" variant="contained" onClick={handleEditSave}>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}


export default Warband;