import { IconButton } from "@mui/material";
import { useState } from "react";
import FrameDialog from "../../ui/containers/FrameDialog";
import FrameHeader from "../../ui/containers/FrameHeader";
import FrameDialogContent from "../../ui/containers/FrameDialogContent";
import FloatingActions from "../../ui/FloatingActions";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import TuneIcon from '@mui/icons-material/Tune';
import { useDispatch, useSelector } from "react-redux";
import ListSettings from "./ListSettings";
import useGameSettings from "../../hooks/use-game-settings";
import { updateData } from "../../store/list-slice";


function ListSettingsIcon() {
	const [open, setOpen] = useState(false);
	const list = useSelector(state => state.list);
	const initialInputsState = {
		factionSlug: list.factionSlug,
		name: list.name,
		nameStatus: null,
		points: list.points,
		pointsStatus: null,
	}
	const [inputs, setInputs] = useState(initialInputsState);
	const dispatch = useDispatch();
	const { getGameSettings } = useGameSettings();
	const { listMinPoints } = getGameSettings();

	function handleOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	function handleNameChange(name) {
		setInputs(prevInputs => ({
			...prevInputs,
			name,
			nameStatus: null,
		}));
	}

	function handlePointsChange(points) {
		setInputs(prevInputs => ({
			...prevInputs,
			points,
			pointsStatus: null,
		}));
	}

	function handleApply() {
		let error = false;

		if (!inputs.name?.length) {
			error = true;
			setInputs((prevInputs) => ({
				...prevInputs,
				nameStatus: "Name cannot be empty",
			}));
		}

		if (inputs.points < listMinPoints) {
			error = true;
			setInputs((prevInputs) => ({
				...prevInputs,
				pointsStatus: `Points cannot be lower than ${listMinPoints}`,
			}));
		}

		if (!error) {
			dispatch(updateData({
				path: "",
				data: {
					name: inputs.name,
					points: inputs.points,
				}
			}))
			handleClose();
		}
	}

	const actions = [
		{
			id: "apply",
			icon: DoneIcon,
			handler: handleApply,
		},
		{
			id: "cancel",
			icon: CloseIcon,
			handler: handleClose,
		}
	];

	return (
		<>
			<IconButton variant="light" onClick={handleOpen}>
				<TuneIcon />
			</IconButton>
			<FrameDialog open={open} onClose={handleClose} responsive actions={actions}>
				<FrameHeader title="List settings" />
				<FrameDialogContent>
					<ListSettings
						inputs={inputs}
						onNameChange={handleNameChange}
						onPointsChange={handlePointsChange}
					/>
				</FrameDialogContent>
			</FrameDialog>
		</>
	);
}


export default ListSettingsIcon;