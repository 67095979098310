import { createSlice } from "@reduxjs/toolkit";
import { getQuery } from "../lib/api";
import { v4 as uuid } from "uuid";


const queriesSlice = createSlice({
	name: "query",
	initialState: [],
	reducers: {
		addRequest(state, { payload }) {
			state.push(payload);
		},
		addResponse(state, { payload }) {
			const query = state.find(q => q.id === payload.id);
			if (query) {
				query.response = payload.response;
			}
		},
		popQuery(state) {
			state.pop();
		},
		clearQueries() {
			return [];
		},
	},
});

const queriesActions = queriesSlice.actions;


export function addQuery({ label, request }) {
	return async (dispatch) => {
		const id = uuid();

		dispatch(queriesActions.addRequest({ id, label, request }));

		let response = null;
		try {
			response = await getQuery(request);
			response ||= "error";
		} catch {
			response = "error";
		}

		dispatch(queriesActions.addResponse({ id, response }));
	};
}


export const { popQuery, clearQueries } = queriesActions;
export default queriesSlice.reducer;