import { useSelector } from "react-redux";
import useGameSettings from "../hooks/use-game-settings";


function GameHome() {
	const { availableGames, currentGameSlug } = useSelector(state => state.games);
	const { getComponent } = useGameSettings();

	const game = availableGames.find(g => g.slug === currentGameSlug);
	const HomeComponent = getComponent("pages", "home");

	return <HomeComponent game={game} />;
}


export default GameHome;