import { createSlice } from "@reduxjs/toolkit";


const gamesSlice = createSlice({
	name: "games",
	initialState: {
		availableGames: [],
		currentGameSlug: null,
		currentFactionSlug: null,
	},
	reducers: {
		setGames(state, { payload: games }) {
			return {
				availableGames: games,
				currentGameSlug: null,
				currentFactionSlug: null
			};
		},
		setCurrentGame(state, { payload: gameSlug }) {
			state.currentGameSlug = gameSlug;
		},
		setCurrentFaction(state, { payload: factionSlug }) {
			state.currentFactionSlug = factionSlug;
		},
	},
});

const gamesActions = gamesSlice.actions;


export const {
	setGames,
	setCurrentGame,
	setCurrentFaction
} = gamesActions;

export default gamesSlice.reducer;