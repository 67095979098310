import {
	createBrowserRouter,
	Route,
	RouterProvider,
	Routes,
} from "react-router-dom";
import { GameCtxProvider } from "./store/GameCtx";
// import { QueryCtxProvider } from "./store/QueryCtx";
import Home from "./pages/Home";
import Game from "./pages/Game";
import Faction, { loader as factionLoader } from "./pages/Faction";
import Factions from "./pages/Factions";
import defaultTheme from "./themes/default";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import MainGameMenu from "./components/game/MainGameMenu";
import TextShowcase from "./pages/TextShowcase";
import "simplebar/dist/simplebar.min.css";
import { ListCtxProvider } from "./store/ListCtx";
import Editor, { loader as editorLoader } from "./pages/Editor";
import { UserCtxProvider } from "./store/UserCtx";
import OAuth from "./pages/OAuth";
import RouteError from "./ui/RouteError";
import GameHome from "./pages/GameHome";
import Init, { loader as gamesLoader } from "./pages/Init";
import { ErrorBoundary } from "react-error-boundary";
import { HorPanelsCtxProvider } from "./ui/layouts/HorPanelsCtx";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Init />,
		loader: gamesLoader,
		errorElement: <RouteError />,
		children: [
			{ index: true, element: <Home /> },
			{ path: "/oauth", element: <OAuth /> },
			{
				path: ":gameSlug",
				element: <Game />,
				children: [
					{ index: true, element: <GameHome /> },
					{
						path: "factions",
						children: [
							{ index: true, element: <Factions /> },
							{
								path: ":factionSlug",
								id: "faction",
								element: <Faction />,
								loader: factionLoader,
							},
						],
					},
					{
						path: "editor",
						children: [
							{ index: true, element: <Editor /> },
							{ path: ":listId", element: <Editor />, id: "editor", loader: editorLoader },
						]
					},
				],
			},
		],
	},
]);

function App() {
	return (
		<ThemeProvider theme={defaultTheme}>
			<CssBaseline />
			<GameCtxProvider>
				<UserCtxProvider>
					<ListCtxProvider>
						{/* <QueryCtxProvider> */}
						{/* <MainLayout>
								<Routes>
									<Route path="/" element={<Home />} />
									<Route path="/oauth" element={<OAuth />} />
									<Route path="/:gameSlug" element={<Game />}>
										<Route path="" element={<MainGameMenu />} />
										<Route path="factions" element={<Factions />} />
										<Route path="editor" element={<PanelsCtxProvider><TlaokEditor /></PanelsCtxProvider>} />
									</Route>
									<Route path="/:gameSlug/:factionSlug" element={
										<PanelsCtxProvider><Faction /></PanelsCtxProvider>
									} />
									<Route path="/editor" element={
										<PanelsCtxProvider><Editor /></PanelsCtxProvider>
									} />
									<Route path="/typography" element={<TextShowcase />} />
								</Routes>
							</MainLayout> */}
						<RouterProvider router={router} />
						{/* </QueryCtxProvider> */}
					</ListCtxProvider>
				</UserCtxProvider>
			</GameCtxProvider>
		</ThemeProvider>
	);
}

export default App;
