import { Paper } from "@mui/material";
import { styled } from "@mui/system";


const EntryPaper = styled(Paper, {
	shouldForwardProp: prop => prop !== "borderTint",
})(({ theme, borderTint }) => ({
	cursor: "pointer",
	position: "relative",
	zIndex: 0,
	background: `linear-gradient(280deg,
		rgba(252,253,253,1) 0%,
		rgba(84,110,122,1) 3%,
		rgba(84,110,122,1) 97%,
		rgba(252,253,253,1) 100%)
	`,
	backgroundColor: borderTint,
	backgroundBlendMode: "overlay",
	padding: theme.spacing(0.5),
	"&:after": {
		content: "''",
		position: "absolute",
		zIndex: -1,
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		background: `linear-gradient(80deg,
			rgba(252,253,253,1) 0%,
			rgba(84,110,122,1) 3%,
			rgba(84,110,122,1) 97%,
			rgba(252,253,253,1) 100%)
		`,
		borderRadius: theme.shape.borderRadius,
		opacity: 0,
		transition: "500ms",
		backgroundColor: borderTint,
		backgroundBlendMode: "overlay",
	},
	"&:hover:after": {
		opacity: 1,
	},
	"& .avatar": {
		width: "55px",
		borderRadius: theme.shape.borderRadius,
	},
	"& .content": {
		backgroundColor: "#cfd8dc",
		borderRadius: theme.shape.borderRadius,
	},
	"&:hover .content": {
		backgroundColor: "#b0bec5",
	},
	"& .padded": {
		padding: theme.spacing(0.5),
	},
	"& .MuiTypography-root": {
		lineHeight: 1.3,
	},
	"& *": {
		cursor: "pointer",
	},
}));


export default EntryPaper;